import React, { useState, useEffect } from "react";
import "./Unit.css";
import axios from "axios";
import { toast } from "react-toastify";
import baseUrl from "../../../../config/config";

function Unit() {
  const [chapterName, setChapterName] = useState("");
  const [chapterDescription, setChapterDescription] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [exams, setExams] = useState([]);
  const [selectedExamId, setSelectedExamId] = useState("");
  const [grades, setGrades] = useState([]);
  const [selectedGradeId, setSelectedGradeId] = useState("");
  const [subjects, setSubjects] = useState([]);
  const [selectedSubjectId, setSelectedSubjectId] = useState("");
  const [chapters, setChapters] = useState([]);
  const [selectedChapter, setSelectedChapter] = useState(null);
  const [showChapterList, setShowChapterList] = useState(false);
  const [imageUrl, setImageUrl] = useState();


  useEffect(() => {
    fetchExams();
  }, []);

  const fetchExams = async () => {
    try {
      const response = await axios.get(`${baseUrl.Url}/v1/exam/getExams`);
      setExams(response.data.data);
    } catch (error) {
      console.error("Error fetching exams:", error);
    }
  };

  const fetchGrades = async (examId) => {
    try {
      const response = await axios.get(
        `${baseUrl.Url}/v1/grade/getgrades/${examId}`
      );
      setGrades(response.data.data);
    } catch (error) {
      console.error("Error fetching grades:", error);
    }
  };

  const fetchSubjects = async (gradeId) => {
    console.log('gradeId', gradeId);
    try {
      const response = await axios.get(
        `${baseUrl.Url}/v1/subject/getSubjects/${gradeId}`
      );
      setSubjects(response.data.data);
    } catch (error) {
      console.error("Error fetching subjects:", error);
    }
  };

  console.log('subjects', subjects)

  const handleChapterNameChange = (e) => {
    setChapterName(e.target.value);
  };

  const handleChapterDescriptionChange = (e) => {
    setChapterDescription(e.target.value);
  };

  const handleThumbnailChange = (e) => {
    const file = e.target.files[0];
    setThumbnail(file);

    const data = new FormData();
    data.append("file", thumbnail);
    data.append("upload_preset", "dagada");
    data.append("cloud_name", "dfzd4xbsk");

    fetch("https://api.cloudinary.com//v1_1/dfzd4xbsk/image/upload", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setImageUrl(data.url);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err);
      });
  };

  const handleExamSelection = async (examId) => {
    setSelectedExamId(examId);
    setSelectedGradeId("");
    setSelectedSubjectId("");
    fetchGrades(examId);
  };

  const handleGradeSelection = async (gradeId) => {
    setSelectedGradeId(gradeId);
    setSelectedSubjectId("");
    fetchSubjects(gradeId);
  };

  const handleSubjectSelection = (subjectId) => {
    setSelectedSubjectId(subjectId);
    fetchChapters(subjectId);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!chapterName) {
        toast.error("Please Enter Exam Name");
        return;
      }

      if (!imageUrl) {
        const userConfirmed = window.confirm(
          "Thumbnail image URL is empty. Do you want to proceed without a thumbnail?"
        );

        if (!userConfirmed) {
          return;
        }
      }
      // const imageUrl = cld.image(thumbnail).toURL();
      const token = localStorage.getItem("token");

      const response = await axios.post(
        `${baseUrl.Url}/v1/chapter/createChapter`,
        {
          chapterName,
          chapterDescription,
          thumbnail: imageUrl,
          examId: selectedExamId,
          gradeId: selectedGradeId,
          subjectId: selectedSubjectId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 201) {
        toast.success("Chapter created successfully!");
        setChapterName("");
        setChapterDescription("");
        setThumbnail(null);
        setSelectedExamId("");
        setSelectedGradeId("");
        setSelectedSubjectId("");
        setImageUrl(null);
        clearFileInput(); // Clear the file input field
        fetchExams();
      }
    } catch (error) {
      console.error("Error creating chapter:", error);
      toast.error(error.response.data.error);
    }
  };
  const fetchChapters = async (subjectId) => {
    try {
      const response = await axios.get(
        `${baseUrl.Url}/v1/chapter/getChapters/${subjectId}`
      );
      setChapters(response.data.data);
    } catch (error) {
      console.error("Error fetching chapters:", error);
    }
  };

  const clearFileInput = () => {
    const fileInput = document.getElementById("thumbnail");
    if (fileInput) {
      fileInput.value = ""; // Clear the value of the file input
    }
  };

  const handleEditChapter = (chapter) => {
    setSelectedChapter(chapter);
    setChapterName(chapter.chapterName);
    setChapterDescription(chapter.chapterDescription);
  };

  const handleUpdateChapter = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${baseUrl.Url}/v1/chapter/updateChapter/${selectedChapter._id}`,
        {
          chapterName,
          chapterDescription,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        toast.success("Chapter updated successfully!");
        setSelectedChapter(null);
        setSelectedExamId("");
        setSelectedSubjectId("");
        setChapters([]);
        fetchExams();
      }
    } catch (error) {
      console.error("Error updating chapter:", error);
      toast.error("An error occurred while updating the chapter.");
    }
  };

  return (
    <div className="App">
      <div className="sub-navbar">
        {Array.isArray(exams) && (
          <div className="dropdown">
            <select
              className="dropbtn"
              value={selectedExamId}
              onChange={(e) => handleExamSelection(e.target.value)}
            >
              <option value="">Select an Exam</option>
              {exams.map((exam) => (
                <option key={exam._id} value={exam._id}>
                  {exam.examName}
                </option>
              ))}
            </select>
          </div>
        )}

        {Array.isArray(grades) && (
          <div className="dropdown">
            <select
              className="dropbtn"
              value={selectedGradeId}
              onChange={(e) => handleGradeSelection(e.target.value)}
            >
              <option value="">Select a Grade</option>
              {grades.map((grade) => (
                <option key={grade._id} value={grade._id}>
                  {grade.gradeName}
                </option>
              ))}
            </select>
          </div>
        )}

        {Array.isArray(subjects) && (
          <div className="dropdown">
            <select
              className="dropbtn"
              value={selectedSubjectId}
              onChange={(e) => handleSubjectSelection(e.target.value)}
            >
              <option value="">Select a Subject</option>
              {subjects.map((subject) => (
                <option key={subject._id} value={subject._id}>
                  {subject.subjectName}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>

      <form className="form" onSubmit={handleSubmit}>
        <label htmlFor="chapterName">Chapter Name:</label>
        <input
          type="text"
          id="chapterName"
          value={chapterName}
          onChange={handleChapterNameChange}
        />

        <label htmlFor="chapterDescription">Chapter Description:</label>
        <textarea
          id="chapterDescription"
          value={chapterDescription}
          onChange={handleChapterDescriptionChange}
        />

        <label htmlFor="thumbnail">Upload Thumbnail:</label>
        <input
          type="file"
          id="thumbnail"
          accept="image/*"
          onChange={handleThumbnailChange}
        />

        <button type="submit">Submit</button>
      </form>

      <div className="sub-navbar">
        <button
          className="get_chapters"
          onClick={() => setShowChapterList(!showChapterList)}
        >
          {showChapterList ? "Hide Chapter Data" : "Get Chapter Data"}
        </button>
      </div>
      {showChapterList && (
        <div className="edit-form">
          <h2 className="section-title">Chapter List</h2>
          <ul>
            {chapters.map((chapter) => (
              <li className="chapter-item" key={chapter._id}>
                <span>{chapter.chapterName}</span>
                <button
                  className="edit-button"
                  onClick={() => handleEditChapter(chapter)}
                >
                  Edit
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
      {selectedChapter && (
        <div className="edit-form">
          <h2 className="section-title">Edit Chapter</h2>
          <label htmlFor="editChapterName">Chapter Name:</label>
          <input
            type="text"
            id="editChapterName"
            value={chapterName}
            onChange={handleChapterNameChange}
            className="input-field"
          />

          <label htmlFor="editChapterDescription">Chapter Description:</label>
          <textarea
            id="editChapterDescription"
            value={chapterDescription}
            onChange={handleChapterDescriptionChange}
            className="textarea-field"
          />

          <button className="update-button" onClick={handleUpdateChapter}>
            Update
          </button>
          <button
            className="cancel-button"
            onClick={() => setSelectedChapter(null)}
          >
            Cancel
          </button>
        </div>
      )}
    </div>
  );
}

export default Unit;
