import React from "react";
import "../../Styles/DeleteAccount.css";

function DeleteAccount() {
  return (
    <>
      <div className="main_div">
        <h1 className="delete_heading">Delete Account</h1>
        <div className="delete-account-container">
          <h1 className="paragraph">Delete Your Account</h1>
          <p className="paragraph">
            If you would like to request the deletion of your account and
            associated data, please send an email to our customer support team.
          </p>
          <p className="paragraph">
            <strong>Customer Support Email:</strong>{" "}
            <a
              href="mailto:info@intelliedtech.com"
              style={{ color: "blue" }}
            >
              info@intelliedtech.com
            </a>
          </p>
          <p className="paragraph">
            Your request will be processed, and your data will be deleted within
            30 days.
          </p>
          <p className="paragraph">
            If you have any questions or need further assistance, feel free to
            reach out to our support team.
          </p>
        </div>
      </div>
    </>
  );
}

export default DeleteAccount;
