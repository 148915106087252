import React, { useState, useEffect } from 'react';
import './AddQuestions.css'; // Import your CSS file

import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
// import loader from "../assets/loader/loader.gif";
import 'react-quill/dist/quill.snow.css';
import ReactQuill, { Quill } from "react-quill";
import katex from "katex";
import "katex/dist/katex.min.css";
import mathquill4quill from 'mathquill4quill';
import "mathquill4quill/mathquill4quill.css";
import { toast } from 'react-toastify';
import axios from 'axios';
import baseUrl from "../../../config/config"
import { useSelector } from 'react-redux';

window.katex = katex;

const API_DEV_TASK = `${baseUrl.task}/tsv/apis/v1`;

const AddQuestions = () => {
  const navigate = useNavigate()
  const users = useSelector((state) => { return state.users });
  const location = useLocation();
  const { state } = location;
  const [newQuestion, setNewQuestion] = useState('');
  const [examData, setExamData] = useState();
  const [editedQuestion, setEditedQuestion] = useState();
  const [editing, setEditing] = useState();

  const [questions, setQuestions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [loading, setLoading] = useState(true);

  const [editedQuestionId, setEditedQuestionId] = useState(null);

  let QuesParagraphQuill = React.createRef();

  const [question, setQuestion] = useState({
    type: '',
    question: '',
    options: [],
    solution: '',
    answer: null,
    difficulty: 'easy',
  });
  useEffect(() => {
    if (state) {
      console.log(state);
      setExamData(state);
      const type = state.examType;
      setQuestion((prev) => ({ ...prev, type }));

    }
  }, [state]);


  let modules = {
    formula: true,
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ["bold", "italic", "underline"], // toggled buttons
      ["blockquote", "code-block"],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ align: [] }],
      ["image", "formula"],
      ["clean"] // remove formatting button
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (state) {
        try {
          const apiUrl = `${API_DEV_TASK}/exam/ques?examId=${state._id}`;
          const response = await axios.get(apiUrl, {
            headers: {
              Authorization: `Bearer ${users.authToken}`,
              'Content-Type': 'application/json',
              Accept: '*/*',
              app_name: 'admin',
              app_version_code: '101',
            }
          });

          setQuestions(response.data.data);
          console.log(response.data.data);
          setLoading(false);
          if (response.data.length === 0) {
            // Display toast if response data is empty
            toast.info('No data found.');
          }
        } catch (error) {
          console.error('Error fetching questions:', error);
          setLoading(false);
          toast.error('Failed to fetch questions. Please try again.');
        }
      }
    };

    fetchData();
  }, [state, question]); // Include state as a dependency

  useEffect(() => {
    const enableMathQuillFormulaAuthoring = mathquill4quill({ Quill, katex });
    if (QuesParagraphQuill.current) {
      enableMathQuillFormulaAuthoring(QuesParagraphQuill.current.editor);
    }
  }, [QuesParagraphQuill]);

  const handleOptionChange = (optionIndex, content) => {
    const updatedOptions = [...question.options];
    updatedOptions[optionIndex] = content;
    setQuestion((prev) => ({ ...prev, options: updatedOptions }));
  };

  const handleSolutionChange = (solution) => {
    setQuestion((prev) => ({ ...prev, solution }));
  };
  const handleAddOption = () => {
    if (question.options.length < 4) {
      setQuestion((prev) => ({ ...prev, options: [...prev.options, ''] }));
    } else {
      toast.error('You can add a maximum of 4 options.');
    }
  };
  const handleEdit = async (questionId) => {
    try {
      const editedQuestion = questions.find((q) => q._id === questionId);
      console.log(editedQuestion)
      setQuestion({
        type: editedQuestion.questionType,
        content: editedQuestion.question,
        options: editedQuestion.options,
        solution: editedQuestion.solution,
        answer: editedQuestion.correctAnswer,
        difficulty: editedQuestion.level,
      });
      setEditedQuestionId(questionId);
      setEditing(true);

    } catch (error) {
      console.error('Error fetching question for editing:', error);
      toast.error('Failed to fetch question for editing. Please try again.');
    }
  };
  console.log(question)


  // const approveHandler = async (examId) => {
  //   try {
  //     const token = localStorage.getItem('token');
  //     const response = await axios.patch(
  //       `${API_DEV_TASK}/exam/{examId}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           'Content-Type': 'application/json',
  //         },
  //       }
  //     );

  //     if (response.status === 200) {
  //       toast.success('Request Send successfully!');
  //       setQuestions((prev) => prev.filter((q) => q._id !== questionId));
  //     }
  //   } catch (error) {
  //     console.error('Error in sending request question:', error);
  //     toast.error('Failed to send request. Please try again.');
  //   }
  // };


  const handleDelete = async (questionId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.delete(
        `${API_DEV_TASK}/delete/ques/?quesId=${questionId}`,
        {
          headers: {
            Authorization: `Bearer ${users.authToken}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
            app_name: 'admin',
            app_version_code: '101',
          }
        }
      );

      if (response.status === 200) {
        toast.success('Question deleted successfully!');
        setQuestions((prev) => prev.filter((q) => q._id !== questionId));
      }
    } catch (error) {
      console.error('Error deleting question:', error);
      toast.error('Failed to delete question. Please try again.');
    }
  };



  const handleCancelEdit = (e) => {
    e.preventDefault();
    setQuestion({
      type: examData.examType,
      content: '',
      options: [],
      solution: '',
      answer: null,
      difficulty: 'easy',
    });
    setEditedQuestionId(null);
    setEditing(false);
  };
  const handleQuestionChange = (content) => {
    // console.log(content)
    setQuestion((prev) => ({ ...prev, content }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!question.content || question.options.length !== 4 || !question.answer) {
        toast.error('Please Enter All Details of the question!');
        return;
      }
      console.log(question)

      const userConfirmed = window.confirm('Do You Want To Submit');

      if (!userConfirmed) {
        return;
      }

      const token = localStorage.getItem('token');
      const requestData = {
        examId: examData._id,
        questionType: question.type,  // Replace with your actual question type
        question: question.content,   // Use question.content instead of question
        level: question.difficulty,
        options: question.options,
        correctAnswer: parseInt(question.answer),  // Parse the answer to an integer
        solution: question.solution,
        markPerQues: 2,  // Replace with the actual mark per question
      };

      const response = editedQuestionId
        ? await axios.put(
          `${API_DEV_TASK}/edit/question/?quesId=${editedQuestionId}`,
          { ...requestData },
          {
            headers: {
              Authorization: `Bearer ${users.authToken}`,
              'Content-Type': 'application/json',
              Accept: '*/*',
              app_name: 'admin',
              app_version_code: '101',
            }
          }
        )
        : await axios.post(
          `${API_DEV_TASK}/create/question`,
          { ...requestData },
          {
            headers: {
              Authorization: `Bearer ${users.authToken}`,
              'Content-Type': 'application/json',
              Accept: '*/*',
              app_name: 'admin',
              app_version_code: '101',
            }
          }
        );

      if (response.status === 200) {
        toast.success(editedQuestionId ? 'Question updated successfully!' : 'Question created successfully!');
        setQuestion({
          type: examData.examType,
          content: '',
          options: [],
          solution: '',
          correctAnswer: null,
          difficulty: 'easy',
        });

        const updatedResponse = await axios.get(`${API_DEV_TASK}/exam/ques?examId=${examData._id}`, {
          headers: {
            Authorization: `Bearer ${users.authToken}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
            app_name: 'admin',
            app_version_code: '101',
          }
        });
        setQuestions(updatedResponse.data.data);
        setEditedQuestionId(null);
        setEditing(false);
      }
    } catch (error) {
      console.error(editedQuestionId ? 'Error updating question:' : 'Error creating question:', error);
      toast.error(error.response.data.error);
    }
  };

  // Add a conditional check to ensure examData exists
  if (!examData) {
    return <div>Loading...</div>; // or render a loading indicator
  }

  return (
    <div className="exam-detail-card">
      <h2 style={{ color: "#3498db", fontSize: '2rem', fontWeight: 'bold' }}>Exam Details</h2>
      <div className=''>
        <table>
          <thead>
            <tr>

              <th>Name</th>
              {/* <th> Class</th> */}
              <th>Subject</th>
              <th>Number of Questions</th>
              <th>Exam Type</th>

              <th>Exam Duration</th>
              <th>Price</th>

              <th>Publish Status</th>
              {/* <th>Action</th> */}
            </tr>
          </thead>
          <tbody>

            <tr>
              <td>{examData.examName}</td>
              {/* <td>{examData.subject}</td> */}
              <td>{examData.subject}</td>
              <td>{examData.numberOfQuestions}</td>
              <td>{examData.examType}</td>
              <td>{examData.examDuration}</td>
              <td>{examData.price}</td>

              <td>{examData.publishStatus}</td>
              <td>
                <div className='admin_panel_table_btns'>
                  {/* {console.log(examData.publishStatus==="true")} */}
                  {examData.publishStatus === "true" ? "Approved Already" : null}
                  {examData.publishStatus === "false" ? "Resource Rejected" : null}
                  {/* {examData.publishStatus === "draft" ? <button className='admin_panel_table_btns_btn2' onClick={()=> approveHandler(examData?._id)}>send for Approval</button> : null} */}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* Uncomment the following section if you want to add functionality to add questions */}
      <div>
        {Array.isArray(questions) && questions.length > 0 && (
          <div style={{ width: '95%', alignItems: 'center', justifyContent: 'center', margin: 'auto' }}>

            {questions && questions.map((question, index) => (
              <div className='questionCard' key={index}>
                <h3 style={{ color: "#3498db", fontSize: '1.5rem', fontWeight: 'normal' }}>Question {index + 1}</h3>
                <div className='question-list'>
                  <div className='questionwidth'>
                    <div dangerouslySetInnerHTML={{ __html: question.question }} />

                    {question.options.map((option, optionIndex) => (
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }} key={optionIndex}>
                        <input
                          className='correct-ans'
                          type="radio"
                          name="answer"
                          checked={question.correctAnswer == optionIndex} // Corrected comparison here
                          value={optionIndex}
                          onChange={() => handleOptionChange(index, optionIndex)}
                          required
                        />
                        <div dangerouslySetInnerHTML={{ __html: option }} />
                      </div>
                    ))}


                  </div>
                  <div style={{ width: '20%', height: '100%', display: 'flex', flexDirection: 'column', padding: '5px' }}>
                    <button onClick={() => handleEdit(question._id)} style={{ padding: '4px 5px', color: '#3498db', backgroundColor: 'aliceblue', fontSize: '14px', borderRadius: '10px', fontWeight: 'bold', marginTop: '10px' }}>Edit</button>
                    <button onClick={() => handleDelete(question._id)} style={{ padding: '4px 5px', color: '#3498db', backgroundColor: 'bisque', fontSize: '14px', borderRadius: '10px', fontWeight: 'bold', marginTop: '10px' }}>Delete</button>
                  </div>
                </div>
                <div style={{ flex: 1, padding: '10px', overflowY: 'auto', width: '100%' }}>
                  <h3 style={{ color: "#3498db", fontSize: '1.5rem', fontWeight: 'normal' }}>Solution</h3>
                  <div dangerouslySetInnerHTML={{ __html: question.solution }} />
                  {/* Add any additional details you want to display */}
                </div>
                <br />
              </div>
            ))}
          </div>
        )}
        <div>
          <h3 style={{ color: "#3498db", fontSize: '1.5rem', fontWeight: 'bold' }}> Add Your Question</h3>
          <ReactQuill
            style={{ backgroundColor: '#fff' }}
            className='shadow form-textarea mt-1 block w-full border rounded w-full text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
            id="editor"
            ref={QuesParagraphQuill}
            modules={modules}
            placeholder="Type question here"
            theme="snow"
            value={question.content}
            onChange={handleQuestionChange}
          />
        </div>
        {question.type === 'multipleChoice' && (
          <>
            <h3 style={{ color: "#3498db", fontSize: '1.5rem', fontWeight: 'bold' }}>Options</h3>
            {question.options.map((option, optionIndex) => (
              <div className='options-question' key={optionIndex}>
                <input
                  className='correct-ans'
                  type="radio"
                  name="answer"
                  checked={(optionIndex) == question.answer}
                  value={optionIndex}
                  onChange={(e) =>
                    setQuestion((prev) => ({ ...prev, answer: e.target.value }))
                  }
                />
                <div className='option-edit'>
                  <ReactQuill
                    style={{ backgroundColor: '#fff' }}
                    className='shadow form-textarea mt-1 block  border rounded w-full text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                    id="editor"
                    placeholder={`Option ${optionIndex + 1}`}
                    ref={QuesParagraphQuill}
                    modules={modules}
                    theme="snow"
                    value={option}
                    onChange={(e) => handleOptionChange(optionIndex, e)}
                  />
                </div>
              </div>
            ))}
            <button className="add-option-btn" onClick={handleAddOption}>
              Add Option
            </button>
          </>
        )}
        <div>
          <h3 style={{ color: "#3498db", fontSize: '1.5rem', fontWeight: 'bold' }}>Your Solution</h3>
          <ReactQuill
            style={{ backgroundColor: '#fff' }}
            className='shadow form-textarea mt-1 block w-full border rounded w-full text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
            id="editor"
            placeholder="Type your solution here"
            ref={QuesParagraphQuill}
            modules={modules}
            theme="snow"
            value={question.solution}
            onChange={(e) => handleSolutionChange(e)}
          />
        </div>
        {editedQuestionId ? (
          <div className="buttons-container">
            <button className="cancel-btn" onClick={handleCancelEdit}>
              Cancel
            </button>
            <button className="update-btn" onClick={handleSubmit}>
              Update
            </button>
          </div>
        ) : (
          <button className="submit-btn" onClick={handleSubmit}>
            Submit
          </button>
        )}
      </div>
      <div style={{ display: 'flex', justifyContent: "center" }}>
        <button className="submit-btn" onClick={() => navigate('/exams')}>
          Submit All Questions to Exam
        </button>
      </div>
    </div>
  );
};

export default AddQuestions;
