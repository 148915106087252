import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../Styles/Setting.css';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import baseUrls from '../config/config'
import { useSelector } from 'react-redux';


const Setting = ({ userData }) => {
  const userDatas = useSelector((state) => { return state.users });
  const [name, setName] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [role, setRole] = useState('');
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editingUser, setEditingUser] = useState({});
  const navigate = useNavigate();
  console.log(users)
  useEffect(() => {
    fetchUsers();
  }, []);
  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${baseUrls.auth}/ums/apis/v1/admin/`, {
        headers: {
          Authorization: `Bearer ${users.authToken}`,
          'Content-Type': 'application/json',
          Accept: '*/*',
          app_name: 'admin',
          app_version_code: '101',
        },
      });

      if (response.status === 200) {
        setUsers(response.data); // Set the fetched users in the state
      }
    } catch (error) {
      console.error('Error fetching users', error);
      // Handle error here
      toast.error('Error fetching users');
    }
  };


  const handleEditUser = (user) => {
    setEditMode(true);
    setEditingUser(user);
  };

  // Function to handle canceling the edit
  const handleCancelEdit = () => {
    setEditMode(false);
    setEditingUser({});
  };

  // Function to handle updating a user
  const handleUpdateUser = async () => {
    try {
      const response = await axios.put(
        `${baseUrls.auth}/ums/apis/v1/admin/${editingUser._id}`,
        {
          name: editingUser.name,
          email: editingUser.email,
          phone: editingUser.phone,
          role: editingUser.role,
        },
        {
          headers: {
            Authorization: `Bearer ${users.authToken}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
            app_name: 'admin',
            app_version_code: '101',
          },
        }
      );

      if (response.status === 200) {
        toast.success('User updated successfully');
        setEditMode(false);
        fetchUsers();
      }
    } catch (error) {
      console.error('Error updating user', error);
      toast.error('Error updating user');
    }
  };

  // Function to handle deleting a user
  const handleDeleteUser = async (userId) => {
    try {
      if (userId) {
        const userConfirmed = window.confirm(
          'One User is Deleting?'
        );

        if (!userConfirmed) {
          return;
        }
      }
      console.log(userDatas)
      if (userDatas.usertype != 'S_Admin') {
        toast.error('You are not Super Admin');
        return;
      }
      const response = await axios.delete(`${baseUrls.auth}/ums/apis/v1/admin/${userId}`, {
        headers: {
          Authorization: `Bearer ${users.authToken}`,
          'Content-Type': 'application/json',
          Accept: '*/*',
          app_name: 'admin',
          app_version_code: '101',
        },
      });

      if (response.status === 204) {
        setUsers((prevUsers) => prevUsers.filter((user) => user._id !== userId));
        toast.success('User deleted successfully');
      }
    } catch (error) {
      console.error('Error deleting user', error);
      toast.error('Error deleting user');
    }
  };



  const handleSignup = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    try {
      if (!name || !email || !phone || !role) {
        toast.error('Please Enter All Content');
        return;
      }
      const response = await axios.post(`${baseUrls.auth}/ums/apis/v1/admin`, {
        name,
        email,
        phone,
        password,
        role,
      },
        {
          headers: {
            Authorization: `Bearer ${users.authToken}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
            app_name: 'admin',
            app_version_code: '101',
          },
        }
      );
      if (response.status === 201) {
        toast.success('User Created successful!');
        setName('');
        setPhone('');
        setEmail('')
        setPassword('');
        setConfirmPassword('');
        setRole('');
        fetchUsers();
      }
    } catch (error) {
      console.error('Error in registration', error);
      toast.error(error.response.data.error);

    }
  };

  return (
    <div className="admin_panel_resource_container">
      <div className="flex justify-center">
        <form onSubmit={handleSignup} className='border-[2px] border-blue-500 p-20 rounded-3xl'>
          <h2 className='text-xl font-bold text-center text-blue-500'>Create Users To IntelliEdtech</h2>
          <div className="input-group">
            <label htmlFor="email">Full Name</label>
            <input
              className="signup-input"
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="input-group">
            <label htmlFor="email">Email</label>
            <input
              className="signup-input"
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="input-group">
            <label htmlFor="phone">Mobile</label>
            <input
              className="signup-input"
              type="number"
              id="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">Password</label>
            <input
              className="signup-input"
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="input-group">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
              className="signup-input"
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          <div className="input-group">
            <label htmlFor="role">Role</label>
            <select
              className="signup-input"
              id="role"
              value={role}
              onChange={(e) => setRole(e.target.value)}
            >
              <option value="">Select a Role</option>
              <option value="O_Manager">Operation Manager</option>
              <option value="C_Creator">Content Creator</option>
              <option value="M_Expert">Matter Expert</option>
            </select>
          </div>
          <button className="signup-button" type="submit">
            Create New User
          </button>
          <ToastContainer />
        </form>
        {error && <p className="error-message">{error}</p>}
      </div>
      <h2 className="user-list-heading">User List</h2>
      <table className="w-[70vw]">
        <thead>
          <tr>
            <th>Name</th>
            <th>
              {editMode ? "Edit Mail" : "User Id"}
            </th>
            <th>Phone</th>
            <th>Role</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className='text-center'>
          {users.map((user) => (
            <tr key={user.id}>
              <td>
                {editMode && user._id === editingUser._id ? (
                  <input
                    type="text"
                    value={editingUser.name}
                    onChange={(e) =>
                      setEditingUser({
                        ...editingUser,
                        name: e.target.value,
                      })
                    }
                  />
                ) : (
                  user.name
                )}
              </td>
              <td>
                {editMode && user._id === editingUser._id ? (
                  <input
                    type="email"
                    value={editingUser.email}
                    onChange={(e) =>
                      setEditingUser({
                        ...editingUser,
                        email: e.target.value,
                      })
                    }
                  />
                ) : (
                  user._id
                )}
              </td>
              <td>
                {editMode && user._id === editingUser._id ? (
                  <input
                    type="number"
                    value={editingUser.phone}
                    onChange={(e) =>
                      setEditingUser({
                        ...editingUser,
                        phone: e.target.value,
                      })
                    }
                  />
                ) : (
                  user.phone
                )}
              </td>
              <td>
                {editMode && user._id === editingUser._id ? (
                  <select
                    value={editingUser.role}
                    onChange={(e) =>
                      setEditingUser({
                        ...editingUser,
                        role: e.target.value,
                      })
                    }
                  >
                    <option value="O_Manager">Operation Manager</option>
                    <option value="C_Creator">Content Creator</option>
                    <option value="M_Expert">Matter Expert</option>
                  </select>
                ) : (
                  user.role
                )}
              </td>
              <td className='mb-10'>
                {editMode && user._id === editingUser._id ? (
                  <>
                    <button className="edit-button" onClick={handleUpdateUser}>Update</button>
                    <button className="delete-button" onClick={handleCancelEdit}>Cancel</button>
                  </>
                ) : (
                  <>
                    <button className="edit-button" onClick={() => handleEditUser(user)}>Edit</button>
                    <button className="delete-button" onClick={() => handleDeleteUser(user._id)}>Delete</button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

    </div>
  );
};

export default Setting;
