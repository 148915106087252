import React, { useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import axios from "axios";
import { useSelector } from "react-redux";
import baseurl from "../config/config";

Modal.setAppElement("#root");

const CustomModal = ({
  modalOpen,
  setModalOpen,
  selecteddata,
  setSelecteddata,
  dataType,
  setReload,
  reload,
}) => {
  const users = useSelector((state) => state.users);
  const [showInput, setShowInput] = useState(false);
  const [input, setInput] = useState("");

  const handleAdd = async () => {
    if (!input) {
      toast.error("Please write proper text to add.");
      return;
    }

    const body = { name: input };

    try {
      let url;
      switch (dataType) {
        case "curriculum":
          url = `${baseurl.auth}/ums/apis/v1/curriculumfile/create`;
          break;
        case "grade":
          url = `${baseurl.auth}/ums/apis/v1/classes/create`;
          break;
        case "subject":
          url = `${baseurl.auth}/ums/apis/v1/subject/create`;
          break;
        case "chapter":
          url = `${baseurl.auth}/ums/apis/v1/chapter/create`;
          break;
        case "topic":
          url = `${baseurl.auth}/ums/apis/v1/topics/create`;
          break;
        case "subtopic":
          url = `${baseurl.auth}/ums/apis/v1/subtopics/create`;
          break;
        case "language":
          url = `${baseurl.auth}/ums/apis/v1/language/create`;
          break;
        default:
          break;
      }

      if (url) {
        const response = await axios.post(url, body, {
          headers: {
            Authorization: `Bearer ${users.authToken}`,
            "Content-Type": "application/json",
            Accept: "*/*",
            app_name: "admin",
            app_version_code: "101",
          },
        });

        if (response.status === 201) {
          toast.success("Added Successfully");
          setReload(!reload);
          setModalOpen(false);
          setSelecteddata([]);
        } else {
          toast.error("Error while adding.");
        }
      }
    } catch (error) {
      toast.error("Error while adding.");
    }
  };

  const handleDelete = async (item) => {
    try {
      let url;
      switch (dataType) {
        case "curriculum":
          url = `${baseurl.auth}/ums/apis/v1/curriculumfile/delete/${item.value}`;
          break;
        case "grade":
          url = `${baseurl.auth}/ums/apis/v1/classes/delete/${item.value}`;
          break;
        case "subject":
          url = `${baseurl.auth}/ums/apis/v1/subject/delete/${item.value}`;
          break;
        case "chapter":
          url = `${baseurl.auth}/ums/apis/v1/chapter/delete/${item.value}`;
          break;
        case "topic":
          url = `${baseurl.auth}/ums/apis/v1/topics/delete/${item.value}`;
          break;
        case "subtopic":
          url = `${baseurl.auth}/ums/apis/v1/subtopics/delete/${item.value}`;
          break;
        case "language":
          url = `${baseurl.auth}/ums/apis/v1/language/delete/${item.value}`;
          break;
        default:
          break;
      }

      if (url) {
        const response = await axios.delete(url, {
          headers: {
            Authorization: `Bearer ${users.authToken}`,
            "Content-Type": "application/json",
            Accept: "*/*",
            app_name: "admin",
            app_version_code: "101",
          },
        });

        if (response.status === 200) {
          toast.success("Deleted Successfully");
          setReload(!reload);
          setSelecteddata(
            selecteddata.filter((data) => data.value !== item.value)
          );
        } else {
          toast.error("Error while deleting.");
        }
      }
    } catch (error) {
      toast.error("Error while deleting.");
    }
  };

  return (
    <Modal
      className="admin-panel-resource-modal"
      isOpen={modalOpen}
      onRequestClose={() => {
        setModalOpen(false);
        setSelecteddata([]);
      }}
      contentLabel="Change The Master Data"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
    >
      <div className="p-6 bg-white rounded-lg shadow-md w-96">
        <div className="flex justify-between items-center mb-10">
          <div
            className="text-xl font-semibold cursor-pointer"
            onClick={() => setShowInput(!showInput)}
          >
            + Add New
          </div>
          <div
            onClick={() => setModalOpen(false)}
            className="text-xl font-bold cursor-pointer"
          >
            X
          </div>
        </div>
        {showInput && (
          <div className="mb-5">
            <input
              className="border border-black p-2 rounded"
              placeholder="Enter value"
              onChange={(e) => setInput(e.target.value)}
            />
            <button
              className="ml-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              onClick={handleAdd}
            >
              Add New
            </button>
          </div>
        )}
        <div className="space-y-2">
          {selecteddata?.map((item, index) => (
            <div
              key={index}
              className="flex justify-between items-center p-2 bg-gray-100 rounded"
            >
              <span>{item.value}</span>
              <span
                className="cursor-pointer text-red-500 hover:text-red-700"
                onClick={() => handleDelete(item)}
              >
                X
              </span>
            </div>
          ))}
        </div>
        <button
          className="mt-4 px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-600"
          onClick={() => {
            setModalOpen(false);
            setSelecteddata([]);
          }}
        >
          Save Changes
        </button>
      </div>
    </Modal>
  );
};

export default CustomModal;
