import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import baseUrl from "../../config/config";

export const fetchClassOptions = createAsyncThunk(
  'options/fetchClassOptions',
  async () => {
    const response = await axios.get(`${baseUrl.auth}/ums/apis/v1/classes/getAll`);
    return response.data;
  }
);

export const fetchLanguageOptions = createAsyncThunk(
  'options/fetchLanguageOptions',
  async () => {
    const response = await axios.get(`${baseUrl.auth}/ums/apis/v1/language/getAll`);
    return response.data;
  }
);

export const fecthCurriculumOptions = createAsyncThunk(
  'options/fetchCurriculumOptions',
  async () => {
    const response = await axios.get(`${baseUrl.auth}/ums/apis/v1/curriculumfile/getAll`);
    return response.data;
  }
);


export const fetchsubjectoptions = createAsyncThunk(
  'options/fetchsubjectoptions',
  async () => {
    const response = await axios.get(`${baseUrl.auth}/ums/apis/v1/subject/getAll`);
    return response.data;
  }
);

const optionsSlice = createSlice({
  name: 'options',
  initialState: {
    classOptions: [],
    languageOptions: [],
    curriculumOptions: [], 
    subjectOptions:[],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchClassOptions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchClassOptions.fulfilled, (state, action) => {
        state.classOptions = action.payload;
        state.loading = false;
      })
      .addCase(fetchClassOptions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchLanguageOptions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLanguageOptions.fulfilled, (state, action) => {
        state.languageOptions = action.payload;
        state.loading = false;
      })
      .addCase(fetchLanguageOptions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }).addCase(fecthCurriculumOptions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fecthCurriculumOptions.fulfilled, (state, action) => {
        state.curriculumOptions = action.payload;
        state.loading = false;
      })
      .addCase(fecthCurriculumOptions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }).addCase(fetchsubjectoptions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchsubjectoptions.fulfilled, (state, action) => {
        state.subjectOptions = action.payload;
        state.loading = false;
      })
      .addCase(fetchsubjectoptions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      //Add cases for other options similarly
  },
});

export default optionsSlice.reducer;