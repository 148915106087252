import React, { useEffect, useState } from "react";
import logo from "../../assets/logo2.png";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import axios from "axios";
import { useSelector } from "react-redux";
import CustomModal from "../../components/CustomModal";
import baseUrl from "../../config/config";
import { languageOptions } from "../../constants/constant";

function MasterData() {
  const users = useSelector((state) => state.users);
  const [reload, setReload] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [dataType, setDataType] = useState("");
  const [curriculum, setCurriculum] = useState([]);
  const [grade, setGrade] = useState([]);
  const [subject, setSubject] = useState([]);
  const [chapter, setChapter] = useState([]);
  const [topic, setTopic] = useState([]);
  const [subtopic, setSubtopic] = useState([]);
  const [language,setLanguage]=useState([]);
  const fetchData = async (endpoint, setState) => {
    try {
      const response = await axios.get(`${baseUrl.auth}/ums/apis/v1/${endpoint}`, {
        headers: {
          Authorization: `Bearer ${users.authToken}`,
          'Content-Type': 'application/json',
          Accept: '*/*',
          app_name: 'admin',
          app_version_code: '101',
        },
      });
      console.log(response.data);
      setState(response.data);
    } catch (error) {
      toast.error(`Error while fetching ${endpoint} details`);
      console.error(`Error fetching ${endpoint} details:`, error);
    }
  };

  useEffect(() => {
    fetchData('curriculumfile/getAll', setCurriculum);
    fetchData('classes/getAll', setGrade);
    fetchData('subject/getAll', setSubject);
    fetchData('chapter/getAll', setChapter);
    fetchData('topics/getAll', setTopic);
    fetchData('subtopics/getAll', setSubtopic);
    fetchData('language/getAll',setLanguage);
  }, [reload]);

  const handleEditAddClick = (type, data) => {
    setSelectedData(data);
    setModalOpen(true);
    setDataType(type);
  };

  const navigate = useNavigate();

  return (
    <div className="admin_panel_resource_container">
      <CustomModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        selecteddata={selectedData}
        setSelecteddata={setSelectedData}
        setReload={setReload}
        reload={reload}
        dataType={dataType}
      />
      <div className="admin_panel_resource_logo_img_container">
        <img
          onClick={() => navigate("/")}
          className="admin_panel_resource_logo_img"
          src={logo}
          alt="logo"
        />
      </div>
      <div className="admin_panel_resource_container_cont">
        <h1 className="admin_panel_resource_hed">Master Data</h1>
      </div>
      <div className="flex flex-col items-center justify-center w-[70vw] space-y-16">
        <h3 className="text-2xl font-semibold text-blue-500">Content Panel</h3>

        {[
          { label: 'Curriculum', options: curriculum, type: 'curriculum' },
          { label: 'Grade', options: grade, type: 'grade' },
          { label: 'Subject', options: subject, type: 'subject' },
          { label: 'Chapter', options: chapter, type: 'chapter' },
          {label: 'Topic', options: topic, type: 'topic'},
          {label: 'Sub-Topic', options: subtopic, type: 'subtopic'},
          { label: 'Language', options: language, type: 'language' },
          // { label: 'Topic', options: languageOptions, type: 'topic' },
          // { label: 'Sub-Topic', options: languageOptions, type: 'subtopic' },
        ].map(({ label, options, type }) => (
          <div key={type}>
            <div className="flex justify-around items-center space-x-10">
              <div className="flex items-center">
                <div>
                  <label className="text-[#4A3794]" htmlFor={type}>
                    {label}
                  </label>
                  <Select
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        minWidth: "300px",
                        borderColor: "grey",
                        height: "48px",
                        cursor: "pointer",
                      }),
                      dropdownIndicator: (baseStyles) => ({
                        ...baseStyles,
                        color: "#6B4EFF",
                      }),
                    }}
                    placeholder={label}
                    options={options}
                  />
                </div>
              </div>
              <div
                className="mt-5"
                onClick={() => handleEditAddClick(type, options)}
              >
                <button className="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-blue-500 rounded-md focus:outline-none focus:bg-gray-600">
                  Edit/Add
                </button>
              </div>
            </div>
          </div>
        ))}
        <div className="h-[10vh]" />
      </div>
    </div>
  );
}

export default MasterData;
