import React, { useState, useEffect } from 'react';
import './Grade.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import baseUrl from "../../../../config/config";

function Grade() {
  const [gradeName, setGradeName] = useState('');
  const [gradeDescription, setGradeDescription] = useState('');
  // const [thumbnail, setThumbnail] = useState(null);
  // const [exams, setExams] = useState([]);
  const [selectedExamId, setSelectedExamId] = useState('');

  // const [examName, setExamName] = useState('');
  // const [examDescription, setExamDescription] = useState('');
  const [thumbnail, setThumbnail] = useState(null);
  const [exams, setExams] = useState([]);
  const [selectedExam, setSelectedExam] = useState(null);
  const [showExamList, setShowExamList] = useState(false);
  const [grade, setGrades] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [showGradeList, setShowGradeList] = useState(false);

  const [imageUrl, setImageUrl] = useState()


  // const baseUrl.Url /v1= "http://localhost:4001/v1";
  // const baseUrl.Url /v1= 'https://content-panel-intelli.onrender.com/v1'
  // const baseUrl.Url /v1= 'https://test-content-dr78.onrender.com/v1'
  // const API_BASE_URL = 'https://internalrelease.onrender.com/v1'
// const baseUrl.Url /v1= 'https://content-panel-backend-urll.onrender.com/v1'



  useEffect(() => {
    fetchExams();
  }, []);

  const fetchExams = async () => {
    try {
      const response = await axios.get(`${baseUrl.Url}/v1/exam/getExams`);
      setExams(response.data.data);
    } catch (error) {
      console.error('Error fetching exams:', error);
    }
  };

  const fetchGrades = async (examId) => {
    try {
      const response = await axios.get(`${baseUrl.Url}/v1/grade/getgrades/${examId}`);
      setGrades(response.data.data);
    } catch (error) {
      console.error('Error fetching grade:', error);
    }
  };

  const handleExamSelection = async (examId) => {
    setSelectedExamId(examId);
    fetchGrades(examId);
  };

  const handleGradeNameChange = (e) => {
    setGradeName(e.target.value);
  };

  const handleSubjectDescriptionChange = (e) => {
    setGradeDescription(e.target.value);
  };

  const handleThumbnailChange = (e) => {
    const file = e.target.files[0];
    setThumbnail(file);

    const data = new FormData();
    data.append("file" , thumbnail)
    data.append("upload_preset", "dagada")
    data.append("cloud_name" , 'dfzd4xbsk')
  
    fetch("https://api.cloudinary.com//v1_1/dfzd4xbsk/image/upload" , {
      method: 'POST',
      body: data
    }).then((res)=>res.json())
    .then((data)=>{
      console.log(data)
      setImageUrl(data.url);
    }).catch((err)=>{
      console.log(err);
      toast.error(err);
    })
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!gradeName) {
        toast.error('Please Enter Exam Name');
        return;
      }

      if (!imageUrl) {
        const userConfirmed = window.confirm(
          'Thumbnail image URL is empty. Do you want to proceed without a thumbnail?'
        );
  
        if (!userConfirmed) {
          return;
        }
      }
      // const imageUrl = cld.image(thumbnail).toURL();
      const token = localStorage.getItem('token');

      const response = await axios.post(
        `${baseUrl.Url}/v1/grade/creategrade`,
        {
          gradeName,
          gradeDescription,
          thumbnail: imageUrl,
          examId: selectedExamId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('response', response);
      if (response.status === 201 || response.status === 200) {
        toast.success('Subject created successfully!');
        setGradeName('');
        setGradeDescription('');
        setThumbnail(null);
        setSelectedExamId('');
        setImageUrl(null);
        clearFileInput(); // Clear the file input field
        fetchExams();
      }
    } catch (error) {
      console.error('Error creating subject:', error);
      toast.error(error.response.data.error);

    }
  };
  const clearFileInput = () => {
    const fileInput = document.getElementById('thumbnail');
    if (fileInput) {
      fileInput.value = ''; // Clear the value of the file input
    }
  };



  const handleEditSubject = (subject) => {
    setSelectedGrade(subject);
    setGradeName(subject.gradeName);
    setGradeDescription(subject.gradeDescription);
  };

  const handleUpdateSubject = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.put(
        `${baseUrl.Url}/v1/grade/updategrade/${selectedGrade._id}`,
        {
          gradeName,
          gradeDescription,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        toast.success('Subject updated successfully!');
        setSelectedGrade(null);
        setSelectedExamId('');
        setGrades([])
        fetchExams();
      }
    } catch (error) {
      console.error('Error updating subject:', error);
      toast.error('An error occurred while updating the subject.');
    }
  };



  return (
    <div className="App">
      <div className="sub-navbar">
        <select
          className="dropdown-select"
          value={selectedExamId}
          onChange={(e) => handleExamSelection(e.target.value)}
        >
          <option value="">Select an Exam</option>
          {Array.isArray(exams) ? (
                    exams.map((exam) => (
                    <option key={exam._id} value={exam._id}>
                      {exam.examName}
                    </option>
                  ))
                ) : (
                  <option key={exams._id} value={exams._id}>
                    {exams.examName}
                  </option>
                )}
        </select>
      </div>
      <form className="form" onSubmit={handleSubmit}>
        <label htmlFor="gradeName">Grade Name:</label>
        <input
          type="text"
          id="gradeName"
          value={gradeName}
          onChange={handleGradeNameChange}
        />

        <label htmlFor="gradeDescription">Grade Description:</label>
        <textarea
          id="gradeDescription"
          value={gradeDescription}
          onChange={handleSubjectDescriptionChange}
        />

        <label htmlFor="thumbnail">Upload Thumbnail:</label>
        <input
          type="file"
          id="thumbnail"
          accept="image/*"
          onChange={handleThumbnailChange}
        />

        <button type="submit">Submit</button>
      </form>

      <div className="sub-navbar">
        <button className='get_subjects' onClick={() => setShowGradeList(!showGradeList)}>
          {showGradeList ? 'Hide Grade Data' : 'Get Grade Data'}
        </button>
      </div>
      {showGradeList && (
        <div className="edit-form">
          <h2 className="section-title">Grade List</h2>
          <ul>
            {grade.map((subject) => (
              <li className="subject-item" key={subject._id}>
                <span>{subject.gradeName}</span>
                <button className="edit-button" onClick={() => handleEditSubject(subject)}>Edit</button>
              </li>
            ))}
          </ul>
        </div>
      )}
      {selectedGrade && (
        <div className="edit-form">
          <h2 className="section-title">Edit Grade</h2>
          <label htmlFor="editSubjectName">Grade Name:</label>
          <input
            type="text"
            id="editSubjectName"
            value={gradeName}
            onChange={handleGradeNameChange}
            className="input-field"
          />

          <label htmlFor="editSubjectDescription">Grade Description:</label>
          <textarea
            id="editSubjectDescription"
            value={gradeDescription}
            onChange={handleSubjectDescriptionChange}
            className="textarea-field"
          />

          <button className="update-button" onClick={handleUpdateSubject}>Update</button>
          <button className="cancel-button" onClick={() => setSelectedGrade(null)}>Cancel</button>
        </div>
      )}
    </div>
  );
}


export default Grade;
