import React, { useEffect, useState } from "react";
import logo from "../../assets/logo2.png";
import search from "../../assets/icons/search.png";
import "../../Styles/Resource.css";
import { data } from "../../utils/data";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import { useSelector } from "react-redux";
import loader from "../../assets/icons/loader.gif";
import baseUrl from "../../config/config";
import CopyToClipboard from "react-copy-to-clipboard";
function GeneratePayment() {
  const [loading, setLoading] = useState(false);
  const users = useSelector((state) => {
    return state.users;
  });
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [amount, setAmount] = useState("");
  const [email, setEmail] = useState("");
  const [service, setService] = useState("");
  const [data, setData] = useState(null);
  const [value, setValue] = React.useState("some\ntext");
  const [copied, setCopied] = React.useState(false);
  const onCopy = React.useCallback(() => {
    setCopied(true);
  }, []);
  const onChange = React.useCallback(({ target: { value } }) => {
    setValue(value);
    setCopied(true);
  }, []);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const navigate = useNavigate();
  const handleToExam = async (e) => {
    e.preventDefault();
    // const paydata = {
    //   transactionId: "T" + Date.now(),
    //   MUID: "6589154191660a9aacafafe7",
    //   name: name,
    //   amount: amount,
    //   number: mobile,
    //   productId: [
    //     {
    //       Id: "BATCH_542c87e2-c805-4ef9-886a-0bf26549a66b",
    //       name: service,
    //     },
    //   ],
    // };

    const paydata = {
      // amount: +amount,
      // currency: "INR",
      // description: capitalizeFirstLetter(service),
      // name: capitalizeFirstLetter(name),
      // contact: mobile,
      // callback_url: "http://13.234.35.93:3000/success",



      amount: +amount,
      currency: "INR",
      receipt: service,
      name: name,
      mobileNumber: mobile,
      // email: email,
    };
    console.log("paydata", paydata);
    try {
      const response = await axios.post(
        // "https://4i3482jteb.execute-api.ap-south-1.amazonaws.com/dev/payment-service/api/payment-admin",
        // { ...paydata }

        `${baseUrl.Razor}/api/payment-link`,
        { ...paydata }
      );
      console.log('response', response.data);
      setData(`${baseUrl.webUrl}payment/${response.data._id}`);
    } catch (error) {
      console.error("Payment failed:", error);
    }
  };
  if (loading) {
    return (
      <div className="admin_panel_resource_container_loading">
        <img
          src={loader}
          className="admin_panel_resource_container_loading_img"
        />
      </div>
    );
  } else {
    return (
      <div className="admin_panel_resource_container">
        <div className="admin_panel_resource_logo_img_container">
          <img
            onClick={() => {
              navigate("/");
            }}
            className="admin_panel_resource_logo_img"
            src={logo}
            alt="logo"
          />
        </div>
        <div className="admin_panel_resource_container_cont">
          <h1 className="admin_panel_resource_hed">Generate Payment</h1>
        </div>
        <form onSubmit={handleToExam}>
          <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
            <div>
              <label className="text-[#4A3794]" htmlFor="username">
                Name Of Payee
              </label>
              <input
                id="username"
                type="text"
                required={true}
                className="block w-full px-4 py-2 mt-2 text-black bg-white border border-[#4A3794] rounded-md  focus:outline-none focus:ring placeholder:text-slate-500"
                placeholder="Name of Payee"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="mt-1">
              <label className="text-[#4A3794]" htmlFor="username">
                Amount Required
              </label>
              <input
                id="username"
                type="number"
                className="block w-full px-4 py-2 mt-2 text-black bg-white border border-[#4A3794] rounded-md  focus:outline-none focus:ring placeholder:text-slate-500"
                placeholder="Amount in (RS.)"
                required={true}
                onChange={(e) => setAmount(e.target.value)}
              />
            </div>
            <div className="mt-1">
              <label className="text-[#4A3794]" htmlFor="username">
                Name Of Service
              </label>
              <input
                id="username"
                type="text"
                required={true}
                className="block w-full px-4 py-2 mt-2 text-black bg-white border border-[#4A3794] rounded-md  focus:outline-none focus:ring placeholder:text-slate-500"
                placeholder="Name of the service"
                onChange={(e) => setService(e.target.value)}
              />
            </div>
            <div className="mt-1">
              <label className="text-[#4A3794]" htmlFor="username">
                Mobile Number
              </label>
              <input
                id="username"
                type="number"
                className="block w-full px-4 py-2 mt-2 text-black bg-white border border-[#4A3794] rounded-md  focus:outline-none focus:ring placeholder:text-slate-500"
                placeholder="Mobile Number"
                required={true}
                onChange={(e) => setMobile(e.target.value)}
              />
            </div>

            {/* <div className="mt-1">
              <label className="text-[#4A3794]" htmlFor="username">
                Email
              </label>
              <input
                id="username"
                type="text"
                className="block w-full px-4 py-2 mt-2 text-black bg-white border border-[#4A3794] rounded-md  focus:outline-none focus:ring placeholder:text-slate-500"
                placeholder="Your Email"
                required={true}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div> */}
          </div>
          <div className="flex justify-center  mt-6 ">
            <button className="p-5 leading-5 text-white transition-colors duration-200 transform bg-blue-500 rounded-md focus:outline-none focus:bg-gray-600">
              Generate Link
            </button>
          </div>
          <div className="flex justify-center items-center space-x-5 mt-6 ">
            {data && (
              <h2 className="bg-blue-500 text-white p-5">
                {data?.slice(0, 70)}....
              </h2>
            )}
            <div className="space-x-5">
              <CopyToClipboard onCopy={onCopy} text={data}>
                <button>Copy to clipboard with button</button>
              </CopyToClipboard>
              {copied ? <span style={{ color: "red" }}>Copied.</span> : null}
            </div>
          </div>
        </form>
      </div>
    );
  }
}
export default GeneratePayment;
