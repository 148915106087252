import React, { useState, useEffect } from "react";
import axios from "axios";
import "./NewExamCustum.css"; // Import your CSS file
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import baseUrl from "../../../config/config";
import { useSelector } from "react-redux";
const API_DEV_TASK = `${baseUrl.task}/tsv/apis/v1`;

// https://dev-task-services.onrender.com/tsv/apis/v1/getAllExam?userId=user123

const NewExamCustum = () => {
  const [exams, setExams] = useState([]);
  const navigate = useNavigate();
  const users = useSelector((state) => {
    return state.users;
  });
  const [subject, setSubject] = useState("");
  const [chapter, setChapter] = useState("");
  const [topic, setTopic] = useState("");
  const [targetExam, setTargetExam] = useState("");
  const [SubTopic, setSubTopic] = useState("");
  const [question, setQuestion] = useState({
    type: "multipleChoice", // Default type, replace it with your actual state
  });
  const notifySuccess = () => toast.success("Exam created successfully!");
  const notifyError = () =>
    toast.error("Error creating exam. Please try again.");
  const UserId = "user123";
  const [examData, setExamData] = useState({
    examName: "",
    numberOfQuestions: "",
    totalMarks: "",
    price: "",
    language: "",
    examDuration: "00:00",
  });

  useEffect(() => {
    fetchCustumExams();
  }, []);

  const fetchCustumExams = async () => {
    try {
      const response = await axios.get(
        `${API_DEV_TASK}/getAllExam?userId=${users.userId}`,
        {
          headers: {
            Authorization: `Bearer ${users.authToken}`,
            "Content-Type": "application/json",
            Accept: "*/*",
            app_name: "admin",
            app_version_code: "101",
          },
        }
      );
      setExams(response.data.data);
      console.log(response.data.data);
    } catch (error) {
      console.error("Error fetching exams:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setExamData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Validate that required fields are not empty
      if (
        !subject ||
        !chapter ||
        !examData.examName ||
        !examData.numberOfQuestions ||
        !examData.totalMarks ||
        !examData.examDuration ||
        !examData.price ||
        !examData.language
      ) {
        // If any required field is empty, show an error toast and return
        notifyError("Please fill in all required fields.");
        return;
      }

      // Create an object with all the data you want to send
      const postData = {
        userId: UserId,
        subject,
        chapter,
        topic,
        SubTopic,
        targetExam,
        examType: question.type,
        ...examData,
        examCreateFrom: "Custom",
      };

      // Make a POST request to your API
      const response = await axios.post(
        `${API_DEV_TASK}/create/exam`,
        postData,
        {
          headers: {
            Authorization: `Bearer ${users.authToken}`,
            "Content-Type": "application/json",
            Accept: "*/*",
            app_name: "admin",
            app_version_code: "101",
          },
        }
      );

      // Handle the response as needed
      console.log("API Response:", response.data);
      notifySuccess();

      // Clear the form after submitting
      setExamData({
        examName: "",
        numberOfQuestions: "",
        totalMarks: "",
        price: "",
        language: "",
        examDuration: "",
      });
      navigate(`/custom-exam/add-question`, {
        state: response.data.data,
      });
    } catch (error) {
      console.error("Error submitting data:", error);
      notifyError();
    }
  };
  const handleExamDetail = (data) => {
    navigate(`/custom-exam/add-question`, {
      state: data,
    });
  };

  const approveHandler = async (examId) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.patch(
        `${API_DEV_TASK}/exam/${examId}`, // Correct the path syntax by using `${examId}`
        {
          publishStatus: "REQUESTED", // Set the data in the request body
        },
        {
          headers: {
            Authorization: `Bearer ${users.authToken}`,
            "Content-Type": "application/json",
            Accept: "*/*",
            app_name: "admin",
            app_version_code: "101",
          },
        }
      );

      if (response.status === 200) {
        toast.success("Request Send successfully!");
        setExams((prev) => prev.filter((q) => q._id !== examId));
      }
    } catch (error) {
      console.error("Error in sending request question:", error);
      toast.error("Failed to send request. Please try again.");
    }
  };

  return (
    <div className="exam-panel">
      <div className="exam-cards-container-custom">
        {Array.isArray(exams) &&
          exams.map((exam) => (
            <div className="exam-card" key={exam.id}>
              <h2
                style={{
                  color: "#3498db",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                {exam.targetExam}{" "}
              </h2>
              <h3 style={{ color: "#3498db", fontSize: "18px" }}>
                {" "}
                {exam.examName}
              </h3>

              <p>
                No of Questions:{" "}
                <span style={{ color: "#3498db", fontSize: "16px" }}>
                  {" "}
                  {exam.numberOfQuestions}
                </span>
              </p>
              <p>
                Chapter:{" "}
                <span style={{ color: "#3498db", fontSize: "16px" }}>
                  {exam.chapter}
                </span>{" "}
              </p>
              {/* <p>Subtopic: <span style={{color:'#3498db', fontSize:'16px' }}> {exam.subTopic}</span> </p> */}
              <p>
                Language:{" "}
                <span style={{ color: "#3498db", fontSize: "16px" }}>
                  {" "}
                  {exam.language}
                </span>{" "}
              </p>
              <p>
                Type:{" "}
                <span style={{ color: "#3498db", fontSize: "16px" }}>
                  {" "}
                  {exam.examType}
                </span>{" "}
              </p>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                Price: ₹{exam.price}
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <button
                  className="newexambtn"
                  onClick={() => handleExamDetail(exam)}
                >
                  View
                </button>
                {exam.publishStatus === "draft" ? (
                  <button
                    className="newexambtn"
                    onClick={() => approveHandler(exam?._id)}
                  >
                    send for Approval
                  </button>
                ) : (
                  <span style={{ color: "#3498db", fontSize: "16px" }}>
                    {exam.publishStatus}
                  </span>
                )}
              </div>
            </div>
          ))}
      </div>

      <div className="new-exam-panel">
        <h2
          style={{
            color: "#3498db",
            fontSize: "18px",
            fontWeight: "bold",
            marginBottom: "10px",
          }}
        >
          Create Your New Custom Exam Set
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="sub-navbar">
            <label>
              Subject:
              <input
                type="text"
                name="subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                placeholder="Set a Subject"
                required
              />
            </label>

            <label>
              Chapter:
              <input
                type="text"
                name="chapter"
                value={chapter}
                onChange={(e) => setChapter(e.target.value)}
                placeholder="Set a Chapter"
                required
              />
            </label>

            <label>
              Topic(optional):
              <input
                type="text"
                name="topic"
                value={topic}
                onChange={(e) => setTopic(e.target.value)}
                placeholder="Set a Topic"
              />
            </label>

            <label>
              Sub Topic(optional):
              <input
                type="text"
                name="SubTopic"
                value={SubTopic}
                onChange={(e) => setSubTopic(e.target.value)}
                placeholder="Set Sub Topic"
              />
            </label>

            <label>
              Your Target Exam(optional):
              <input
                type="text"
                name="targetExam"
                value={targetExam}
                onChange={(e) => setTargetExam(e.target.value)}
                placeholder="Set an Exam"
              />
            </label>
          </div>

          <label>Exam Type</label>

          <div className="toggle-container">
            <button
              className={`toggle-btn ${
                question.type === "multipleChoice" ? "active" : ""
              }`}
              onClick={() =>
                setQuestion((prev) => ({ ...prev, type: "multipleChoice" }))
              }
            >
              Objective
            </button>
            <button
              className={`toggle-btn ${
                question.type === "subjective" ? "active" : ""
              }`}
              onClick={() =>
                setQuestion((prev) => ({ ...prev, type: "subjective" }))
              }
            >
              Subjective
            </button>
          </div>
          <br />
          <label>Exam Name:</label>

          <input
            type="text"
            name="examName"
            placeholder="Name of this Exam"
            value={examData.examName}
            onChange={handleChange}
            required
          />
          <br />
          <label>Number of Questions:</label>

          <input
            type="number"
            name="numberOfQuestions"
            placeholder="Number of questions will take"
            value={examData.numberOfQuestions}
            onChange={handleChange}
            required
          />
          <br />

          <label>Total Marks:</label>

          <input
            type="number"
            name="totalMarks"
            placeholder="Total exam Marks"
            value={examData.totalMarks}
            onChange={handleChange}
            required
          />
          <br />

          <label>Exam Duration(In hr)</label>

          <input
            type="time"
            name="examDuration"
            placeholder="Exam Duration Time"
            value={examData.examDuration}
            onChange={handleChange}
            required
          />
          <br />

          <label>Price:</label>

          <input
            type="number"
            name="price"
            placeholder="Exam Price(including all taxes + 10% platform charge)"
            value={examData.price}
            onChange={handleChange}
            required
          />
          <br />

          <label>Language:</label>

          <input
            type="text"
            name="language"
            placeholder="Exam Language"
            value={examData.language}
            onChange={handleChange}
            required
          />
          <br />

          <button className="dropbtn" type="submit">
            Create Exam
          </button>
        </form>
      </div>
    </div>
  );
};

export default NewExamCustum;
