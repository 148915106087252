import React, { useState, useEffect } from 'react';
import './Curriculum.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import baseUrl from "../../../../config/config";

function Curriculum() {
  const [examName, setExamName] = useState('');
  const [examDescription, setExamDescription] = useState('');
  const [thumbnail, setThumbnail] = useState(null);
  const [exams, setExams] = useState([]);
  const [selectedExam, setSelectedExam] = useState(null);
  const [showExamList, setShowExamList] = useState(false);
  const [imageUrl, setImageUrl] = useState()
  // const baseUrl.Url /v1= "http://localhost:4001/v1";
  // const baseUrl.Url= 'https://content-panel-backend-urll.onrender.com/v1'

  // const cld = new Cloudinary({ cloud: { cloudName: 'dfzd4xbsk' } });

  useEffect(() => {
    fetchExams();
  }, []);

  const handleExamNameChange = (e) => {
    setExamName(e.target.value);
  };

  const handleExamDescriptionChange = (e) => {
    setExamDescription(e.target.value);
  };

  const handleThumbnailChange = (e) => {
    const file = e.target.files[0];
    console.log(file);
    setThumbnail(file);
    const data = new FormData();
    data.append("file", thumbnail)
    data.append("upload_preset", "dagada")
    data.append("cloud_name", 'dfzd4xbsk')

    fetch("https://api.cloudinary.com//v1_1/dfzd4xbsk/image/upload", {
      method: 'POST',
      body: data
    }).then((res) => res.json())
      .then((data) => {
        console.log(data)
        setImageUrl(data.url);
      }).catch((err) => {
        console.log(err);
        toast.error(err);
      })
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!examName) {
        toast.error('Please Enter Exam Name');
        return;
      }

      if (!imageUrl) {
        const userConfirmed = window.confirm(
          'Thumbnail image URL is empty. Do you want to proceed without a thumbnail?'
        );

        if (!userConfirmed) {
          return;
        }
      }


      // const imageUrl = cld.image(thumbnail).toURL();
      const token = localStorage.getItem('token');

      const response = await axios.post(
        `${baseUrl.Url}/v1/exam/createExam`,
        {
          examName,
          examDescription,
          thumbnail: imageUrl,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status === 201) {
        toast.success('Exam created successfully!');
        setExamName('');
        setExamDescription('');
        setThumbnail(null);
        setImageUrl(null);
        clearFileInput(); // Clear the file input field
        fetchExams();
      }


    } catch (error) {
      // console.error('Error creating exam:', error);
      toast.error(error.response.data.error);
    }
  };
  const clearFileInput = () => {
    const fileInput = document.getElementById('thumbnail');
    if (fileInput) {
      fileInput.value = ''; // Clear the value of the file input
    }
  };


  const fetchExams = async () => {
    try {
      const response = await axios.get(`${baseUrl.Url}/v1/exam/getExams`);
      setExams(response.data.data);
    } catch (error) {
      console.error('Error fetching exams:', error);
    }
  };

  const handleEditExam = (exam) => {
    setSelectedExam(exam);
    setExamName(exam.examName);
    setExamDescription(exam.examDescription);
  };

  const handleUpdateExam = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.put(
        `${baseUrl.Url}/v1/exam/updateExam/${selectedExam._id}`,
        {
          examName,
          examDescription,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        toast.success('Exam updated successfully!');
        setSelectedExam(null);
        setExams([]);
        fetchExams();
      }
    } catch (error) {
      console.error('Error updating exam:', error);
      toast.error('An error occurred while updating the exam.');
    }
  };

  return (
    <div className="App">
    <form className="form" onSubmit={handleSubmit}>
      <label htmlFor="examName">Exam Name:</label>
      <input
        type="text"
        id="examName"
        value={examName}
        onChange={handleExamNameChange}
      />

      <label htmlFor="examDescription">Exam Description:</label>
      <textarea
        id="examDescription"
        value={examDescription}
        onChange={handleExamDescriptionChange}
      />

      <label htmlFor="thumbnail">Upload Thumbnail:</label>
      <input
        type="file"
        id="thumbnail"
        accept="image/*"
        onChange={handleThumbnailChange}
      />

      <button className='button-exam' type="submit">Create Exam</button>
    </form>

    <button className='get_exam' onClick={() => setShowExamList(!showExamList)}>
      {showExamList ? 'Hide Exam Data' : 'Get Exam Data'}
    </button>

    {showExamList && (
      <div className="exam-list">
        <h2 className="section-title">Exam List</h2>
        <ul>
          {exams.map((exam) => (
            <li className="exam-item" key={exam._id}>
              <span>{exam.examName}</span>
              <button className="edit-button" onClick={() => handleEditExam(exam)}>Edit</button>
            </li>
          ))}
        </ul>
      </div>
    )}

    {selectedExam && (
      <div className="edit-form">
        <h2 className="section-title">Edit Exam</h2>
        <label htmlFor="editExamName">Exam Name:</label>
        <input
          type="text"
          id="editExamName"
          value={examName}
          onChange={handleExamNameChange}
          className="input-field"
        />

        <label htmlFor="editExamDescription">Exam Description:</label>
        <textarea
          id="editExamDescription"
          value={examDescription}
          onChange={handleExamDescriptionChange}
          className="textarea-field"
        />

        <button className="update-button" onClick={handleUpdateExam}>Update</button>
        <button className="cancel-button" onClick={() => setSelectedExam(null)}>Cancel</button>
      </div> 
    )}
  </div>
  );
}

export default Curriculum;
