import React, { useEffect, useState } from "react";
import logo from "../../../assets/logo2.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import baseUrl from "../../../config/config";

function SwitchStudentRequests() {
  const navigate = useNavigate();
  const users = useSelector((state) => {
    return state.users;
  });
  const [viewStudentRequest, setViewStudentRequest] = useState([]);

  const switchRequest = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl.ins}/institute/apis/v1/inst/switch/req?status=pending`,
      headers: {
        Authorization: `Bearer ${users.authToken}`,
        "Content-Type": "application/json",
          Accept: "*/*",
          app_name: "admin",
          app_version_code: "101",
      },
    };

    axios
      .request(config)
      .then((res) => {
        console.log('res',res.data.data);
        setViewStudentRequest(res.data.data)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    switchRequest();
  },[])

  return (
    <div 
    // className="admin_panel_resource_container"
    >
      <div className="admin_panel_resource_logo_img_container">
        <img
          onClick={() => {
            navigate("/");
          }}
          className="admin_panel_resource_logo_img"
          src={logo}
          alt="logo"
        />
      </div>

      <div className="m-5 mt-4">
        <div className="admin_panel_resource_container_cont">
          <h1 className="text-2xl ">Switch Institute Student Requests</h1>
        </div>

        {viewStudentRequest ? (
          <>
            <div className="flex mt-4 justify-between w-[73vw]">
              <table className="w-full bg-[#9bd4e3] rounded-lg overflow-hidden">
                <thead>
                  <tr>
                    <th className="px-4 py-3">Student ID</th>
                    {/* <th className="px-4 py-3">Class</th> */}
                    <th className="px-4 py-3">Name</th>
                    <th className="px-4 py-3">Current Institute</th>
                    <th className="px-4 py-3">Switch to</th>
                    <th className="px-4 py-3">Action</th>
                  </tr>
                </thead>
                <tbody>
                    <tr className="hover:bg-blue-300 max-w-92">
                      <td className="px-4 py-3">{viewStudentRequest?.student_id}</td>
                      {/* <td className="px-4 py-3">{}</td> */}
                      <td className="px-4 py-3">{viewStudentRequest?.student_name}</td>
                      <td className="px-4 py-3">{viewStudentRequest?.enroll?.institute_name}</td>
                      <td className="px-4 py-3">{viewStudentRequest?.explore?.institute_name}</td>
                      <td className="px-2 py-3">
                        <div
                          onClick={() => navigate("/switchInstitute", { state: viewStudentRequest })}
                          className="bg-[#FA991C] hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded cursor-pointer flex items-center justify-center"
                          style={{ maxWidth: "fit-content" }}
                        >
                          <div className="text-center">View Details</div>
                        </div>
                      </td>
                    </tr>
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <div className="text-3xl text-[#130F40] text-left p-5">
            No Students found....
          </div>
        )}
      </div>
    </div>
  );
}

export default SwitchStudentRequests;
