import React, { useEffect, useState } from "react";
import LeftImg from "../../assets/Left-Img.png";
import LoginImg from "../../assets/Login-Img.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setBatches,
  setExpiryDate,
  setUserType,
  setauthToken,
  setdata,
  setisLoggedin,
  setuserId,
} from "../../redux/reducers/userSlice";
import { jwtDecode } from "jwt-decode";
import baseUrl from "../../config/config";
function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const users = useSelector((state) => {
    return state.users;
  });
  const handleDataSubmit = (e) => {
    e.preventDefault();
    if (email === "" || password === "") {
      toast.error("Enter a valid credentials!", {
        position: "top-right",
      });
      return;
    }
    setLoading(true);
    axios
      .get(
        `${baseUrl.auth}/ums/apis/v1/admin/get?email=${email}&password=${password}`,
        {
          headers: {
            Authorization: `Bearer ${users.authToken != null ? users.authToken : users.unauthToken
              }`,
            "Content-Type": "application/json",
            Accept: "*/*",
            app_name: "admin",
            app_version_code: "101",
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          console.log("response",res.data)
          dispatch(setauthToken(res.data.token.accessToken));
          dispatch(setuserId(res.data._id));
          const decoded = jwtDecode(res.data.token.accessToken);
          console.log("decoded",decoded)
          let usertype = decoded.userType;
          let expirationDate = new Date();
          expirationDate.setHours(expirationDate.getHours() + 10);
          dispatch(setExpiryDate(`${expirationDate}`));
          dispatch(setUserType(usertype))
          dispatch(setisLoggedin(true));
          navigate("/");
          setLoading(false); 
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error("something went wrong !", {
          position: "top-right",
        });
        console.log(err);
      });
  };
  return (
    <div className="flex bg-slate-200">
      <div className="w-1/2">
        <img
          src={LeftImg}
          alt="left-img"
          className="w-[50vw] h-[100vh] object-cover"
        />
      </div>
      <div className="relative w-1/2 p-10 mr-10 mx-auto my-auto bg-white">
        <div>
          <img
            src={LoginImg}
            alt="login-up-img"
            className="absolute top-[-174px] right-0"
          />
        </div>
        <div className="font-semibold text-2xl text-black">
          Log<span className="text-[#6B4EFF]">In</span>
        </div>
        <form
          onSubmit={handleDataSubmit}
          className="mx-auto mb-0 mt-8  space-y-4"
        >
          <div>
            <div className="relative">
              <input
                type="email"
                className={`w-full rounded-lg p-4 ${email ? "border-black" : "border-red-500"} text-sm shadow-sm outline-none`}
                placeholder="company@email.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="relative">
              <input
                type="password"
                className={`w-full rounded-lg p-4 ${password ? "border-black" : "border-red-500"} text-sm shadow-sm outline-none`}
                placeholder="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>
          <div className="flex p-10  items-center justify-between">
            {!loading ? (
              email&&password ?
                <button
                  type="submit"
                  className="inline-block mt-24 w-full rounded-lg bg-[#6B4EFF] px-5 py-3 text-sm font-medium text-white"
                >
                  Sign in
                </button>
                : <button
                  type="submit"
                  disabled
                  className="disabled:bg-slate-500 inline-block mt-24 w-full rounded-lg bg-[#6B4EFF] px-5 py-3 text-sm font-medium text-white"
                >
                  Sign in
                </button>
            ) : (
              "Loading..."
            )}
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Login;
