import React, { useState } from "react";
import LeftImg from "../../assets/Left-Img.png";
import LoginImg from "../../assets/Login-Img.png";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  setBatches,
  setExpiryDate,
  setUserType,
  setauthToken,
  setdata,
  setisLoggedin,
  setuserId,
} from "../../redux/reducers/userSlice";
import { jwtDecode } from "jwt-decode";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import baseUrl from "../../config/config";

function OTPScreen() {
  const [otp, setotp] = useState("");
  let [searchParmas, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);

  const users = useSelector((state) => {
    return state.users;
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let mobileN = searchParmas.get("mobileNumber");

  const handleDataSubmit = async (e) => {
    e.preventDefault();
    if (otp == "" || otp.length < 4 || otp.length >4) {
      toast.error("Enter a valid otp!", {
        position: "top-right",
      });
      return;
    }
    setLoading(true);
    try {
      const res = await axios.post(
        `${baseUrl.auth}/ums/apis/v1/users/verify_otp`,
        {
          phone: decodeURIComponent(mobileN),
          otp: otp,
        },
        {
          headers: {
            Authorization: `Bearer ${
              users.authToken != null ? users.authToken : users.unauthToken
            }`,
            "Content-Type": "application/json",
            Accept: "*/*",
            app_name: "admin",
            app_version_code: "101",
          },
        }
      );
      dispatch(setauthToken(res.data.data.accessToken));
      dispatch(setuserId(res.data.data._id));
      const decoded = jwtDecode(res.data.data.accessToken);
      console.log(decoded)
      let usertype=decoded.userType;
      let expirationDate = new Date();
      expirationDate.setHours(expirationDate.getHours() + 10);

      dispatch(setExpiryDate(`${expirationDate}`));
      dispatch(setUserType(usertype))
      dispatch(setisLoggedin(true));
      setLoading(false); // Set loading to false when the API responds

      // if (res?.data?.data?.isNewUser) {
      //   navigate("/");
      // } else {
      navigate("/");
      // }
    } catch (err) {
      // Handle error
      setLoading(false); // Set loading to false when the API responds with an error
      console.log(err);
      // Display an error toast using react-native-toast-message
      toast.error("Something went wrong !", {
        position: "top-right",
      });
    }
  };

  return (
    <div className="flex bg-slate-200">
      <div className="w-1/2">
        <img
          src={LeftImg}
          alt="left-img"
          className="w-[50vw] h-[100vh] object-cover"
        />
      </div>
      <div className="relative w-1/2 p-10 mr-10 mx-auto my-auto bg-white">
        <div>
          <img
            src={LoginImg}
            alt="login-up-img"
            className="absolute top-[-174px] right-0"
          />
        </div>
        <div className="font-semibold text-2xl text-black">
          Verify<span className="text-[#6B4EFF]">OTP</span>
        </div>
        <form
          onSubmit={handleDataSubmit}
          className="mx-auto mb-0 mt-8  space-y-4"
        >
          <div>
            <label htmlFor="email" className="sr-only">
              Mobile Number
            </label>

            <div className="relative">
              <input
                type="number"
                className={`w-full rounded-lg ${otp.length!==4?"border-red-500":"border-gray-200"} p-4 pe-12 text-sm shadow-sm outline-none`}
                placeholder="Enter OTP here"
                value={otp}
                onChange={(e) => setotp(e.target.value)}
              />
            </div>
          </div>

          <div className="flex p-10  items-center justify-between">
            {!loading ? (
               otp.length===4 ?
               <button
                 type="submit"
                 className="inline-block mt-24 w-full rounded-lg bg-[#6B4EFF] px-5 py-3 text-sm font-medium text-white"
               >
                 Verify
               </button>
               :<button
               type="submit"
               disabled
               className="disabled:bg-slate-500 inline-block mt-24 w-full rounded-lg bg-[#6B4EFF] px-5 py-3 text-sm font-medium text-white"
             >
               Verify
             </button>
            ) : (
              "Loading..."
            )}
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}

export default OTPScreen;
