import React, { useState } from "react";
import logo from "../../../assets/logo2.png";
import { useLocation, useNavigate } from "react-router-dom";

const batchData = [
    {
        id:1,
        batchName:"Batch 1",
        class:"Class XI",
        curriculam:"JEE",
        subject:"Chem,Maths",
        batchStartTime:"4:30 p.M.",
        batchEndTime:"9:30 p.M.",
        timeDetail:"",
        weekdaysSchedule:"tuesday",
        weekDay:"",
        count:"",
        startDate:"10th Jan 24",
        batchSize:"20",
    }
]

function SwitchInstitute() {
  const navigate = useNavigate();
  const params = useLocation();
  console.log('params', params.state);
  const item = params.state;
  const [selectedButton, setSelectedButton] = useState("subjective");

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
  };

  return (
    <div 
    // className="admin_panel_resource_container"
    >
      <div className="admin_panel_resource_logo_img_container">
        <img
          onClick={() => {
            navigate("/");
          }}
          className="admin_panel_resource_logo_img"
          src={logo}
          alt="logo"
        />
      </div>

      <div className="m-5 mt-4">
        <div className="admin_panel_resource_container_cont">
          <h1 className="text-2xl ">Switch Institute Student Requests</h1>
        </div>

        {item ? (
          <>
            <div className="flex mt-4 justify-between w-[73vw]">
              <table className="w-full bg-[#9bd4e3] rounded-lg overflow-hidden">
                <thead>
                  <tr>
                    <th className="px-4 py-3">Student ID</th>
                    {/* <th className="px-4 py-3">Class</th> */}
                    <th className="px-4 py-3">Name</th>
                    <th className="px-4 py-3">Current Institute</th>
                    <th className="px-4 py-3">Switch to</th>
                    <th className="px-4 py-3">Action</th>
                  </tr>
                </thead>
                <tbody>
                    <tr className="hover:bg-blue-300 max-w-92">
                      <td className="px-4 py-3">{item?.student_id}</td>
                      {/* <td className="px-4 py-3">{item.standard}</td> */}
                      <td className="px-4 py-3">{item?.student_name}</td>
                      <td className="px-4 py-3">{item?.enroll?.institute_name}</td>
                      <td className="px-4 py-3">{item?.explore?.institute_name}</td>
                      <td className="px-2 py-3">
                        <div
                          className="bg-[#FA991C] hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded cursor-pointer flex items-center justify-center"
                          style={{ maxWidth: "fit-content" }}
                        >
                          <div className="text-center">Approve</div>
                        </div>
                      </td>
                    </tr>
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <div className="text-3xl text-[#130F40] text-left p-5">
            No Students found....
          </div>
        )}

        {/* <div className="font-light">2/2 switches remaining for this student</div> */}


        {/* {batchData.length > 0 ? (
          batchData.map((item, key) => (
            <div
              className="border border-[#1C768F] rounded-t-lg my-3 overflow-hidden"
              key={key}
            >
              <div
                onClick={() => {
                
                }}
                className="flex flex-wrap justify-between border-b-2 cursor-pointer border-[#1C768F] px-6 py-4 bg-[#D9D9D9]"
              >
                <div className="flex h-auto">
                  <h5 className="text-[#1C768F] font-bold text-3xl mr-5">
                    {item.batchName}
                  </h5>
                  <h5 className="text-[#000000B2] text-3xl">{item.class}</h5>
                </div>
                <div className="flex">
                  <h5 className="text-[#1C768F] font-bold text-3xl mr-5">
                    {item.curriculam}
                  </h5>
                  <h5 className="text-[#000000B2] font-bold text-2xl">
                    {item.subject}
                  </h5>
                </div>
              </div>
              <div className="flex my-2 mx-6">
                <div className="w-92 flex flex-col items-center">
                  
                  <div className="flex-row flex">
                  <h5 className="text-[#1C768F] font-bold text-lg ml-6">
                    Timings
                  </h5>
                    <h5 className="text-[#000000] text-lg ml-2">
                      {item.batchStartTime}
                    </h5>
                 
                    <h5 className="text-[#1C768F] text-base mt-1 mx-1">to</h5>
                    <div>
                      <h5 className="text-[#000000] text-lg ml-1">
                        {item.batchEndTime}
                      </h5>
                      
                    </div>
                  </div>
                </div>
                <div>
                  <h5 className="text-[red] text-xs mt-2 ml-1">
                    {item.timeDetail}
                  </h5>
                </div>
                <div>
                  <h5 className="text-[#1C768F] text-lg mr-6 ml-5">
                    Weekly Schedule
                  </h5>
                  <h5 className="text-[#000000] text-lg mr-6 ml-2">
                    {item.weekdaysSchedule}
                  </h5>
                  <h5 className="text-[#00000099] text-lg mr-6 ml-2">
                    {item.weekDay}
                  </h5>
                  <h5 className="text-[#00000099] text-lg mr-6 ml-2">
                    {item.count}
                  </h5>
                </div>
                <div>
                  <h5 className="text-[#1C768F] mr-8">Batch Commencing From</h5>
                  <h5 className="text-[#032539] mr-8">
                    
                    {item.startDate}
                  </h5>
                </div>
                <div>
                  <h5 className="text-[#1C768F] mr-20">Batch Size</h5>
                  <div className="mr-20 text-[#FF543E]">
                    {item.batchSize} seats left!
                  </div>
                </div>
               
              </div>
            
            </div>
          ))
        ) : (
          <div className="text-center mb-20">No data found</div>
        )} */}



<div className="ml-6 mt-3 flex justify-center p-8">
                <div>
                  <div className="text-[#032539]">Description</div>
                    <div className="flex flex-row">
                  <div
                    className={`${
                      selectedButton === "subjective"
                        ? "bg-[#1C768F] text-white"
                        : "bg-white text-[#1C768F]"
                    }
                          w-96 h-10 border border-1 border-[#1C768F] px-4 py-2 flex flex-row justify-between`}
                    onClick={() => handleButtonClick("subjective")}
                  >
                    <div>
                    Institute Fees
                    </div>
                    <div>
                    25,000
                    </div>
                  </div>
                  <div
                    className={`${
                      selectedButton === "objective"
                        ? "bg-[#1C768F] text-white"
                        : "bg-white text-[#1C768F]"
                    }
                          w-96 h-10 border border-1 border-[#1C768F] px-4 py-2 flex flex-row justify-between`}
                    onClick={() => handleButtonClick("objective")}
                  >
                     <div>
                     Enter Your Fees
                    </div>
                    <div>
                    35,000
                    </div>
                  </div>
                    </div>
                </div>
              </div>


              <div
              onClick={() => navigate("/verifyStudentrequests")}
              className="bg-[#1C768F] py-2 mb-5 mt-2 flex items-center justify-center self-center mx-80">
                <div className="text-white text-center">Accept Request</div>
              </div>

      </div>
    </div>
  );
}

export default SwitchInstitute;
