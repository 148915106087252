import React, { useEffect, useState } from "react";
import logo from "../../assets/logo2.png";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import loader from "../../assets/icons/loader.gif"
import baseUrl from "../../config/config"
import { useSelector } from "react-redux";
function Assigment() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const users = useSelector((state) => { return state.users });
  useEffect(() => {
    setLoading(true);
    if (users) {
      axios.get(`${baseUrl.task}/tsv/apis/v1/batch/${id}`, {
        headers: {
          Authorization: `Bearer ${users.authToken}`,
          'Content-Type': 'application/json',
          Accept: '*/*',
          app_name: 'admin',
          app_version_code: '101',
        }
      }).then((res) => {
        setData(res.data.data);
        console.log(res.data.data);
        setLoading(false);
      }).catch((err) => {
        setLoading(false);
        console.log("unable to get assigment details", err);
      })
    }
  }, [])
  if (loading || data == null) {
    return (
      <div className='admin_panel_resource_container_loading'><img src={loader} className='admin_panel_resource_container_loading_img' /></div>
    )
  }
  else {
    return (
      <div className="admin_panel_resource_container">
        <div className="admin_panel_resource_logo_img_container">
          <img
            onClick={() => {
              navigate("/");
            }}
            className="admin_panel_resource_logo_img"
            src={logo}
            alt="logo"
          />
        </div>
        <div className="admin_panel_resource_container_cont">
          <h1 className="admin_panel_resource_hed">Assigment</h1>
        </div>
        <div className="space-y-5">
          {
            data.map((item, key) => (
              <div key={key} className="flex justify-between border items-center rounded-md border-blue-500 p-6">
                <div className="p-2">
                  <div className="text-3xl text-gray-500">Assigment Name- {key + 1}</div>
                  {/* <div className="text-2xl text-blue-500">Mr.Kapoor</div>
              <div className="text-sm text-gray-500">Mathematics</div>
              <div className="text-xl text-black">Due Date- 08.08.22</div>
              <div className="text-blue text-xl">Publish Date- 08.08.23</div> */}
                </div>
                {/* <div className="p-2">
              <div className="text-xs text-gray-500">Subject- Maths</div>
              <div className="text-xl text-gray-500">Topic- Trigonometry</div>
              <div className="text-2xl text-blue-500">SubTopic- Equation</div>
            </div> */}
                <div className="p-2">
                  {
                    (item?.publishStatus === "draft") ? (
                      <div onClick={() => { navigate(`/assigment/${item?._id}`) }} className="text-xl text-white cursor-pointer bg-blue-500 text-center mt-2 rounded-xl p-2">
                        Review Assigment
                      </div>
                    ) : (
                      <div className="text-xl text-white cursor-pointer bg-red-500 text-center mt-2 rounded-xl p-2">
                        Published
                      </div>
                    )
                  }
                </div>
              </div>
            ))
          }
        </div>
      </div>
    );
  }
}

export default Assigment;
