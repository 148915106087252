import { createSlice } from "@reduxjs/toolkit";
const UserSlice = createSlice({
  name: 'users',
  initialState: {
    isloggedin: false,
    userId:null,
    ExpiryDate: null,
    unauthToken:null,
    authToken:null,
    usertype:null
  },
  reducers: {
    setdata(state, action) {
      state.userdata = action.payload
    },
    setuserId(state, action) {
      state.userId = action.payload
    },
    setExpiryDate(state, action) {
      state.ExpiryDate = action.payload
    },
    setisLoggedin(state, action) {
      state.isloggedin = action.payload
    },
    setunauthToken(state, action) {
      state.unauthToken = action.payload
    },
    setauthToken(state, action) {
      state.authToken = action.payload
    },
    setUserType(state,action){
      state.usertype=action.payload
    }
  },
})


export default UserSlice.reducer
export const { setdata, setuserId,setExpiryDate,setunauthToken, setauthToken,setisLoggedin,setUserType } = UserSlice.actions