import React, { useEffect, useState } from "react";
import logo from "../../../assets/logo2.png";
import loader from "../../../assets/icons/loader.gif";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import baseUrl from "../../../config/config";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-calendar/dist/Calendar.css";
function MarkCopyCheckInstitute() {
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log("state", state);
  const [teacherList, setTeacherList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [teacher, setTeacher] = useState();
  const [dueDate, setDueDate] = useState();
  const [pricePerCopy, setPricePerCopy] = useState(0);

  const users = useSelector((state) => {
    return state.users;
  });
  console.log("users", users);
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${baseUrl.tms}/tms/apis/v1/search/teacher`, {
        headers: {
          Authorization: `Bearer ${
            users.authToken != null ? users.authToken : users.unauthToken
          }`,
          "Content-Type": "application/json",
          Accept: "*/*",
          app_name: "admin",
          app_version_code: "101",
        },
      })
      .then((res) => {
        let arr = [];
        res.data.data.forEach((element) => {
          arr.push({
            label: element.firstName,
            value: { userId: element.userId, firstName: element.firstName },
          });
        });
        setTeacherList(arr);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error finding the teacher", err);
        setLoading(false);
      });
  }, []);

  console.log("token", users.authToken);

  const assignCopyToTeacher = () => {
    console.log("teacher", teacher?.value?.userId, state?.examSubmitIds);
    if (
      state.examSubmitIds?.length == 0 ||
      !teacher?.value?.userId ||
      !dueDate ||
      !users.authToken ||
      !state.examId
    ) {
      toast.error("Provide all fields !");
      return;
    }

    let data = JSON.stringify({
      admin: [
        {
          teacher_id: teacher.value.userId,
          Count_copies: state.examSubmitIds?.length,
          Percopyprice: +pricePerCopy,
          Total_amount: 1 * state.examSubmitIds?.length,
          due_date: dueDate,
          status_copy_check: "pending",
          exam_submit_id: state.examSubmitIds,
        },
      ],
    });

    console.log("dddddd", JSON.parse(data), state.examId);
    axios
      .put(
        `${baseUrl.ins}/institute/apis/v1/institute/exam/check/admin/toteacher/${state.examId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${users.authToken}`,
            "Content-Type": "application/json",
            Accept: "*/*",
            app_name: "admin",
            app_version_code: "101",
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          console.log("ressss", res);
          navigate("/copy-check-institute");
        }
      })
      .catch((err) => {
        console.log("er", err);
      });
  };

  if (loading) {
    return (
      <div className="admin_panel_resource_container_loading">
        <img
          src={loader}
          className="admin_panel_resource_container_loading_img"
        />
      </div>
    );
  } else {
    return (
      <div className="admin_panel_resource_container">
        <div className="admin_panel_resource_logo_img_container">
          <img
            onClick={() => {
              navigate("/");
            }}
            className="admin_panel_resource_logo_img"
            src={logo}
            alt="logo"
          />
        </div>

        <div className="m-2 mt-4">
          <div className="admin_panel_resource_container_cont">
            <h1 className="text-2xl">Copy Checking</h1>
          </div>

          <div className="admin_panel_resource_container_cont my-5">
            <h1 className="text-2xl">
              Selected {state.examSubmitIds?.length}{" "}
              {state.examSubmitIds?.length > 1 ? "Copies" : "Copy"}
            </h1>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 gap-x-10 mx-10">
          <div className="flex flex-col">
            <label className="text-left font-bold mb-1 text-[#1C768F]">
              Select Subject
            </label>
            <input
              type="text"
              placeholder="Maths-3"
              className="w-92 mr-2 border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              defaultValue={state.subject}
            />
          </div>
          <div className={"w-[30vw]"}>
            <div className="text-[#1C768F]">Select Subject Teacher</div>
            <Select
              options={teacherList}
              className={`rounded-md w-full ${
                !teacher && "border-red-500 border-[2px]"
              }`}
              onChange={(selectedOptions) => {
                setTeacher(selectedOptions);
              }}
              value={teacherList.userId}
            />
          </div>

          <div className="flex flex-col">
            <label className="text-left font-bold mb-1 text-[#1C768F]">
              Checking Deadline
            </label>
            <div className="relative">
              <input
                value={dueDate}
                onChange={(e) => {
                  setDueDate(e.target.value);
                }}
                placeholder="Name"
                type="date"
                id="institute_name"
                name="institute_name"
              />
              <span class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <svg
                  class="h-6 w-6 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  ></path>
                </svg>
              </span>
            </div>
          </div>

          <div className="flex flex-col">
            <label className="text-left text-[#1C768F] mb-1">Topic</label>
            <input
              type="text"
              placeholder="Topic Name"
              className="w-92 mr-2 border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            defaultValue={state.topic}
            />
          </div>

          <div className="flex flex-col">
            <label className="text-left font-bold mb-1 text-[#1C768F]">
              Exam Name
            </label>
            <input
              type="text"
              placeholder="English"
              className="w-92 mr-2 border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              defaultValue={state.examName}
            />
          </div>

          <div className="flex flex-col">
            <label className="text-left text-[#1C768F] mb-1">Exam Date</label>
            <input
              type="text"
              placeholder="10"
              className="w-92 mr-2 border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              defaultValue={new Date(state.examDate).toLocaleDateString()}
            />
          </div>

          <div className="flex flex-col">
            <label className="text-left text-[#1C768F] mb-1">
              Price Per Copy
            </label>
            <input
              type="number"
              placeholder=""
              className="w-92 mr-2 border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              value={pricePerCopy}
              onChange={(e) => {
                setPricePerCopy(e.target.value);
              }}
            />
          </div>
        </div>

        <button
          className="bg-[#FA991C] text-white font-bold py-4 w-44 items-center justify-center self-center rounded-lg mt-10 mb-2"
          onClick={(e) => {
            e.preventDefault();
            assignCopyToTeacher();
          }}
        >
          Assign to Teacher
        </button>

        <div className="flex justify-evenly"> 
          <div className="w-60">
            <div className="text-center mt-10">
              Total Price for {state.examSubmitIds?.length} Copy
            </div>

            <button className="bg-[#1C768F] w-60 items-center self-center mt-3 mb-10">
              <div>{pricePerCopy * state.examSubmitIds?.length} rupees</div>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default MarkCopyCheckInstitute;
