// MeetingPage.js

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import JitsiIntegration from './JitsiIntegration';
import '../../Styles/MeetingPage.css'; // Import the CSS file
import { useSelector } from "react-redux";

const MeetingPage = () => {
  const { roomName } = useParams();

  useEffect(() => {
    // You can add additional logic here if needed
    console.log(`Joining meeting with roomName: ${roomName}`);
  }, [roomName]);

  const users = useSelector((state) => {
    return state.users;
  });

  const username = users?.userdata?.personalDetails?.first_name;
  const userid = users?.userId;

  return (
    <div className="meeting-container">
      <h1>  <strong>Intelli Class</strong> </h1>
      <JitsiIntegration roomName={roomName} username={username} userid={userid} />
    </div>
  );
};

export default MeetingPage;
