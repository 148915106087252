import React, { useEffect, useState } from 'react'
import logo from "../../assets/logo2.png";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import axios from 'axios';
import loader from "../../assets/icons/loader.gif"
import { toast } from 'react-toastify';
import baseUrl from "../../config/config"
import { useSelector } from 'react-redux';
function AddStudent() {
  const navigate = useNavigate();
  const [student, setStudent] = useState([]);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const users = useSelector((state) => { return state.users });


  const addStudentToBatchHandler = async (item) => {
    setLoading(true);
    try {
      const res = await axios.put(`${baseUrl.tms}/tms/apis/v1/student/add/classId`,
        {
          "studentId": item.userId,
          "classId": id
        },
        {
          headers: {
            Authorization: `Bearer ${users.authToken}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
            app_name: 'admin',
            app_version_code: '101',
          }
        })
      setLoading(false);
      if (res.data.data.status === 400) {
        if (res.data.data.message === "STUDENT_ALREADY_ADDED") {
          toast.error('Student Already In Batch.')
        }
      }
      console.log(res.data.data)
    } catch (err) {
      console.log("Error fetching the students", err);
      setLoading(false);
    }
  }
  useEffect(() => {
    setLoading(true);
    axios.get(`${baseUrl.sts}/sts/apis/v1/students`, {
      headers: {
        Authorization: `Bearer ${users.authToken}`,
        'Content-Type': 'application/json',
        Accept: '*/*',
        app_name: 'admin',
        app_version_code: '101',
      }
    }).then((res) => {
      const filteredData = res.data.data.filter(item => item.isadmin === false);
      setStudent(filteredData);
      setLoading(false);
      console.log(filteredData)
    }).catch((err) => {
      console.log("Error fetching the students", err);
      setLoading(false);
    })
  }, [])
  const [searchTerm, setSearchTerm] = useState('');
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredStudents = student.filter((item) => {
    const fullName = `${item.personalDetails.first_name}`;
    return fullName.toLowerCase().includes(searchTerm.toLowerCase());
  });
  if (loading || student == null) {
    return (
      <div className='admin_panel_resource_container_loading'><img src={loader} className='admin_panel_resource_container_loading_img' /></div>
    )
  }
  else {
    return (
      <div className="admin_panel_resource_container">
        <div className="admin_panel_resource_logo_img_container">
          <img
            onClick={() => {
              navigate("/");
            }}
            className="admin_panel_resource_logo_img"
            src={logo}
            alt="logo"
          />
        </div>
        <div className="admin_panel_resource_container_cont">
          <h1 className="admin_panel_resource_hed">Class Creation</h1>
        </div>
        <div className="mt-5 items-center rounded-md bg-slate-300 border-[#CBCBCB] border-[1px] p-6">
          <div className="flex justify-between p-3 items-center">
            <div className="text-3xl font-bold ">Students Requests</div>
            <input placeholder='Search Student Name' name='inp' id='inp' type="text" className='admin_panel_resource_search_bar_inp' value={searchTerm}
              onChange={handleSearch} />
          </div>
          <div className=" p-10 mt-5 space-y-5 bg-white rounded-lg">
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th> Class</th>
                  <th>Subject</th>
                  <th>Target Exam</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  filteredStudents.map((item, index) => (
                    <tr key={index}>
                      <td>{item?.personalDetails.first_name}</td>
                      <td>{item?.educationDetails?.class}</td>
                      {/* <td>{item?.educationDetails?.prefferedSub.map((item, key) => (item))}</td>
                    <td>{item?.educationDetails?.target_exam.map((item, key) => (item))}</td> */}
                      <td>
                        <div className='admin_panel_table_btns space-x-2'>
                          <button className='admin_panel_table_btns_btn2' onClick={() => { addStudentToBatchHandler(item) }}>Add To Batch</button>
                        </div>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
            <div className="flex justify-center mt-6">
              <button onClick={() => navigate('/classes')} className="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-blue-500 rounded-md focus:outline-none focus:bg-gray-600">
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default AddStudent