import React, { useState, useEffect } from 'react';
import './Topic.css'; // You need to create this CSS file for styling
import axios from 'axios';
import { toast } from 'react-toastify';
import baseUrl from "../../../../config/config";

function Topic() {
  const [topicName, setTopicName] = useState('');
  const [topicDescription, setTopicDescription] = useState('');
  const [thumbnail, setThumbnail] = useState(null);
  const [exams, setExams] = useState([]);
  const [selectedExamId, setSelectedExamId] = useState('');
  const [grades, setGrades] = useState([]);
  const [selectedGradeId, setSelectedGradeId] = useState('');
  const [subjects, setSubjects] = useState([]);
  const [selectedSubjectId, setSelectedSubjectId] = useState('');
  const [chapters, setChapters] = useState([]);
  const [selectedChapterId, setSelectedChapterId] = useState('');
  const [topics, setTopics] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [showTopicList, setShowTopicList] = useState(false);
  const [imageUrl, setImageUrl] = useState()


  // const baseUrl.Url /v1= "http://localhost:4001/v1";
  // const baseUrl.Url /v1= 'https://content-panel-intelli.onrender.com/v1'
  // const API_BASE_URL = 'https://test-content-dr78.onrender.com/v1'
  // const API_BASE_URL = 'https://internalrelease.onrender.com/v1'
// const baseUrl.Url /v1= 'https://content-panel-backend-urll.onrender.com/v1'


  useEffect(() => {
    fetchExams();
  }, []);

  const fetchExams = async () => {
    try {
      const response = await axios.get(`${baseUrl.Url}/v1/exam/getExams`);
      setExams(response.data.data);
    } catch (error) {
      console.error('Error fetching exams:', error);
    }
  };

  const fetchGrades = async (examId) => {
    try {
      const response = await axios.get(`${baseUrl.Url}/v1/grade/getgrades/${examId}`);
      setGrades(response.data.data);
    } catch (error) {
      console.error('Error fetching grades:', error);
    }
  };

  const fetchSubjects = async (gradeId) => {
    try {
      const response = await axios.get(`${baseUrl.Url}/v1/subject/getSubjects/${gradeId}`);
      setSubjects(response.data.data);
    } catch (error) {
      console.error('Error fetching subjects:', error);
    }
  };

  const fetchChapters = async (subjectId) => {
    try {
      const response = await axios.get(`${baseUrl.Url}/v1/chapter/getChapters/${subjectId}`);
      setChapters(response.data.data);
    } catch (error) {
      console.error('Error fetching chapters:', error);
    }
  };

  const handleGradeSelection = (gradeId) => {
    setSelectedGradeId(gradeId);
    fetchSubjects(gradeId);
  };

  const handleTopicNameChange = (e) => {
    setTopicName(e.target.value);
  };

  const handleTopicDescriptionChange = (e) => {
    setTopicDescription(e.target.value);
  };

  const handleThumbnailChange = (e) => {
    const file = e.target.files[0];
    setThumbnail(file);
 
    const data = new FormData();
    data.append("file" , thumbnail)
    data.append("upload_preset", "dagada")
    data.append("cloud_name" , 'dfzd4xbsk')
  
    fetch("https://api.cloudinary.com//v1_1/dfzd4xbsk/image/upload" , {
      method: 'POST',
      body: data
    }).then((res)=>res.json())
    .then((data)=>{
      console.log(data)
      setImageUrl(data.url);
    }).catch((err)=>{
      console.log(err);
      toast.error(err);
    })
  };


  const handleExamSelection = (examId) => {
    setSelectedExamId(examId);
    setSelectedGradeId('');
    setSelectedSubjectId('');
    setSelectedChapterId('');
    fetchGrades(examId);
  };

  const handleSubjectSelection = (subjectId) => {
    setSelectedSubjectId(subjectId);
    setSelectedChapterId('');
    fetchChapters(subjectId);
  };

  const handleChapterSelection = (chapterId) => {
    setSelectedChapterId(chapterId);

    fetchTopics(chapterId);

  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!topicName) {
        toast.error('Please Enter Exam Name');
        return;
      }

      if (!imageUrl) {
        const userConfirmed = window.confirm(
          'Thumbnail image URL is empty. Do you want to proceed without a thumbnail?'
        );
  
        if (!userConfirmed) {
          return;
        }
      }
      // const imageUrl = cld.image(thumbnail).toURL();
      const token = localStorage.getItem('token');

      const response = await axios.post(
        `${baseUrl.Url}/v1/topic/createTopic`,
        {
          topicName,
          topicDescription,
          thumbnail: imageUrl,
          examId: selectedExamId,
          gradeId: selectedGradeId,
          subjectId: selectedSubjectId,
          chapterId: selectedChapterId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 201) {
        toast.success('Topic created successfully!');
        setTopicName('');
        setTopicDescription('');
        setThumbnail(null);
        setSelectedExamId('');
        setSelectedGradeId("");
        setSelectedSubjectId('');
        setSelectedChapterId('');
        setImageUrl(null);
        clearFileInput();
        fetchExams();

      }
    } catch (error) {
      console.error('Error creating topic:', error);
      toast.error(error.response.data.error);
    }
  };

  const clearFileInput = () => {
    const fileInput = document.getElementById('thumbnail');
    if (fileInput) {
      fileInput.value = ''; // Clear the value of the file input
    }
  };

  const fetchTopics = async (chapterId) => {
    try {
      const response = await axios.get(`${baseUrl.Url}/v1/topic/getTopics/${chapterId}`);
      setTopics(response.data.data);
    } catch (error) {
      console.error('Error fetching topics:', error);
    }
  };


  const handleEditTopic = (topic) => {
    setSelectedTopic(topic);
    setTopicName(topic.topicName);
    setTopicDescription(topic.topicDescription);
  };

  const handleUpdateTopic = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.put(
        `${baseUrl.Url}/v1/topic/updateTopic/${selectedTopic._id}`,
        {
          topicName,
          topicDescription,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        toast.success('Topic updated successfully!');
        setSelectedTopic(null);
        setSelectedExamId('');
        setSelectedSubjectId('');
        setSelectedChapterId('');
        setTopics([]);
        fetchExams();
      }
    } catch (error) {
      console.error('Error updating topic:', error);
      toast.error('An error occurred while updating the topic.');
    }
  };



  return (
    <div className="App">
 <div className="sub-navbar">
        <div className="dropdown">
          <select
            className="dropbtn"
            value={selectedExamId}
            onChange={(e) => handleExamSelection(e.target.value)}
          >
            {/* Options for Exams */}
            <option value="">Select an Exam</option>
            {Array.isArray(exams) ? (
              exams.map((exam) => (
                <option key={exam._id} value={exam._id}>
                  {exam.examName}
                </option>
              ))
            ) : null}
          </select>
        </div>
        <div className="dropdown">
          <select
            className="dropbtn"
            value={selectedGradeId}
            onChange={(e) => handleGradeSelection(e.target.value)}
          >
            {/* Options for Grades */}
            <option value="">Select a Grade</option>
            {Array.isArray(grades) ? (
              grades.map((grade) => (
                <option key={grade._id} value={grade._id}>
                  {grade.gradeName}
                </option>
              ))
            ) : null}
          </select>
        </div>
        <div className="dropdown">
          <select
            className="dropbtn"
            value={selectedSubjectId}
            onChange={(e) => handleSubjectSelection(e.target.value)}
          >
            {/* Options for Subjects */}
            <option value="">Select a Subject</option>
            {Array.isArray(subjects) ? (
              subjects.map((subject) => (
                <option key={subject._id} value={subject._id}>
                  {subject.subjectName}
                </option>
              ))
            ) : null}
          </select>
        </div>
        <div className="dropdown">
          <select
            className="dropbtn"
            value={selectedChapterId}
            onChange={(e) => handleChapterSelection(e.target.value)}
          >
            {/* Options for Chapters */}
            <option value="">Select a Chapter</option>
            {Array.isArray(chapters) ? (
              chapters.map((chapter) => (
                <option key={chapter._id} value={chapter._id}>
                  {chapter.chapterName}
                </option>
              ))
            ) : null}
          </select>
        </div>
      </div>
      <form className="form" onSubmit={handleSubmit}>
        <label htmlFor="topicName">Topic Name:</label>
        <input
          type="text"
          id="topicName"
          value={topicName}
          onChange={handleTopicNameChange}
        />

        <label htmlFor="topicDescription">Topic Description:</label>
        <textarea
          id="topicDescription"
          value={topicDescription}
          onChange={handleTopicDescriptionChange}
        />

        <label htmlFor="thumbnail">Upload Thumbnail:</label>
        <input
          type="file"
          id="thumbnail"
          accept="image/*"
          onChange={handleThumbnailChange}
        />

        <button type="submit">Submit</button>
      </form>



      <div className="sub-navbar">
        <button className='get_topics' onClick={() => setShowTopicList(!showTopicList)}>
          {showTopicList ? 'Hide Topic Data' : 'Get Topic Data'}
        </button>
      </div>
      {showTopicList && (
        <div className="edit-form">
          <h2 className="section-title">Topic List</h2>
          <ul>
            {topics.map((topic) => (
              <li className="topic-item" key={topic._id}>
                <span>{topic.topicName}</span>
                <button className="edit-button" onClick={() => handleEditTopic(topic)}>Edit</button>
              </li>
            ))}
          </ul>
        </div>
      )}
      {selectedTopic && (
        <div className="edit-form">
          <h2 className="section-title">Edit Topic</h2>
          <label htmlFor="editTopicName">Topic Name:</label>
          <input
            type="text"
            id="editTopicName"
            value={topicName}
            onChange={handleTopicNameChange}
            className="input-field"
          />

          <label htmlFor="editTopicDescription">Topic Description:</label>
          <textarea
            id="editTopicDescription"
            value={topicDescription}
            onChange={handleTopicDescriptionChange}
            className="textarea-field"
          />

          <button className="update-button" onClick={handleUpdateTopic}>Update</button>
          <button className="cancel-button" onClick={() => setSelectedTopic(null)}>Cancel</button>
        </div>
      )}
    </div>
  );
}

export default Topic;
