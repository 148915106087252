import React, { useEffect, useState } from "react";
import logo from "../../assets/logo2.png";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { FaFilePdf } from "react-icons/fa";
import axios from "axios";
import loader from "../../assets/icons/loader.gif";
import baseUrl from "../../config/config"
import { useSelector } from "react-redux";
function ChapterDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);
  const [flag, setFlag] = useState(false);
  const users = useSelector((state) => { return state.users });
  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${baseUrl.tms}/tms/apis/v1/chapter/${id}`,{
          headers: {
            Authorization: `Bearer ${users.authToken}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
            app_name: 'admin',
            app_version_code: '101',
          }
        }
      )
      .then((res) => {
        setData(res.data.data);
        console.log(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("unable to get assigment details", err);
      });
  }, [flag]);
  if (loading || data == null) {
    return (
      <div className="admin_panel_resource_container_loading">
        <img
          src={loader}
          className="admin_panel_resource_container_loading_img"
        />
      </div>
    );
  } else {
    return (
      <div className="admin_panel_resource_container">
        <div className="admin_panel_resource_logo_img_container">
          <img
            onClick={() => {
              navigate("/");
            }}
            className="admin_panel_resource_logo_img"
            src={logo}
            alt="logo"
          />
        </div>
        <div className="admin_panel_resource_container_cont">
          <h1 className="admin_panel_resource_hed">Chapter Details</h1>
        </div>
        <div className="space-y-5">
          <div className="flex justify-between border items-center rounded-md border-blue-500 p-6">
            <div className="p-2">
              <div className="text-gray-500 text-3xl">
                {data?.chapter?.name}
              </div>
            </div>
            <div className="p-2"></div>
          </div>
          <div className="flex justify-between border items-center rounded-md border-blue-500 p-6">
            <div className="p-2">
              <div className="text-gray-500 text-xl">
                {data?.chapter?.description}
              </div>
            </div>
            <div className="p-2"></div>
          </div>
          <div className="mt-5 items-center rounded-md bg-slate-300 border-[#CBCBCB] border-[1px] p-6">
            <div className="flex justify-between p-3 items-center">
              <div className="text-3xl font-bold ">Chapter Overview</div>
            </div>
            <div className="mx-10 p-5">
            <h3 className="font-bold text-2xl mb-5">Sessions In Chapter</h3>
            <div className="space-y-5">
              {
                data?.chapter?.session?.map((item, key) => (
                  <div key={key} onClick={() => { navigate(`/session/${item}`) }}  className="flex p-5 bg-[#6764FF] text-black justify-between cursor-pointer ">
                    <div>{`session ${key+1}`}</div>
                  </div>
                ))
              }
            </div> 
            </div>  
          </div>
        </div>
      </div>
    );
  }
}
export default ChapterDetails;
