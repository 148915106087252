import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './NewExamPanel.css';

const API_BASE_URL = 'https://content-panel-backend-urll.onrender.com/v1';
const API_DEV_TASK = 'https://dev-task-services.onrender.com/tsv/apis/v1'

const NewExamPanel = () => {
  const [exams, setExams] = useState([]);

  const [selectedExamId, setSelectedExamId] = useState('');
  const [subjects, setSubjects] = useState([]);

  const [selectedSubjectId, setSelectedSubjectId] = useState('');
  const [chapters, setChapters] = useState([]);

  const [selectedChapterId, setSelectedChapterId] = useState('');
  const [topics, setTopics] = useState([]);

  const [selectedTopicId, setSelectedTopicId] = useState('');
  const [subTopics, setSubTopics] = useState([]);

  const [selectedSubTopicId, setSelectedSubTopicId] = useState('');
  const [question, setQuestion] = useState({
    type: 'multipleChoice', // Default type, replace it with your actual state
  });

  const [examData, setExamData] = useState({
    examName: '',
    numberOfQuestions: '',
    totalMarks: '',
    PerQuestionMark: '',
    price: '',
    language: '',
    examDuration: '00:00',
    subject: '',
    chapter: '',
    topic: '',
    SubTopic: '',
    targetExam: '',
  });

  useEffect(() => {
    (async()=>{
      await fetchExams();
    })()
  }, []);
  
  const fetchExams = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/exam/getExams`);
      setExams(response.data.data);
    } catch (error) {
      console.error('Error fetching exams:', error);
    }
  };

  const fetchSubjects = async (examId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/subject/getSubjects/${examId}`);
      setSubjects(response.data.data);
    } catch (error) {
      console.error('Error fetching subjects:', error);
    }
  };

  const fetchChapters = async (subjectId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/chapter/getChapters/${subjectId}`);
      setChapters(response.data.data);
    } catch (error) {
      console.error('Error fetching chapters:', error);
    }
  };

  const fetchTopics = async (chapterId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/topic/getTopics/${chapterId}`);
      setTopics(response.data.data);
    } catch (error) {
      console.error('Error fetching topics:', error);
    }
  };

  const fetchSubTopics = async (topicId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/subTopic/getSubTopics/${topicId}`);
      setSubTopics(response.data.data);
    } catch (error) {
      console.error('Error fetching subtopics:', error);
    }
  };
  const handleExamSelection = (examId) => {
    const selectedExam = exams.find((exam) => exam._id === examId);
    setSelectedExamId(examId);
    // setExam(selectedExam);

    setSelectedSubjectId('');
    setSelectedChapterId('');
    setSelectedTopicId('');
    setSelectedSubTopicId('');
    fetchSubjects(examId);
    // Set subject name in the examData
    setExamData((prevData) => ({
      ...prevData,
      targetExam: selectedExam.examName,
    }));
  };

  const handleSubjectSelection = (subjectId) => {
    const selectedSubject = subjects.find((subject) => subject._id === subjectId);
    setSelectedSubjectId(subjectId);
    setSelectedChapterId('');
    setSelectedTopicId('');
    setSelectedSubTopicId('');
    fetchChapters(subjectId);

    // Set subject name in the examData
    setExamData((prevData) => ({
      ...prevData,
      subject: selectedSubject.subjectName,
    }));
  };

  const handleChapterSelection = (chapterId) => {
    const selectedChapter = chapters.find((chapter) => chapter._id === chapterId);
    setSelectedChapterId(chapterId);
    setSelectedTopicId('');
    setSelectedSubTopicId('');
    fetchTopics(chapterId);

    // Set chapter name in the examData
    setExamData((prevData) => ({
      ...prevData,
      chapter: selectedChapter.chapterName,
    }));
  };

  const handleTopicSelection = (topicId) => {
    const selectedTopic = topics.find((topic) => topic._id === topicId);
    setSelectedTopicId(topicId);
    setSelectedSubTopicId('');
    fetchSubTopics(topicId);

    // Set topic name in the examData
    setExamData((prevData) => ({
      ...prevData,
      topic: selectedTopic.topicName,
    }));
  };

  const handleSubTopicSelection = (subTopicId) => {
    const selectedSubTopic = subTopics.find((subTopic) => subTopic._id === subTopicId);
    setSelectedSubTopicId(subTopicId);

    // Set subtopic name in the examData
    setExamData((prevData) => ({
      ...prevData,
      subTopic: selectedSubTopic.subTopicName,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setExamData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const notifySuccess = () => toast.success('Exam created successfully!');
  const notifyError = () => toast.error('Error creating exam. Please try again.');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const postData = {
        userId: 'user123',
        selectedExamId,
        selectedSubjectId,
        selectedChapterId,
        selectedTopicId,
        selectedSubTopicId,
        questionType: question.type,
        ...examData,
        examCreateFrom: 'Panel'
      };

      const response = await axios.post(`${API_DEV_TASK}/panelExams`, postData);

      console.log('API Response:', response.data);

      notifySuccess();

      setExamData({
        examName: '',
        numberOfQuestions: '',
        totalMarks: '',
        PerQuestionMark: '',
        price: '',
        language: '',
        examDuration: '',
      });
    } catch (error) {
      console.error('Error submitting data:', error);
      notifyError();
    }
  };

  return (
    <div className="exam-panel">
      <div className="new-exam-panel">
        <h2 style={{ color: "#3498db", fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>
          Create New Exam <span style={{ color: '#E1A325', fontSize: '16px' }}>(Exam set will take Question from intelli panel)</span>
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="sub-navbar">
            <div className="dropdown">
              <select
                className="dropbtn"
                value={selectedExamId}
                onChange={(e) => handleExamSelection(e.target.value)}
              >
                <option value="">Select an Exam</option>
                {Array.isArray(exams) ? (
                  exams.map((exam) => (
                    <option key={exam._id} value={exam._id}>
                      {exam.examName}
                    </option>
                  ))
                ) : (
                  <option key={exams._id} value={exams._id}>
                    {exams.examName}
                  </option>
                )}
              </select>
            </div>
            <div className="dropdown">
              <select
                className="dropbtn"
                value={selectedSubjectId}
                onChange={(e) => handleSubjectSelection(e.target.value)}
              >
                <option value="">Select a Subject</option>
                {Array.isArray(subjects) ? (
                  subjects.map((subject) => (
                    <option key={subject._id} value={subject._id}>
                      {subject.subjectName}
                    </option>
                  ))
                ) : (
                  <option key={subjects._id} value={subjects._id}>
                    {subjects.subjectName}
                  </option>
                )}
              </select>
            </div>
            <div className="dropdown">
              <select
                className="dropbtn"
                value={selectedChapterId}
                onChange={(e) => handleChapterSelection(e.target.value)}
              >
                <option value="">Select a Chapter</option>
                {Array.isArray(chapters) ? (
                  chapters.map((chapter) => (
                    <option key={chapter._id} value={chapter._id}>
                      {chapter.chapterName}
                    </option>
                  ))
                ) : (
                  <option key={chapters._id} value={chapters._id}>
                    {chapters.chapterName}
                  </option>
                )}
              </select>
            </div>
            <div className="dropdown">
              <select
                className="dropbtn"
                value={selectedTopicId}
                onChange={(e) => handleTopicSelection(e.target.value)}
              >
                <option value="">Select a Topic</option>
                {Array.isArray(topics) ? (
                  topics.map((topic) => (
                    <option key={topic._id} value={topic._id}>
                      {topic.topicName}
                    </option>
                  ))
                ) : (
                  <option key={topics._id} value={topics._id}>
                    {topics.topicName}
                  </option>
                )}
              </select>
            </div>
            <div className="dropdown">
              <select
                className="dropbtn"
                value={selectedSubTopicId}
                onChange={(e) => handleSubTopicSelection(e.target.value)}
              >
                <option value="">Select Sub Topic</option>
                {Array.isArray(subTopics) ? (
                  subTopics.map((subTopic) => (
                    <option key={subTopic._id} value={subTopic._id}>
                      {subTopic.subTopicName}
                    </option>
                  ))
                ) : (
                  <option key={subTopics._id} value={subTopics._id}>
                    {subTopics.subTopicName}
                  </option>
                )}
              </select>
            </div>
          </div>

          <label>
            Exam Type
          </label>
          <div className="toggle-container">
            <button className={`toggle-btn ${question.type === 'multipleChoice' ? 'active' : ''}`}
              onClick={() => setQuestion(prev => ({ ...prev, type: 'multipleChoice' }))}>Objective</button>
            <button className={`toggle-btn ${question.type === 'subjective' ? 'active' : ''}`}
              onClick={() => setQuestion(prev => ({ ...prev, type: 'subjective' }))}>Subjective</button>
          </div>

          <br />
          <label>
            Exam Name:
          </label>
          <input
            type="text"
            name="examName"
            placeholder='Name of this Exam'
            value={examData.examName}
            onChange={handleChange}
            required
          />
          <br />
          <label>
            Number of Questions:
          </label>
          <input
            type="number"
            name="numberOfQuestions"
            placeholder='Number of questions will take'
            value={examData.numberOfQuestions}
            onChange={handleChange}
            required
          />
          <br />
          <label>
            Total Marks:
          </label>
          <input
            type="number"
            name="totalMarks"
            placeholder='Total exam Marks'
            value={examData.totalMarks}
            onChange={handleChange}
            required
          />
          <br />
          <label>
            Mark Per Question:
          </label>
          <input
            type="number"
            name="PerQuestionMark"
            placeholder='Per Question Mark'
            value={examData.PerQuestionMark}
            onChange={handleChange}
            required
          />
          <br />
          <label>
            Exam Duration(In hr)
          </label>
          <input
            type="time"
            name="examDuration"
            placeholder="Exam Duration Time"
            value={examData.examDuration}
            onChange={handleChange}
            required
          />
          <br />
          <label>
            Price:
          </label>
          <input
            type="number"
            name="price"
            placeholder='Exam Price(including all taxes + 10% platform charge)'
            value={examData.price}
            onChange={handleChange}
            required
          />
          <br />
          <label>
            Language:
          </label>
          <input
            type="text"
            name="language"
            placeholder='Exam Language'
            value={examData.language}
            onChange={handleChange}
            required
          />
          <br />
          <button className='dropbtn' type="submit">Create Exam</button>
        </form>


      </div>
    </div>
  );
};

export default NewExamPanel;
