import React, { useEffect, useState } from 'react'
import logo from "../../../assets/logo2.png"
import search from "../../../assets/icons/search.png"
import "../../../Styles/Resource.css"
// import { data } from '../../../utils/data'
import { useNavigate } from 'react-router-dom'
import Select from "react-select";
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import loader from "../../../assets/icons/loader.gif"

import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { useSelector } from 'react-redux'
import baseUrl from "../../../config/config"
import Assigment from '../Assigment'
const subjectsData = [
  {
    "value": "All",
    "label": "All",
    "value": "All",
    "label": "All"
  },
  {
    "value": "History",
    "label": "History"
  },
  {
    "value": "Geography",
    "label": "Geography"
  },
  {
    "value": "English",
    "label": "English"
  },
  {
    "value": "Maths",
    "label": "Maths"
  },
  {
    "value": "Science",
    "label": "Science"
  },
  {
    "value": "Compuer Science",
    "label": "Compuer Science"
  },
  {
    "value": "Art",
    "label": "Art"
  },
  {
    "value": "Music",
    "label": "Music"
  },
  {
    "value": "Physical Education",
    "label": "Physical Education"
  },
]
const typeOfResData = [
  {
    "value": "video",
    "label": "video"
  },
  {
    "value": "text",
    "label": "text"
  }
]
const classData = [
  {
    "value": "1",
    "label": "1st "
  },
  {
    "value": "2",
    "label": "2nd "
  },
  {
    "value": "3",
    "label": "3rd "
  },
  {
    "value": "4",
    "label": "4th "
  },
  {
    "value": "5",
    "label": "5th "
  },
  {
    "value": "6",
    "label": "6th "
  },
  {
    "value": "7",
    "label": "7th "
  },
  {
    "value": "8",
    "label": "8th "
  },
  {
    "value": "9",
    "label": "9th "
  },
  {
    "value": "10",
    "label": "10th "
  },
  {
    "value": "11",
    "label": "11th "
  },
  {
    "value": "12",
    "label": "12th "
  },
];



function Assignment() {
  const [selectedMode, setselectedMode] = useState('pending')
  const [showFitlerModal, setshowFitlerModal] = useState(false)

  const [price, setPrice] = useState()
  const [subject, setSubject] = useState()
  const [cls, setCls] = useState()
  const [type, setType] = useState()

  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)

  const [ResourceModal, setResourceModal] = useState(false);

  const navigate = useNavigate()
  const users = useSelector((state) => { return state.users });

  const [nameR, setNameR] = useState()
  const [clssR, setClssR] = useState()
  const [subjR, setSubjR] = useState()
  const [priceR, setPriceR] = useState()
  const [descpR, setdescpR] = useState()
  const [UrlR, setUrlR] = useState()
  const [publishedByR, setpublishedByR] = useState()
  const [resourcestypeR, setresourcestypeR] = useState()


  const toggleModel = async () => {
    setshowFitlerModal(!showFitlerModal)
  }

  // const applyFilter = async () => {
  //   try {

  //     setLoading(true)
  //     const apiUrl = `https://dev-task-services.onrender.com/tsv/apis/v1//search/resource?subject=${subject}&classes=${cls}&resourcestype=${type}&price=${price}`;
  //     const response = await axios.get(apiUrl);
  //     setData(response.data.data)
  //     setshowFitlerModal(false)

  //     console.log(response.data)
  //     setLoading(false)
  //   } catch (e) {
  //     console.log(e);
  //     setLoading(false)
  //     toast.error("something went wrong !")
  //   }
  // }
  const applyFilter = async () => {
    try {
      setLoading(true);

      // Constructing URL based on conditions
      let apiUrl = `${baseUrl.task}/tsv/apis/v1//search/resource?`;

      // Appending subject to URL if it exists
      if (subject) {
        apiUrl += `subject=${subject}&`;
      }

      // Appending classes to URL if it exists
      if (cls) {
        apiUrl += `classes=${cls}&`;
      }

      // Appending resourcestype to URL if it exists
      if (type) {
        apiUrl += `resourcestype=${type}&`;
      }

      // Appending price to URL if it exists
      if (price) {
        apiUrl += `price=${price}`;
      }

      // Remove trailing '&' if present
      if (apiUrl.endsWith('&')) {
        apiUrl = apiUrl.slice(0, -1);
      }

      const response = await axios.get(apiUrl,
        {
          headers: {
            Authorization: `Bearer ${users.authToken != null ? users.authToken : users.unauthToken}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
            app_name: 'admin',
            app_version_code: '101',
          }
        },
      );
      setData(response.data.data);
      setshowFitlerModal(false);
      setLoading(false);
      console.log(response.data);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error('Something went wrong!');
    }
  };

  const fetchResources = async (status) => {
    try {

      setLoading(true)
      const apiUrl = `${baseUrl.tms}/tms/apis/v1/assignment/get/all/req?publishStatus=${status}`;
      const response = await axios.get(apiUrl,
        {
          headers: {
            Authorization: `Bearer ${users.authToken != null ? users.authToken : users.unauthToken}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
            app_name: 'admin',
            app_version_code: '101',
          }
        },
      );
      setData(response.data.data)
      console.log(response.data)
      setLoading(false)
    } catch (e) {
      console.log(e);
      setLoading(false)
      toast.error("Something went wrong !")
    }
  }
  useEffect(() => {
    fetchResources(selectedMode)
  }, [selectedMode])

  const filteredData = () => {
    if (selectedMode === "published") {
      return data.filter((elem) => { return elem.publishStatus == "published" && elem.isdelete !== "true" }) || []
    } else if (selectedMode === "pending") {
      return data.filter((elem) => { return elem.publishStatus === "pending" && elem.isdelete !== "true" }) || []
    } else if (selectedMode === "myresource") {
      return data.filter((elem) => { return elem.publishStatus === "draft" && elem.isdelete !== "true" }) || []
    } else {
      return []
    }
  }
  const clearFilter = async () => {
    fetchResources()
  }
  const requestResourceHandler = async (id) => {
    await axios.put(`${baseUrl.task}/tsv/apis/v1/resource/${id}`, {
      "publishStatus": "requested"
    },
      {
        headers: {
          Authorization: `Bearer ${users.authToken != null ? users.authToken : users.unauthToken}`,
          'Content-Type': 'application/json',
          Accept: '*/*',
          app_name: 'admin',
          app_version_code: '101',
        }
      },
    )
    fetchResources()
  }
  const createResource = async () => {
    if (!priceR || !descpR || !nameR || !clssR || !subjR || !UrlR || !publishedByR || !resourcestypeR) {
      toast.error("Provide all fields !")
    } else {
      try {

        const Result = await axios.post(`${baseUrl.task}/tsv/apis/v1/create/resource`, {
          "class": clssR,
          "subject": subjR,
          "name": nameR,
          "description": descpR,
          "userId": users.userId,
          "price": priceR,
          "resourcestype": resourcestypeR,
          "Url": UrlR,
          "publishedBy": publishedByR
        },
          {
            headers: {
              Authorization: `Bearer ${users.authToken != null ? users.authToken : users.unauthToken}`,
              'Content-Type': 'application/json',
              Accept: '*/*',
              app_name: 'admin',
              app_version_code: '101',
            }
          },
        )
        console.log('createApi', Result);
        setPriceR(null);
        setdescpR(null);
        setNameR(null);
        setClssR(null);
        setSubjR(null);
        setUrlR(null);
        setpublishedByR(null);
        setresourcestypeR(null);
        setResourceModal(false)
        toast.success("Resource Created successfully !")
        fetchResources()
        setselectedMode("pending")
      } catch (e) {
        toast.error("something went wrong !")
        console.log(e)
      }
    }
  }
  const ModalCloseHandler = async () => {
    setResourceModal(false)
  }
  const approveHandler = async (id) => {
    try {
      setLoading(true)
      await axios.put(`${baseUrl.tms}/tms/apis/v1/assignment/publish-status/${id}`, { publishStatus: "published" },
        {
          headers: {
            Authorization: `Bearer ${users.authToken != null ? users.authToken : users.unauthToken}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
            app_name: 'admin',
            app_version_code: '101',
          }
        },
      )
      fetchResources(selectedMode);
    } catch (e) {
      console.log(e)
      toast.error("something went wrong !")
      setLoading(false)
    }
  }
  const deleteHandler = async (id) => {
    try {
      setLoading(true)
      await axios.put(`${baseUrl.task}/tsv/apis/v1/resource/${id}`, {
        "isdelete": true
      },
        {
          headers: {
            Authorization: `Bearer ${users.authToken != null ? users.authToken : users.unauthToken}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
            app_name: 'admin',
            app_version_code: '101',
          }
        },
      )
      let arr = data.filter((elem) => { return elem._id !== id })
      setData(arr)
      setLoading(false)
    } catch (e) {
      console.log(e)
      toast.error("something went wrong !")
      setLoading(false)
    }
  }
  if (loading || data == null) {
    return (
      <div className='admin_panel_resource_container_loading'><img src={loader} className='admin_panel_resource_container_loading_img' /></div>
    )
  } else {

    return (
      <div className='admin_panel_resource_container'>
        <div className='admin_panel_resource_logo_img_container'>
          <img onClick={() => { navigate("/") }} className='admin_panel_resource_logo_img' src={logo} alt="logo" />
        </div>
        <div className='admin_panel_resource_container_cont'>
          <Modal className='admin_panel_resource_model' open={ResourceModal} onClose={ModalCloseHandler} center>
            <h1 className='admin_panel_resource_heds'>Create Resource </h1>
            <input value={nameR} onChange={(e) => { setNameR(e.target.value) }} className='admin_panel_resource_model_inp' type="text" placeholder='Enter Resource name' />
            <input value={subjR} onChange={(e) => { setSubjR(e.target.value) }} className='admin_panel_resource_model_inp' type="text" placeholder='Enter Resource Subject' />
            <input value={priceR} onChange={(e) => { setPriceR(e.target.value) }} className='admin_panel_resource_model_inp' type="number" placeholder='Enter Resource Price' />
            <input value={clssR} onChange={(e) => { setClssR(e.target.value) }} className='admin_panel_resource_model_inp' type='number' placeholder='Enter Resource Class' />

            {/* <input value={resourcestypeR} onChange={(e) => { setresourcestypeR(e.target.value) }} className='admin_panel_resource_model_inp' type="text" placeholder='Enter Resource Type' /> */}
            <input value={UrlR} onChange={(e) => { setUrlR(e.target.value) }} className='admin_panel_resource_model_inp' type="text" placeholder='Enter resource URL' />
            <input value={publishedByR} onChange={(e) => { setpublishedByR(e.target.value) }} className='admin_panel_resource_model_inp' type="text" placeholder='Enter publisher name' />
            <Select
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  // width: "max-content",
                  minWidth: "300px",
                  borderColor: "white",
                  height: "30px",
                  cursor: "pointer",
                }),
                dropdownIndicator: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "#6B4EFF",
                }),
              }}
              className="admin_panel_resource_filter_container_item_drop"
              placeholder="Enter resource type"
              options={typeOfResData}
              onChange={(selectedOption) =>
                setresourcestypeR(selectedOption.value)
              }
            />
            <textarea value={descpR} onChange={(e) => { setdescpR(e.target.value) }} className='admin_panel_resource_model_inp_txt' placeholder='Enter Resource description' />


            <button className='admin_panel_resource_filter_btn' onClick={createResource}>Create</button>
          </Modal>
          <h1 className='admin_panel_resource_hed'>Class Assignment</h1>
          <div className='admin_panel_resource_search_bar'>
            <label htmlFor='inp'> <img className='admin_panel_resource_search_bar_img' src={search} alt="logo" /></label>
            <input placeholder='Search Study Material' name='inp' id='inp' type="text" className='admin_panel_resource_search_bar_inp' />
          </div>
        </div>

        <div className='admin_panel_resource_tabs'>
          <div style={selectedMode === "pending" ? { borderBottom: "3px solid #6764FF" } : null} onClick={() => { setselectedMode("pending") }} className='admin_panel_resource_tab'>Requests</div>
          <div style={selectedMode === "published" ? { borderBottom: "3px solid #6764FF" } : null} onClick={() => { setselectedMode("published") }} className='admin_panel_resource_tab'>Approved</div>
          <div style={selectedMode === "myresource" ? { borderBottom: "3px solid #6764FF" } : null} onClick={() => { setselectedMode("myresource") }} className='admin_panel_resource_tab'>My Resources</div>

        </div>
        <div className='admin_panel_resource_filter_btn' onClick={toggleModel}>Filter</div>
        <div className='admin_panel_resource_filter_btn_clear' onClick={clearFilter}>Clear Filter</div>

        {selectedMode === "myresource" ? <div className='admin_panel_resource_filter_btn' onClick={() => { setResourceModal(true) }}>Create</div> : null}


        {showFitlerModal ?
          <div className='admin_panel_resource_filter_container'>

            <div className='admin_panel_resource_filter_container_item'>
              <h3>Subject </h3>
              <Select
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    // width: "max-content",
                    minWidth: "300px",
                    borderColor: "white",
                    height: "48px",
                    cursor: "pointer",
                  }),
                  dropdownIndicator: (baseStyles, state) => ({
                    ...baseStyles,
                    color: "#6B4EFF",
                  }),
                }}
                className="admin_panel_resource_filter_container_item_drop"
                placeholder="Enter Subject"
                options={subjectsData}
                onChange={(selectedOption) =>
                  setSubject(selectedOption.value)
                }
              />
              ({subject})
            </div>

            <div className='admin_panel_resource_filter_container_item'>
              <h3>Price </h3>
              <input className='admin_panel_resource_filter_container_item_dragger' value={price} min="100" onChange={(e) => { setPrice(e.target.value) }} max="100000" type="range" />
              (Rs {price})
            </div>

            {/* <div className='admin_panel_resource_filter_container_item'>
  <h3>Rating </h3>
  <input className='admin_panel_resource_filter_container_item_dragger' value={rating} min="1" onChange={(e)=>{setRating(e.target.value)}} max="10" type="range" />
  ({rating})
  </div> */}
            <div className='admin_panel_resource_filter_container_item'>
              <h3>Type </h3>
              <Select
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    // width: "max-content",
                    minWidth: "300px",
                    borderColor: "white",
                    height: "48px",
                    cursor: "pointer",
                  }),
                  dropdownIndicator: (baseStyles, state) => ({
                    ...baseStyles,
                    color: "#6B4EFF",
                  }),
                }}
                className="admin_panel_resource_filter_container_item_drop"
                placeholder="Enter type of resource"
                options={typeOfResData}
                onChange={(selectedOption) =>
                  setType(selectedOption.value)
                }
              />
              ({type})
            </div>
            <div className='admin_panel_resource_filter_container_item'>
              <h3>Class </h3>
              <Select
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    // width: "max-content",
                    minWidth: "300px",
                    borderColor: "white",
                    height: "48px",
                    cursor: "pointer",
                  }),
                  dropdownIndicator: (baseStyles, state) => ({
                    ...baseStyles,
                    color: "#6B4EFF",
                  }),
                }}
                className="admin_panel_resource_filter_container_item_drop"
                placeholder="Enter Class"
                options={classData}
                onChange={(selectedOption) =>
                  setCls(selectedOption.value)
                }
              />
              ({cls})
            </div>

            <div className='admin_panel_resource_filter_btn_apply' onClick={applyFilter}>Apply Filters</div>
          </div> :
          null
        }

        {filteredData().length !== 0 ? <table>
          <thead>
            <tr>

              <th>Name</th>
              <th> Published By</th>
              <th>Type</th>
              {/* <th>Rating</th> */}
              <th>Action</th>
              {selectedMode === "myresource" ? <th>Edit</th> : null}
              {selectedMode === "myresource" ? <th>Delete</th> : null}
            </tr>
          </thead>
          <tbody>
            {filteredData().map((val, key) => (
              <tr key={key}>
                <td>{val.name}</td>
                <td>{val.publishedBy}</td>
                <td>{val?.resourcestype}</td>
                {/* <td>{val.rating}</td> */}
                <td>
                  <div className='admin_panel_table_btns'>
                    {
                      selectedMode === "pending" ?
                        <>
                          <button className='admin_panel_table_btns_btn1' onClick={() => { window.open(val.AssignmentUrl, '_blank') }}>View</button>
                          <button className='admin_panel_table_btns_btn2' onClick={() => { approveHandler(val._id) }}>Approve</button>
                        </>
                        :
                        selectedMode === "myresource" ? <button className='admin_panel_table_btns_btn1' onClick={() => { requestResourceHandler(val._id) }}>Request</button> : <button className='admin_panel_table_btns_btn1' onClick={() => { window.open(val.AssignmentUrl, '_blank') }}>View Details</button>

                    }
                  </div>

                </td>
                <td>
                  {selectedMode === "myresource" ?
                    <div className='admin_panel_table_btns'>
                      <button className='admin_panel_table_btns_btn1' onClick={() => { navigate(`/edit-resource/${val._id}`) }}>Edit</button>
                    </div>
                    : null
                  }

                </td>
                <td>
                  {selectedMode === "myresource" ?
                    <div className='admin_panel_table_btns'>
                      <button className='admin_panel_resource_filter_btn_clear' onClick={() => { deleteHandler(val._id) }}>Delete</button>
                    </div>
                    : null
                  }
                </td>
              </tr>
            ))}
          </tbody>

        </table>

          : "No item found !"}
        <ToastContainer />
      </div>
    )
  }

}

export default Assignment
