import React, { useState, useEffect } from "react";
import axios from "axios";

import "./Exam.css"; // Import your CSS file
import { useNavigate } from "react-router-dom";
import baseUrl from "../../../config/config";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const API_DEV_TASK = `${baseUrl.task}/tsv/apis/v1`;

const ExamComponent = () => {
  const users = useSelector((state) => {
    return state.users;
  });
  const [panelExams, setPanelExams] = useState([]);
  const [custumExams, setCustumExams] = useState([]);
  const [examSelect, setExamSelect] = useState();
  const navigate = useNavigate();
  const [selectedMode, setselectedMode] = useState("pending");

  const fetchData = async () => {
    try {
      let apiPath = "";
      if (selectedMode === "myexam") {
        // Replace with the logic to get the user ID dynamically
        const userId = "user123"; // You can get this dynamically based on your authentication logic
        apiPath = `/get/All/Exam?userId=${userId}`;
      } else {
        apiPath = `/v1/examQuesWeb/get/exam/${selectedMode}`;
      }

      const response = await axios.get(`${baseUrl.Url}${apiPath}`, {
        headers: {
          Authorization: `Bearer ${users.authToken}`,
          "Content-Type": "application/json",
          Accept: "*/*",
          app_name: "admin",
          app_version_code: "101",
        },
      });
      console.log('response', response.data.data);
      setCustumExams(response.data.data.exams); // Assuming the API response contains an array of exams
      setPanelExams(response.data.data);
    } catch (error) {
      console.error("Error fetching exam data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [selectedMode]);

  const handleAddExam = (exam) => {
    setExamSelect(exam);
    if (exam === "custom") {
      navigate("/custom-exam");
    } else if (exam === "panel") {
      navigate("/create-exam-panel");
    }
  };
  const approveHandler = async (examId) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.patch(
        `${API_DEV_TASK}/exam/${examId}`, // Correct the path syntax by using `${examId}`
        {
          publishStatus: "REQUESTED", // Set the data in the request body
        },
        {
          headers: {
            Authorization: `Bearer ${users.authToken}`,
            "Content-Type": "application/json",
            Accept: "*/*",
            app_name: "admin",
            app_version_code: "101",
          },
        }
      );

      if (response.status === 200) {
        toast.success("Request Send successfully!");
        // setCustumExams((prev) => prev.filter((q) => q._id !== examId));
      }
    } catch (error) {
      console.error("Error in sending request question:", error);
      toast.error("Failed to send request. Please try again.");
    }
  };
  const handleToPublish = async (examId) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(`${baseUrl.Url}/v1/examQuesWeb/${examId}`,
        {
          publishStatus: "published",
          published: true
        },
        {
          headers: {
            Authorization: `Bearer ${users.authToken}`,
            "Content-Type": "application/json",
            Accept: "*/*",
            app_name: "admin",
            app_version_code: "101",
          },
        }
      );
      if (response.status === 200) {
        toast.success("Published successfully!");
        fetchData();
        // setExams((prev) => prev.filter((q) => q._id !== examId));
      }
    } catch (error) {
      console.error("Error in Publishing Exam:", error);
      toast.error("Failed to Publish Exam. Please try again.");
    }
  };
  return (
    <div className="exam-list-container">
      <h1 className="exam-head">Exam</h1>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <div className="admin_panel_resource_tabs">
          <div
            style={
              selectedMode === "published"
                ? { borderBottom: "3px solid #6764FF" }
                : null
            }
            onClick={() => {
              setselectedMode("published");
            }}
            className="admin_panel_resource_tab"
          >
            Approved
          </div>
          <div
            style={
              selectedMode === "pending"
                ? { borderBottom: "3px solid #6764FF" }
                : null
            }
            onClick={() => {
              setselectedMode("pending");
            }}
            className="admin_panel_resource_tab"
          >
            Requests
          </div>
        </div>
      </div>
      <h1
        style={{
          margin: "20px 0px",
          color: "#3498db",
          fontSize: "18px",
          fontWeight: "bold",
        }}
      >
        Exams Created By Panel
      </h1>
      <div className="exam-cards-container">
        {Array.isArray(panelExams) && panelExams.length > 0 ? (
          panelExams.map((exam) => (
            <div className="exam-card" key={exam._id}>
              <h2
                style={{
                  color: "#3498db",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                {" "}
                <span style={{ color: "#3498db", fontSize: "16px" }}>
                  {exam.targetExam}
                </span>{" "}
              </h2>
              <h3 style={{ color: "#3498db", fontSize: "18px" }}>
                {" "}
                <span style={{ color: "#3498db", fontSize: "16px" }}>
                  {" "}
                  {exam.exam_name}
                </span>
              </h3>
              {/* Add more details based on your needs */}
              <p>
                Duration:{" "}
                <span style={{ color: "#3498db", fontSize: "16px" }}>
                  {" "}
                  {exam.duration} {"Mins"}
                </span>
              </p>
              <p>
                Number of Questions:{" "}
                <span style={{ color: "#3498db", fontSize: "16px" }}>
                  {exam.questions.length}{" "}
                </span>
              </p>
              {/* ... (more details) */}
              <p
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                ₹{exam.exam_price}
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                {exam.publishStatus === "pending" ? (
                  <button
                    className="admin_panel_table_btns_btn2"
                    onClick={() => handleToPublish(exam?._id)}
                  >
                    Publish Now
                  </button>
                ) : null}
                {exam.publishStatus === "published" ? (
                  <button
                    className="newexambtn"
                    disabled
                  //onClick={() => approveHandler(exam?._id)}
                  >
                    Already Published
                  </button>
                ) : null}
              </div>
            </div>
          ))
        ) : (
          <p>No exams available.</p>
        )}
      </div>
    </div>
  );
};
export default ExamComponent;
