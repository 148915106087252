import React, { useEffect, useState } from "react";
import logo from "../../assets/logo2.png";
import search from "../../assets/icons/search.png";
import "../../Styles/Resource.css";
import { data } from "../../utils/data";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import loader from "../../assets/icons/loader.gif";
import Modal from "react-responsive-modal";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import baseUrl from "../../config/config";
const subjectsData = [
  {
    value: "All",
    label: "All",
  },
  {
    value: "History",
    label: "History",
  },
  {
    value: "Geography",
    label: "Geography",
  },
  {
    value: "English",
    label: "English",
  },
  {
    value: "Mathematics",
    label: "Mathematics",
  },
  {
    value: "Science",
    label: "Science",
  },
  {
    value: "Compuer Science",
    label: "Compuer Science",
  },
  {
    value: "Art",
    label: "Art",
  },
  {
    value: "Music",
    label: "Music",
  },
  {
    value: "Physical Education",
    label: "Physical Education",
  },
];
const typeOfResData = [
  {
    value: "video",
    label: "video",
  },
  {
    value: "text",
    label: "text",
  },
];
const classData = [
  {
    value: "Pre Shool",
    label: "Pre School",
  },
  {
    value: "KG",
    label: "KG",
  },
  {
    value: "1st ",
    label: "1st ",
  },
  {
    value: "2nd ",
    label: "2nd ",
  },
  {
    value: "3rd ",
    label: "3rd ",
  },
  {
    value: "4th ",
    label: "4th ",
  },
  {
    value: "5th ",
    label: "5th ",
  },
  {
    value: "6th ",
    label: "6th ",
  },
  {
    value: "7th ",
    label: "7th ",
  },
  {
    value: "8th ",
    label: "8th ",
  },
  {
    value: "9th ",
    label: "9th ",
  },
  {
    value: "10th ",
    label: "10th ",
  },
  {
    value: "11th ",
    label: "11th ",
  },
  {
    value: "12th ",
    label: "12th ",
  },
];

function Teachers() {
  const [showFitlerModal, setshowFitlerModal] = useState(false);
  const [teacherdata, setTeacherdata] = useState([]);
  const [detailModal, setDetailModal] = useState(false);
  const [formModal, setFormModal] = useState(false);
  const [rating, setRating] = useState();
  const [comment, setComment] = useState();
  const [communication, setCommunication] = useState();
  const [teachingskill, seTeachingskill] = useState();

  const users = useSelector((state) => {
    return state.users;
  });

  const navigate = useNavigate();

  const toggleModel = async () => {
    setshowFitlerModal(!showFitlerModal);
  };
  const applyFilter = async () => { };
  const notifyError = () => toast.error("Error...Please try again.");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tid, setTid] = useState();
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${baseUrl.tms}/tms/apis/v1/teachers`, {
        headers: {
          Authorization: `Bearer ${users.authToken}`,
          "Content-Type": "application/json",
          Accept: "*/*",
          app_name: "admin",
          app_version_code: "101",
        },
      })
      .then((res) => {
        const filteredData = res.data.data.filter(
          (item) => item.isadmin === false
        );
        setData(filteredData);
        console.log(filteredData);
        setLoading(false);
      })
      .catch((err) => {
        console.log("err");
        setLoading(false);
      });
  }, [formModal]);

  const handleButtonClick = (teacherId) => {
    fetchTeacherDetails(teacherId);
    setDetailModal(true);
  };
  const fetchTeacherDetails = async (teacherId) => {
    try {
      const response = await axios.get(
        `${baseUrl.tms}/tms/apis/v1/user/${teacherId}`,
        {
          headers: {
            Authorization: `Bearer ${users.authToken != null ? users.authToken : users.unauthToken
              }`,
            "Content-Type": "application/json",
            Accept: "*/*",
            app_name: "admin",
            app_version_code: "101",
          },
        }
      );
      console.log(response.data.data);
      setTeacherdata(response.data.data);
    } catch (error) {
      notifyError();
      console.error("Error fetching student details:", error);
    }
  };
  const ModalCloseHandler = async () => {
    setDetailModal(false);
  };
  const ModalCloseHandlerform = async () => {
    setFormModal(false);
  };
  const openModalHandler = (data) => {
    setTid(data); // Set the data received to the state
    setFormModal(true); // Open the modal
  };

  const sendReview = (tid) => {
    if (!rating || !comment || !communication || !teachingskill) {
      toast.error("Provide all fields !");
      return;
    }
    const data = {
      Admin_Review: {
        admin_userid: users?.userId,
        rating: rating,
        comment: comment,
        communication: communication,
        Teaching_skill: teachingskill,
        flag: true,
      },
    };
    axios
      .put(`${baseUrl.tms}/tms/apis/v1/updateAdminreviewDetails/${tid}`, data, {
        headers: {
          Authorization: `Bearer ${users.authToken}`,
          "Content-Type": "application/json",
          Accept: "*/*",
          app_name: "admin",
          app_version_code: "101",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setFormModal(false);
        }
      })
      .catch((err) => {
        notifyError();
      });
  };
  const [searchTerm, setSearchTerm] = useState("");
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredTeacher = data.filter((teacher) => {
    const fullName = `${teacher.personalDetails.first_name}`;
    return fullName.toLowerCase().includes(searchTerm.toLowerCase());
  });

  if (loading || data == null) {
    return (
      <div className="admin_panel_resource_container_loading">
        <img
          src={loader}
          className="admin_panel_resource_container_loading_img"
        />
      </div>
    );
  } else {
    return (
      <div className="admin_panel_resource_container">
        <Modal
          className="admin_panel_resource_model"
          open={formModal}
          onClose={ModalCloseHandlerform}
          center
        >
          <h1 className="admin_panel_resource_heds">Review a Teacher</h1>
          <label htmlFor="">Rating of the Teacher </label>
          <br />
          <input
            value={rating}
            onChange={(e) => {
              setRating(e.target.value);
            }}
            className="admin_panel_resource_model_inp"
            type="number"
            placeholder="Rating of the Teacher"
          />
          <br />
          <label htmlFor="">Comments for teacher </label>
          <br />
          <input
            value={comment}
            onChange={(e) => {
              setComment(e.target.value);
            }}
            className="admin_panel_resource_model_inp"
            type="text"
            placeholder="Comments for teacher"
          />
          <br />
          <label htmlFor="">Teaching Skills</label>
          <br />
          <input
            value={teachingskill}
            onChange={(e) => {
              seTeachingskill(e.target.value);
            }}
            className="admin_panel_resource_model_inp"
            type="text"
            placeholder="Teacher Teaching Skills"
          />
          <br />
          <label htmlFor="">Communication Skills</label>
          <br />
          <input
            value={communication}
            onChange={(e) => {
              setCommunication(e.target.value);
            }}
            className="admin_panel_resource_model_inp"
            type="text"
            placeholder="Communication Skills"
          />
          <button
            onClick={() => sendReview(tid)}
            className="admin_panel_resource_filter_btn"
          >
            Review
          </button>
        </Modal>
        <Modal
          center
          classNames="admin_panel_resource_model"
          open={detailModal}
          onClose={ModalCloseHandler}
        >
          <h1 className="admin_panel_resource_heds text-center">
            Teacher Details
          </h1>
          {teacherdata.map((teacher, index) => (
            <div key={index}>
              <div className="flex space-x-5  p-10">
                <div>
                  <h2 className="text-lg font-semibold mb-2">
                    Personal Details
                  </h2>
                  <p>Name: {teacher.personalDetails.first_name}</p>
                  <p>Last Name: {teacher.personalDetails.last_name}</p>
                  <p>Father's Name: {teacher.personalDetails.fathersName}</p>
                  <p>
                    Date of Birth:{" "}
                    {new Date(teacher.personalDetails.dob).toLocaleDateString()}
                  </p>
                  <p>Email: {teacher.personalDetails.email}</p>
                  <p>Phone Number: {teacher.personalDetails.phone_number}</p>
                  <p>Gender: {teacher.personalDetails.gender}</p>
                </div>
                <div>
                  <h3 className="text-lg font-semibold">Experience</h3>
                  {teacher.OnlieTeachingDeatis?.experience.map((exp, index) => (
                    <div key={index}>
                      <p>School Name: {exp.school_name}</p>
                      <p>Position: {exp.position}</p>
                      <p>Start Date: {exp.start_date}</p>
                      <p>End Date: {exp.end_date}</p>
                    </div>
                  ))}
                </div>
                <div>
                  <h3 className="text-lg font-semibold">Qualifications</h3>
                  {teacher.OnlieTeachingDeatis?.qualifications.map(
                    (qualification, index) => (
                      <div key={index}>
                        <p>Degree: {qualification.degree}</p>
                        <p>Institution: {qualification.institution}</p>
                        <p>Year: {qualification.year}</p>
                      </div>
                    )
                  )}
                </div>
              </div>
              <div className="flex space-x-8 p-10">
                <div>
                  <h2 className="text-lg font-semibold mb-2">
                    Teaching Details
                  </h2>
                  <p>
                    Teaching Mode:{" "}
                    {teacher.teachingDetails.teaching_mode.join(", ")}
                  </p>
                  <h2 className="text-lg font-semibold mb-2">
                    Online Teaching Details
                  </h2>
                  <p>
                    Availability: {teacher.OnlieTeachingDeatis.availability}
                  </p>
                  <p>
                    Teaching Languages:{" "}
                    {teacher.OnlieTeachingDeatis.teaching_languages.join(", ")}
                  </p>
                  <p>
                    Per Hour Charges: RS:
                    {teacher.OnlieTeachingDeatis.perhourcharge}
                  </p>
                </div>
                <div>
                  <h2 className="text-lg font-semibold mb-2">
                    Offline Teaching Details
                  </h2>
                  <p>
                    Availability: {teacher.OfflineTeachingDeatis.availability}
                  </p>
                  <p>
                    Teaching Languages:{" "}
                    {teacher.OfflineTeachingDeatis.teaching_languages.join(
                      ", "
                    )}
                  </p>
                  <p>
                    Per Hour Charges: RS:
                    {teacher.OfflineTeachingDeatis.priceperdistancekm}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </Modal>
        <div className="admin_panel_resource_logo_img_container flex items-center justify-between p-5">
          <img
            onClick={() => {
              navigate("/");
            }}
            className="admin_panel_resource_logo_img"
            src={logo}
            alt="logo"
          />
          <div className="bg-[#35d24e] text-white px-4 py-3 rounded-xl cursor-pointer" onClick={() => {
            navigate('/reviews')
          }}>
            All Reviews
          </div>
        </div>
        <div className="admin_panel_resource_container_cont">
          <h1 className="admin_panel_resource_hed">Teachers</h1>
          <div className="admin_panel_resource_search_bar items-center">
            <label htmlFor="inp">
              {" "}
              <img
                className="admin_panel_resource_search_bar_img"
                src={search}
                alt="logo"
              />
            </label>
            <input
              placeholder="Search Teacher Name"
              name="inp"
              id="inp"
              type="text"
              className="admin_panel_resource_search_bar_inp"
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Phone Number</th>
              <th>Email</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredTeacher.map((val, key) => (
              <tr key={key}>
                <td>{val?.personalDetails?.first_name}</td>
                <td>{val?.personalDetails.phone_number}</td>
                <td>{val?.personalDetails.email}</td>
                <td>
                  <div className="admin_panel_table_btns">
                    <button
                      className="admin_panel_table_btns_btn1"
                      onClick={() => handleButtonClick(val?.userId)}
                    >
                      View
                    </button>
                    {val.Admin_Review ? (
                      <button className="admin_panel_table_btns_btn1">
                        Reviewed
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          openModalHandler(val?.userId);
                        }}
                        className="admin_panel_table_btns_btn2"
                      >
                        Review
                      </button>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
export default Teachers;
