import React, { useEffect, useState } from "react";
import logo from "../../assets/logo2.png";
import search from "../../assets/icons/search.png";
import "../../Styles/Resource.css";
import { data } from "../../utils/data";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import { useSelector } from "react-redux";
import loader from "../../assets/icons/loader.gif"
import baseUrl from "../../config/config"
import moment from "moment";
function Classes() {
  const [batchData, setBatchData] = useState([]);
  const [loading, setLoading] = useState(false);
  const users = useSelector((state) => { return state.users });
  const [selectedMode, setselectedMode] = useState("newclass");
  useEffect(() => {
    if (users?.userId) {
      setLoading(true);
      axios
        .get(
          `${baseUrl.tms}/tms/apis/v1/abc/class/admin`
          , {
            headers: {
              Authorization: `Bearer ${users.authToken != null ? users.authToken : users.unauthToken}`,
              'Content-Type': 'application/json',
              Accept: '*/*',
              app_name: 'admin',
              app_version_code: '101',
            }
          },)
        .then((res) => {
          console.log(res.data.data)
          setBatchData(res.data.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log("Something went wrong..", err);
          setLoading(false)
        });
    }
  }, [selectedMode]);
  const navigate = useNavigate();
  if (loading || data == null) {
    return (
      <div className='admin_panel_resource_container_loading'><img src={loader} className='admin_panel_resource_container_loading_img' /></div>
    )
  } else {
    return (
      <div className="admin_panel_resource_container">
        <div className="admin_panel_resource_logo_img_container">
          <img
            onClick={() => {
              navigate("/");
            }}
            className="admin_panel_resource_logo_img"
            src={logo}
            alt="logo"
          />
        </div>
        <div className="admin_panel_resource_container_cont">
          <h1 className="admin_panel_resource_hed">Classes</h1>
        </div>
        <div className="admin_panel_resource_tabs">
          <div
            style={
              selectedMode === "newclass"
                ? { borderBottom: "3px solid #6764FF" }
                : null
            }
            onClick={() => {
              setselectedMode("newclass");
            }}
            className="admin_panel_resource_tab"
          >
            Create New Class
          </div>
          <div
            style={
              selectedMode === "createdclass"
                ? { borderBottom: "3px solid #6764FF" }
                : null
            }
            onClick={() => {
              setselectedMode("createdclass");
            }}
            className="admin_panel_resource_tab"
          >
            Created Classes
          </div>
        </div>
        {selectedMode === "newclass" ? (
          <div className="w-full flex justify-center mt-5  border-blue-500 border p-5 h-80 border-dashed">
            <div className=" text-center items-center flex justify-center ">
              <button
                onClick={() => {
                  navigate("/classes/create-class");
                }}
                className="bg-blue-500 p-2 px-2  text-white"
              >
                Create
              </button>
            </div>
          </div>
        ) : (
          <div className="mt-5 p-5 space-y-10">
            {
              batchData?.map((item, key) => (
                <div key={key} className="flex justify-between border items-center rounded-md border-blue-500 p-8">
                  <div>
                    <div className="text-xs text-gray-500">Class Name- {item?.className}</div>
                    <div className="text-3xl text-blue-500">{item?.className}</div>
                    <div className="text-sm text-gray-500">{item?.Subject}</div>
                    <div className="text-xl text-black">Language- {item?.language}</div>
                    <div className="text-blue text-xl">Start - {moment(item.startClass).format("Do MMM YY")}</div>
                  </div>
                  <div>
                    <div className="text-xl text-gray-500">Class Duration - <span className="text-3xl text-blue-500">{item?.duration}</span></div>
                  </div>
                  <div>
                    <div className="text-xs text-gray-500">Batch Type</div>
                    <div className="text-3xl text-blue-500">{item?.classType === "batch" ? "Batch Tutoring" : item?.classType === "home" ? "Home Tutoring" : "Private Tutoring"}</div>
                    <div
                      onClick={() => {
                        navigate(`/classes/${item?._id}`);
                      }}
                      className="text-xl text-white cursor-pointer bg-blue-500 text-center mt-2 rounded-xl p-2"
                    >
                      View
                    </div>

                    <div
                      onClick={() => {
                        navigate(`/addsession/${item?._id}`);
                      }}
                      className="text-xl text-white cursor-pointer bg-blue-500 text-center mt-2 rounded-xl p-2"
                    >
                      Add Session
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        )}
      </div>
    );
  }
}
export default Classes;
