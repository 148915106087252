import React, { useState, useEffect } from "react";
import logo from "../../../assets/logo2.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import baseUrl from "../../../config/config";

function SubscriptionPlansList() {
  const navigate = useNavigate();
  const [subscriptionData, setSubscriptionData] = useState([]);

  const users = useSelector((state) => {
    return state.users;
  });

  useEffect(() => {
    axios
      .get(`${baseUrl.task}/tsv/apis/v1/subscription/copylist`, {
        headers: {
          Authorization: `Bearer ${
            users.authToken != null ? users.authToken : users.unauthToken
          }`,
          "Content-Type": "application/json",
          Accept: "*/*",
          app_name: "admin",
          app_version_code: "101",
        },
      })
      .then((res) => {
        setSubscriptionData(res.data.data);
        console.log("res1", res.data.data);
      })
      .catch((err) => {
        console.log("error fetching data", err);
      });
  }, []);

  return (
    <div className="">
      <div className="admin_panel_resource_logo_img_container">
        <img
          onClick={() => {
            navigate("/");
          }}
          className="admin_panel_resource_logo_img"
          src={logo}
          alt="logo"
        />
      </div>

      <div className="m-5 mt-4">
        <div className="admin_panel_resource_container_cont">
          <h1 className="text-2xl ">Subscription Plans</h1>
        </div>

        <div className="flex mt-12 justify-between w-[75vw]">
          <table className="w-full bg-[#1C768F26] rounded-lg overflow-hidden">
            <thead className="bg-[#1C768F26]">
              <tr>
                <th className="px-5 py-4 text-left">Curriculum</th>
                <th className="px-5 py-4 text-left">Subject</th>
                <th className="px-5 py-4 text-left">Class</th>
                <th className="px-5 py-4 text-left">Target Exams</th>
                <th className="px-5 py-4 text-left">Price</th> 
                {/* <th className="px-5 py-4 text-left">Action</th> */}
              </tr>
            </thead>
            <tbody>
              {subscriptionData.length > 0 ? (
                subscriptionData?.map((item, index) => (
                  <tr key={index} className="hover:bg-gray-200">
                    <td className="px-4 py-3">{item.curriculum}</td>
                    <td className="px-4 py-3">{item.standard}</td>
                    <td className="px-4 py-3">{item.subject}</td>
                    <td className="px-4 py-3">{item.targetExams.join(', ')}</td>
                    <td className="px-4 py-3">{item.fixedPrice}</td>
                    {/* <td className="px-4 py-3 space-x-2">
                      <button
                        // onClick={() => handleOpenModal(item)}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                      >
                        View/Edit
                      </button>
                    </td> */}
                  </tr>
                ))
              ) : (
                <div className="text-3xl text-[#130F40] text-left p-5">
                  No Request found....
                </div>
              )}
            </tbody>
          </table>
        </div>
      </div>
      
    </div>
  );
}

export default SubscriptionPlansList;
