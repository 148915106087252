import React from 'react'
import logo from "../../assets/logo2.png";
import { useNavigate, useSearchParams } from "react-router-dom";
function AddTutor() {
  const navigate = useNavigate();
  return (
    <div className="admin_panel_resource_container">
      <div className="admin_panel_resource_logo_img_container">
        <img
          onClick={() => {
            navigate("/");
          }}
          className="admin_panel_resource_logo_img"
          src={logo}
          alt="logo"
        />
      </div>
      <div className="admin_panel_resource_container_cont">
        <h1 className="admin_panel_resource_hed">Class Creation</h1>
      </div>
      <div className="mt-5 items-center rounded-md bg-slate-300 border-[#CBCBCB] border-[1px] p-6">
        <div className="flex justify-between p-3 items-center">
          <div className="text-3xl font-bold ">Assign Tutor</div>
          <div className="border-[#CBCBCB] border-[2px] cursor-pointer rounded-md px-5 p-2 text-black">
            Filter
          </div>
        </div>
        <div className=" p-10 mt-5 space-y-5 bg-white rounded-lg">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th> Class</th>
                <th>Subject</th>
                <th>Rating</th>
                <th>Price</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Mr Kapoor</td>
                <td>III</td>
                <td>MATHEMATICS</td>
                <td>Rs 2500</td>
                <td>Video,MP4</td>
                <td>
                  <div className='admin_panel_table_btns space-x-2'>
                    <button className='admin_panel_table_btns_btn2'>Assign</button>
                    <button className='text-blue-500'>X</button>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Mr Kapoor</td>
                <td>III</td>
                <td>MATHEMATICS</td>
                <td>Rs 2500</td>
                <td>Video,MP4</td>
                <td>
                  <div className='admin_panel_table_btns space-x-2'>
                    <button className='admin_panel_table_btns_btn2'>Assign</button>
                    <button className='text-blue-500'>X</button>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Mr Kapoor</td>
                <td>III</td>
                <td>MATHEMATICS</td>
                <td>Rs 2500</td>
                <td>Video,MP4</td>
                <td>
                  <div className='admin_panel_table_btns space-x-2'>
                    <button className='admin_panel_table_btns_btn2'>Assign</button>
                    <button className='text-blue-500'>X</button>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Mr Kapoor</td>
                <td>III</td>
                <td>MATHEMATICS</td>
                <td>Rs 2500</td>
                <td>Video,MP4</td>
                <td>
                  <div className='admin_panel_table_btns space-x-2'>
                    <button className='admin_panel_table_btns_btn2'>Assign</button>
                    <button className='text-blue-500'>X</button>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Mr Kapoor</td>
                <td>III</td>
                <td>MATHEMATICS</td>
                <td>Rs 2500</td>
                <td>Video,MP4</td>
                <td>
                  <div className='admin_panel_table_btns space-x-2'>
                    <button className='admin_panel_table_btns_btn2'>Assign</button>
                    <button className='text-blue-500'>X</button>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Mr Kapoor</td>
                <td>III</td>
                <td>MATHEMATICS</td>
                <td>Rs 2500</td>
                <td>Video,MP4</td>
                <td>
                  <div className='admin_panel_table_btns space-x-2'>
                    <button className='admin_panel_table_btns_btn2'>Assign</button>
                    <button className='text-blue-500'>X</button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="flex justify-center mt-6">
            <button class="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-blue-500 rounded-md focus:outline-none focus:bg-gray-600">
              Save Changes
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddTutor