import React, { useEffect, useState } from 'react'
import logo from "../src/assets/logo2.png"
import search from "../src/assets/icons/search.png"
import "../src/Styles/Resource.css"
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import loader from "../src/assets/icons/loader.gif"
import Modal from 'react-responsive-modal'
import baseUrl from "../src/config/config"
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
function InstituteRequest() {
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const [detailModal, setDetailModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const users = useSelector((state) => { return state.users });
    const [studentdata, setStudentdata] = useState([]);
    const handleButtonClick = (studentId) => {
        console.log('first')
        fetchStudentDetails(studentId);
        setDetailModal(true)
    };
    const fetchStudentDetails = async (studentId) => {
        try {
            const response = await axios.get(`${baseUrl.sts}/sts/apis/v1/user/${studentId}`, {
                headers: {
                    Authorization: `Bearer ${users.authToken}`,
                    'Content-Type': 'application/json',
                    Accept: '*/*',
                    app_name: 'admin',
                    app_version_code: '101',
                }
            });
            console.log(response.data.data)
            setStudentdata(response.data.data);
        } catch (error) {
            toast.error('error while fetching the student details')
            console.error('Error fetching student details:', error);
        }
    };
    const ModalCloseHandler = async () => {
        setDetailModal(false)
    }
    useEffect(() => {
        setLoading(true);
        axios.get(`${baseUrl.ins}/institute/apis/v1/all/req/admin/ins`, {
            headers: {
                Authorization: `Bearer ${users.authToken}`,
                'Content-Type': 'application/json',
                Accept: '*/*',
                app_name: 'admin',
                app_version_code: '101',
            }
        }
        ).then((res) => {
            const filteredData = res.data.data;
            setData(filteredData);
            console.log("ffnre",filteredData)
            setLoading(false);
        }).catch((err) => {
            console.log('err');
            setLoading(false);
        })
    }, [])
    console.log(studentdata)
    const [searchTerm, setSearchTerm] = useState('');
    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredStudents = data.filter((student) => {
        const fullName = `${student.institute_name}`;
        return fullName.toLowerCase().includes(searchTerm.toLowerCase());
    });

    if (loading || data == null) {
        return (
            <div className='admin_panel_resource_container_loading'><img src={loader} className='admin_panel_resource_container_loading_img' /></div>
        )
    } else {
        return (
            <div className='admin_panel_resource_container'>
                <Modal className='admin_panel_resource_model' open={detailModal} onClose={ModalCloseHandler} center>
                    <h1 className='admin_panel_resource_heds text-center'>Requests</h1>
                    {studentdata && (
                        <div className='grid grid-cols-2 gap-4 p-5'>
                            <div className='flex flex-col'>
                                <label className='font-bold'>Name:</label>
                                <p>{studentdata?.institute_name}</p>
                            </div>
                            <div className='flex flex-col'>
                                <label className='font-bold'>Name:</label>
                                <p>{studentdata?.studentname}</p>
                            </div>
                            <div className='flex flex-col'>
                                <label className='font-bold'>Phone Number:</label>
                                <p>{studentdata?.personalDetails?.phone_number}</p>
                            </div>
                            <div className='flex flex-col'>
                                <label className='font-bold'>Gender:</label>
                                <p>{studentdata?.personalDetails?.gender}</p>
                            </div>
                            <div className='flex flex-col'>
                                <label className='font-bold'>Class:</label>
                                <p>{studentdata?.educationDetails?.class}</p>
                            </div>
                            <div className='flex flex-col'>
                                <label className='font-bold'>Board:</label>
                                <p>{studentdata?.educationDetails?.Board}</p>
                            </div>
                            {/* Add more fields as needed */}
                        </div>
                    )}
                </Modal>
                <div className='admin_panel_resource_logo_img_container flex justify-between items-center'>
                    <img onClick={() => { navigate("/") }} className='admin_panel_resource_logo_img' src={logo} alt="logo" />
                    <div className="bg-[#35d24e] text-white px-4 py-3 rounded-xl cursor-pointer" onClick={() => {
                        navigate('/switchStudentRequests')
                    }}>
                        Switch Institute Student Requests
                    </div>
                    <div className="bg-[#35d24e] text-white px-4 py-3 rounded-xl cursor-pointer" onClick={() => {
                        navigate('/institute/request/resouce')
                    }}>
                        Institute Resource Request
                    </div>
                </div>
                <div className="w-full px-2 bg-white">
                    <div className="flex  justify-between items-center">
                        <h1 className='admin_panel_resource_hed'>Student Requests</h1>
                        <div className='admin_panel_resource_search_bar items-center w-1/2'>
                            <label htmlFor='inp'> <img className='admin_panel_resource_search_bar_img' src={search} alt="logo" /></label>
                            <input placeholder='Search Student Name' name='inp' id='inp' type="text" className='admin_panel_resource_search_bar_inp' value={searchTerm}
                                onChange={handleSearch} />
                        </div>
                    </div>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Name</th>
                            <th>Class</th>
                            <th>Subject</th>
                            <th>Institute</th>
                            {/* <th>Batch Name</th> */}
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            filteredStudents.map((item, key) => (
                                <tr key={key}>
                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                        <div className="text-sm leading-5 text-gray-900">
                                            {key + 1}
                                        </div>
                                    </td>
                                    {console.log(item)}
                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                        <div className="flex items-center">
                                            <div className="text-sm leading-5 font-medium text-gray-900">
                                                {item?.studentname}
                                            </div>
                                        </div>
                                    </td>

                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                        <div className="text-sm leading-5 text-gray-900">
                                            {item?.standard}
                                        </div>
                                    </td>

                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                        <span className="px-2 inline-flex leading-5 font-semibold rounded-full">
                                            {item?.subject}
                                        </span>
                                    </td>

                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                                        {item?.institute_name}
                                    </td>
                                    <td>
                                        <div className='admin_panel_table_btns'>
                                            <button onClick={() => navigate('/institute/request/detail',{ state:item})} className='admin_panel_table_btns_btn1'>View</button>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}
export default InstituteRequest
