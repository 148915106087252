import baseUrl from "../../config/config";

export function RazorPayment(data) {
    return new Promise(async (resolve, reject) => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            if (data.status) {
                const options = {
                    key: "rzp_live_PdkJYmMqiVWyB4",
                    amount: data.amount.toString(),
                    currency: data.currency,
                    name: "Intelli Edtech",
                    description: "Pay and Buy Course.",
                    image: "https://www.digitallearningstore.in/assets/img/dls.png",
                    order_id: data.orderId,
                    handler: async function (res) {
                        var myHeaders = new Headers();
                        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                        var urlencoded = new URLSearchParams();
                        urlencoded.append("razorpay_payment_id", res.razorpay_payment_id);
                        urlencoded.append("razorpay_order_id", res.razorpay_order_id);
                        urlencoded.append("razorpay_signature", res.razorpay_signature);

                        var requestOptions = {
                            method: "POST",
                            headers: myHeaders,
                            body: urlencoded,
                            redirect: "follow",
                        };
                        const response = await fetch(
                            `${baseUrl.Razor}/api/payment/link/validate`,
                            requestOptions
                        );
                        const results = await response.json();
                        console.log('results', results);
                        if (results.status) {
                            resolve({
                                status: 1,
                                message: results.message,
                                txn_status: results.txn_status
                            });
                        } else {
                            reject({
                                status: 0,
                                message: results.message,
                            });
                        }
                    },
                    prefill: {
                        name: data.name,
                        // email: data.email,
                        contact: data.mobileNumber,
                    },
                    theme: {
                        color: "#ff7316",
                    },
                };
                const rzp1 = new window.Razorpay(options);
                rzp1.open();
            }
        } catch (error) {
            reject({
                status: 0,
                message: error.message,
            });
        }
    });
}