import React, { useEffect, useState } from "react";
import logo from "../../assets/logo2.png";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { FaFilePdf } from "react-icons/fa";
import axios from "axios";
import loader from "../../assets/icons/loader.gif";
import baseUrl from "../../config/config"
import { useSelector } from "react-redux";
function SessionDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);
  const [flag, setFlag] = useState(false);
  const users = useSelector((state) => { return state.users });
  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${baseUrl.tms}/tms/apis/v1/session/${id}`, {
        headers: {
          Authorization: `Bearer ${users.authToken}`,
          'Content-Type': 'application/json',
          Accept: '*/*',
          app_name: 'admin',
          app_version_code: '101',
        }
      }
      )
      .then((res) => {
        setData(res.data.data);
        console.log(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("unable to get session details", err);
      });
  }, [flag]);

  const handleJoinSession = () => {
    // console.log(roomName)
    const roomName = id;
    navigate(`/meetsessions/${roomName}`);
  };


  if (loading || data == null) {
    return (
      <div className="admin_panel_resource_container_loading">
        <img
          src={loader}
          className="admin_panel_resource_container_loading_img"
        />
      </div>
    );
  } else {
    return (
      <div className="admin_panel_resource_container">
        <div className="admin_panel_resource_logo_img_container">
          <img
            onClick={() => {
              navigate("/");
            }}
            className="admin_panel_resource_logo_img"
            src={logo}
            alt="logo"
          />
        </div>
        <div className="admin_panel_resource_container_cont">
          <h1 className="admin_panel_resource_hed">Chapter Details</h1>
        </div>
        <div className="space-y-5">
          <div className="flex justify-between border items-center rounded-md border-blue-500 p-6">
            <div className="p-2">
              <div className="text-gray-500 text-3xl">
                {data?.sessionName}
              </div>
            </div>
            <div className="p-2"></div>
          </div>
          <div className="flex justify-between border items-center rounded-md border-blue-500 p-6">
            <div className="p-2">
              <div className="text-gray-500 text-xl">
                {data?.description}
              </div>
            </div>
            <div className="p-2"></div>
          </div>
                     
          <div className="flex justify-center bg-blue-500 text-white p-5 cursor-pointer"  onClick={handleJoinSession}>
            Join Now
          </div>
        </div>
      </div>
    );
  }
}
export default SessionDetails;
