import React, { useState } from "react";
import logo from "../../../assets/logo2.png";
import { useNavigate } from "react-router-dom";
import VerifyIcon from "../../../assets/verifyIcon.png";

const studentRequestsData = [
  {
    id: 1,
    studentId: "STD1234",
    standard: "X",
    studentname: "Virat Kohli",
    currentInstitute: "FIITJEE",
    switchTo: "Unacademy",
  },
  // {
  //   id: 2,
  //   studentId: "STD1234",
  //   standard: "X",
  //   studentname: "Virat Kohli",
  //   currentInstitute: "FIITJEE",
  //   switchTo: "Unacademy",
  // },
];

function VerifyStudentRequests() {
  const navigate = useNavigate();
  const [selectedButton, setSelectedButton] = useState("subjective");

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
  };

  return (
    <div className="admin_panel_resource_container">
      <div className="admin_panel_resource_logo_img_container">
        <img
          onClick={() => {
            navigate("/");
          }}
          className="admin_panel_resource_logo_img"
          src={logo}
          alt="logo"
        />
      </div>

      <div className="m-5 mt-4">
        <div className="admin_panel_resource_container_cont">
          <h1 className="text-2xl ">Switch Institute Student Requests</h1>
        </div>

        {studentRequestsData && studentRequestsData.length > 0 ? (
          <>
            <div className="flex mt-4 justify-between w-[73vw]">
              <table className="w-full bg-[#9bd4e3] rounded-lg overflow-hidden">
                <thead>
                  <tr>
                    <th className="px-4 py-3">Student ID</th>
                    <th className="px-4 py-3">Class</th>
                    <th className="px-4 py-3">Name</th>
                    <th className="px-4 py-3">Current Institute</th>
                    <th className="px-4 py-3">Switch to</th>
                    <th className="px-4 py-3">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {studentRequestsData.map((item, index) => (
                    <tr key={index} className="hover:bg-blue-300 max-w-92">
                      <td className="px-4 py-3">{item.studentId}</td>
                      <td className="px-4 py-3">{item.standard}</td>
                      <td className="px-4 py-3">{item.studentname}</td>
                      <td className="px-4 py-3">{item.currentInstitute}</td>
                      <td className="px-4 py-3">{item.switchTo}</td>
                      <td className="px-2 py-3">
                        <div className="flex items-start justify-start">
                          <img
                            src={VerifyIcon}
                            alt="Verify"
                            className="cursor-pointer"
                          />
                          <div
                            className="bg-[#FA991C] hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded cursor-pointer ml-2"
                            onClick={() => {}}
                          >
                            <div className="text-center">View Details</div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <div className="text-3xl text-[#130F40] text-left p-5">
            No Students found....
          </div>
        )}
      </div>
    </div>
  );
}

export default VerifyStudentRequests;
