import React, { useEffect, useState } from 'react'
import loader from "../../assets/icons/loader.gif"
import logo from "../../assets/logo2.png";
import { useSelector } from 'react-redux';
import axios from 'axios';
import baseUrl from "../../config/config"
import { useNavigate } from 'react-router-dom';
function Reviews() {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate=useNavigate()
  const users = useSelector((state) => { return state.users });

  useEffect(() => {
    setLoading(true);
    axios.get(`${baseUrl.tms}/tms/apis/v1/admin/${users.userId}`,{
      headers: {
        Authorization: `Bearer ${users.authToken}`,
        'Content-Type': 'application/json',
        Accept: '*/*',
        app_name: 'admin',
        app_version_code: '101',
      }
    }).then((res) => {
      setData(res.data.data);
      console.log(res.data.data)
      setLoading(false);
    }).catch((err) => {
      console.log('err');
      setLoading(false);
    })
  }, [])
  if (loading || data == null) {
    return (
      <div className='admin_panel_resource_container_loading'><img src={loader} className='admin_panel_resource_container_loading_img' /></div>
    )
  } else {
    return (
      <div className='admin_panel_resource_container'>
        <div className="admin_panel_resource_logo_img_container">
          <img
            onClick={() => {
              navigate("/");
            }}
            className="admin_panel_resource_logo_img"
            src={logo}
            alt="logo"
          />
        </div>
        <div className="admin_panel_resource_container_cont">
          <h1 className="admin_panel_resource_hed">Admin Reviews</h1>
        </div>
        <div className='space-y-5'>
          {
            data?.map((item, key) => (
              <div key={key} className="border rounded-md border-blue-500 p-8 flex justify-between items-center">
                <div className="flex flex-col">
                  <div className="text-2xl text-gray-500">Name: {item?.personalDetails?.first_name}</div>
                  <div className="text-3xl text-blue-500">Rating: {item?.Admin_Review?.rating}</div>
                  <div className="text-xl text-black">Teaching Skills: {item?.Admin_Review?.Teaching_skill}</div>
                  <div className="text-3xl text-gray-500">Comments: {item?.Admin_Review?.comment}</div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    )
  }
}
export default Reviews;
