import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo2.png";
import Select from "react-select";
import axios from "axios";
import { useSelector } from "react-redux";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import loader from "../../assets/icons/loader.gif"
import { toast } from "react-toastify";
import search from "../../assets/icons/search.png"
import baseUrl from "../../config/config"
import { modeData, classData } from "../../constants/constant"
function ClassCreation() {
  const { classOptions, languageOptions, curriculumOptions, subjectOptions } = useSelector(state => state.options);
  const navigate = useNavigate();
  const [openteacher, setOpenteacher] = useState(false);
  const [loading, setLoading] = useState(false);
  const [teachers, setTeachers] = useState([]);
  const users = useSelector((state) => { return state.users });

  const [curriculum, setCurriculum] = useState([]);
  const [grade, setGrade] = useState([]);
  const [subject, setSubject] = useState([]);
  const [language, setLanguage] = useState([]);

  const [data, setData] = useState({
    "creatorUserId": users?.userId,
    "teacherId": [],
    "subject": "",
    "className": "",
    "curriculum": "",
    "curriculumv": "",
    "grade": "",
    "gradev": "",
    "language": "",
    "classType": "",
    "startClass": "",
    "endClass": "",
    "duration": 0,
    "howManyHours": 0,
    "perUnitCharge": 0,
    "wallet": 0,
  })

  const getCurriculum = async () => {
    try {
      const response = await axios.get(`${baseUrl.Url}/v1/exam/getExams`);
      if (response.data.success) {
        const curriculumOptions = response.data.data.map(item => ({
          value: item._id,
          label: item.examName
        }));
        setCurriculum(curriculumOptions);
      }
      else {
        toast.error('Curriculum not found');
      }
    } catch (error) {
      console.error('Error Curriculum :', error);
      toast.error('Failed to fetch Curriculum. Please try again.');
    }
  }

  const getGrade = async () => {
    try {
      const response = await axios.get(`${baseUrl.Url}/v1/grade/getgrades/${data.curriculumv}`, {
        headers: {
          'Authorization': `Bearer ${users.authToken}`
        }
      });
      if (response.data.success) {
        const gradeOptions = response.data.data.map(item => ({
          value: item._id,
          label: item.gradeName
        }));
        setGrade(gradeOptions);
      } else {
        toast.error('Grades not found');
      }
    } catch (error) {
      console.error('Error fetching grades:', error);
      toast.error('Failed to fetch grades. Please try again.');
    }
  };

  const getSubject = async (id) => {
    try {
      const response = await axios.get(`${baseUrl.Url}/v1/subject/getSubjects/${data.gradev}`, {
        headers: {
          'Authorization': `Bearer ${users.authToken}`
        }
      });
      if (response.data.success) {

        const subjectOptions = response.data.data.map(item => ({
          value: item._id,
          label: item.subjectName
        }));
        setSubject(subjectOptions);
      } else {
        toast.error('Subjects not found');
      }
    } catch (error) {
      console.error('Error fetching subjects:', error);
      toast.error('Failed to fetch subjects. Please try again.');
    }
  };

  const getLanguage = async () => {
    try {
      const response = await axios.get(`${baseUrl.Url2}language/getAll`);
      if (response.status === 200) {
        setLanguage(response.data);
      } else {
        toast.error('Languages not found');
      }
    } catch (error) {
      console.error('Error fetching languages:', error);
      toast.error('Failed to fetch languages. Please try again.');
    }
  };

  useEffect(() => {
    getCurriculum();
    getLanguage();
  }, [])

  useEffect(() => {
    getGrade();
  }, [data.curriculumv])

  useEffect(() => {
    getSubject();
  }, [data.gradev])

  useEffect(() => {
    setLoading(true);
    axios.get(`${baseUrl.tms}/tms/apis/v1/teachers`, {
      headers: {
        Authorization: `Bearer ${users.authToken}`,
        'Content-Type': 'application/json',
        Accept: '*/*',
        app_name: 'admin',
        app_version_code: '101',
      }
    }).then((data) => {
      const filteredData = data.data.data.filter(item => item.isadmin === false);
      setTeachers(filteredData);
      console.log(filteredData);
      setLoading(false);
    }).catch((err) => {
      console.log('error finding the teacher', err);
      setLoading(false);
    })
  }, [])
  const notifyError = () => toast.error('Error.... Please try again.');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const [searchTerm, setSearchTerm] = useState('');
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };
  const filteredTeacher = teachers.filter((teacher) => {
    const fullName = `${teacher.personalDetails.first_name}`;
    return fullName.toLowerCase().includes(searchTerm.toLowerCase());
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    if (data.wallet.length === 0 || data.classType.length === 0 || data.startClass === null || data.endClass === null) {
      notifyError('Please fill in all required fields.');
      return;
    }
    setLoading(true);
    axios.post(`${baseUrl.tms}/tms/apis/v1/classes`, data, {
      headers: {
        Authorization: `Bearer ${users.authToken}`,
        'Content-Type': 'application/json',
        Accept: '*/*',
        app_name: 'admin',
        app_version_code: '101',
      }
    }).then((res) => {
      if (res.status === 200) {
        console.log('res', res.data);
        setLoading(false);
        console.log('res?.data?.data?.batch_id', res?.data?.data?._id);
        navigate(`/classes/create-class/addstudent/${res?.data?.data?._id}`);
      }
    }).catch((err) => {
      console.log('error while creating the batch', err);
      setLoading(false);
    })
  }
  if (loading || data == null) {
    return (
      <div className='admin_panel_resource_container_loading'><img src={loader} className='admin_panel_resource_container_loading_img' /></div>
    )
  } else {
    return (
      <div className="admin_panel_resource_container">
        <div className="admin_panel_resource_logo_img_container">
          <img
            onClick={() => {
              navigate("/");
            }}
            className="admin_panel_resource_logo_img"
            src={logo}
            alt="logo"
          />
        </div>

        <form onSubmit={handleSubmit}>
          <div className="border border-1 bg-[#7D68E2] overflow-y-scroll h-screen mt-14 rounded-3xl"
            style={{ scrollbarWidth: 'none', '-ms-overflow-style': 'none' }}>
            <div className="p-4 text-2xl text-white mt-2">Create New Class</div>
            <div className="bg-white grid grid-cols-1 gap-6 my-4 sm:grid-cols-2 mx-3 p-5 rounded-md">
              <div>
                <label className="text-[#4A3794]" htmlFor="username">
                  Classname
                </label>
                <input
                  id="username"
                  type="text"
                  required={true}
                  className="block w-full px-4 py-2 mt-2 text-black bg-white border border-[#4A3794] rounded-md  focus:outline-none focus:ring placeholder:text-slate-500"
                  placeholder="Class Name"
                  onChange={(e) => setData({ ...data, className: e.target.value })}
                />
              </div>
              <div className="mt-1">
                <label className="text-[#4A3794]" htmlFor="username">
                  Curriculum
                </label>
                <Select
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      // width: "max-content",
                      minWidth: "300px",
                      borderColor: "grey",
                      height: "48px",
                      cursor: "pointer",
                    }),
                    dropdownIndicator: (baseStyles, state) => ({
                      ...baseStyles,
                      color: "#6B4EFF",
                    }),
                  }}
                  placeholder="curriculum"
                  options={curriculum}
                  onChange={(selectedOption) =>
                    setData({ ...data, curriculumv: selectedOption.value, curriculum: selectedOption.label })
                  }
                />
              </div>
              <div>
                <div className="space-y-2">
                  <label className="text-[#4A3794]" htmlFor="username">
                    Grade
                  </label>
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        // width: "max-content",
                        minWidth: "300px",
                        borderColor: "grey",
                        height: "48px",
                        cursor: "pointer",
                      }),
                      dropdownIndicator: (baseStyles, state) => ({
                        ...baseStyles,
                        color: "#6B4EFF",
                      }),
                    }}
                    placeholder="Grade"
                    options={grade}
                    onChange={(selectedOption) =>
                      setData({ ...data, gradev: selectedOption.value, grade: selectedOption.label })
                    }
                  />
                </div>
              </div>
              <div>
                <div className="space-y-2">
                  <label className="text-[#4A3794]" htmlFor="username">
                    Subject
                  </label>
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        // width: "max-content",
                        minWidth: "300px",
                        borderColor: "grey",
                        height: "48px",
                        cursor: "pointer",
                      }),
                      dropdownIndicator: (baseStyles, state) => ({
                        ...baseStyles,
                        color: "#6B4EFF",
                      }),
                    }}
                    placeholder="Subject"
                    options={subject}
                    onChange={(selectedOption) =>
                      setData({ ...data, subjectv: selectedOption.value, subject: selectedOption.label })
                    }
                  />
                </div>
              </div>
              <div>
                <div className="space-y-2">
                  <label className="text-[#4A3794]" htmlFor="username">
                    Language
                  </label>
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        // width: "max-content",
                        minWidth: "300px",
                        borderColor: "grey",
                        height: "48px",
                        cursor: "pointer",
                      }),
                      dropdownIndicator: (baseStyles, state) => ({
                        ...baseStyles,
                        color: "#6B4EFF",
                      }),
                    }}
                    placeholder="Language"
                    options={language}
                    onChange={(selectedOption) =>
                      setData({ ...data, language: selectedOption.value })
                    }
                  />
                </div>
              </div>
              <div className="space-y-2">
                <label className="text-[#4A3794]" htmlFor="username">
                  Class Type
                </label>
                <Select
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      // width: "max-content",
                      minWidth: "300px",
                      borderColor: "grey",
                      height: "48px",
                      cursor: "pointer",
                    }),
                    dropdownIndicator: (baseStyles, state) => ({
                      ...baseStyles,
                      color: "#6B4EFF",
                    }),
                  }}
                  placeholder="class type"
                  options={classData}
                  onChange={(selectedOption) =>
                    setData({ ...data, classType: selectedOption.value })
                  }
                />
              </div>
              <div className="space-x-5">
                <label className="text-[#4A3794]" htmlFor="username">
                  Duration (In Days)
                </label>
                <input
                  type="number"
                  name="duration"
                  placeholder="Class Duration in (Days)"
                  value={data?.duration}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="space-x-5">
                <label className="text-[#4A3794]" htmlFor="username">
                  Duration (In Hrs)
                </label>
                <input
                  type="number"
                  name="howManyHours"
                  placeholder="Class Duration in (HR)"
                  value={data?.howManyHours}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="space-x-5">
                <label className="text-[#4A3794]" htmlFor="username">
                  Per Session Charge (In Hrs)
                </label>
                <input
                  type="number"
                  name="perUnitCharge"
                  placeholder="Per Unit Charge"
                  value={data?.perUnitCharge}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="space-x-5">
                <label className="text-[#4A3794]" htmlFor="username">
                  Class Start Date
                </label>
                <DatePicker
                  selected={data?.startClass}
                  onChange={(date) => setData({ ...data, startClass: date })}
                  className="border p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
                  timeIntervals={15}
                  placeholderText="Batch Start Date"
                  dateFormat="MMMM d, yyyy"
                  timeCaption="Time"
                />
              </div>

              <div className="space-x-5">
                <label className="text-[#4A3794]" htmlFor="username">
                  Class End Date
                </label>
                <DatePicker
                  selected={data?.endClass}
                  onChange={(date) => setData({ ...data, endClass: date })}
                  className="border p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
                  timeIntervals={15}
                  placeholderText="Batch End Date"
                  dateFormat="MMMM d, yyyy"
                  timeCaption="Time"
                />
              </div>
              <div className="mt-1">
                <label className="text-[#4A3794]" htmlFor="username">
                  Student Charges
                </label>
                <input
                  id="username"
                  type="number"
                  className="block w-full px-4 py-2 mt-2 text-black bg-white border border-[#4A3794] rounded-md  focus:outline-none focus:ring placeholder:text-slate-500"
                  placeholder="Student Charges In (Rs)"
                  required={true}
                  onChange={(e) => setData({ ...data, wallet: e.target.value })}
                />
              </div>
              {/* <div className="mt-1">
                <label className="text-[#4A3794]" htmlFor="username">
                  Teacher Charges
                </label>
                <input
                  id="username"
                  type="number"
                  className="block w-full px-4 py-2 mt-2 text-black bg-white border border-[#4A3794] rounded-md  focus:outline-none focus:ring placeholder:text-slate-500"
                  placeholder="Teacher Charges In (Rs)"
                  required={true}
                  onChange={(e) => setData({ ...data, teacherCharges: e.target.value })}
                />
              </div> */}

            </div>
          </div>
          <div className="mt-10">
            <div
              className="text-white bg-[#4A3794] p-4 rounded-md text-center"
              htmlFor="username"
              onClick={() => setOpenteacher(!openteacher)}
            >
              Add Teacher
            </div>
          </div>
          {openteacher &&
            <div className="mt-5 items-center rounded-md bg-slate-300 border-[#CBCBCB] border-[1px] p-6">
              <div className="flex justify-between p-3 items-center">
                <div className="text-3xl font-bold ">Assign Tutor</div>
                <div className='flex space-x-3 items-center'>
                  <label htmlFor='inp'> <img className='admin_panel_resource_search_bar_img' src={search} alt="logo" /></label>
                  <input placeholder='Search Teacher Name' name='inp' id='inp' type="text" className='h-10 w-80' value={searchTerm}
                    onChange={handleSearch} />
                </div>
              </div>
              <div className=" p-10 mt-5 space-y-5 bg-white rounded-lg">
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Subject</th>
                      <th>Price</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      teachers?.map((item, key) => (
                        <tr key={key}>
                          <td>{item?.personalDetails?.first_name}</td>
                          <td>
                            {item?.teachingDetails?.subjects_taught?.map((subjectItem, index) => (
                              <span key={index}>
                                {subjectItem.subject}
                                {index !== item.teachingDetails.subjects_taught.length - 1 && ', '}
                              </span>
                            ))}
                          </td>
                          <td>{`RS: ${item?.OnlieTeachingDeatis?.perhourcharge}`}</td>
                          <td>
                            <div className='admin_panel_table_btns space-x-2'>
                              {
                                (data?.teacherId.includes(item?.userId)) ? (
                                  <button className='admin_panel_table_btns_btn1 bg-red-500'>Assigned</button>
                                ) : (
                                  <button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setData(prevData => ({ ...prevData, teacherId: item?.userId }))
                                    }}
                                    className='admin_panel_table_btns_btn2'
                                  >
                                    Assign
                                  </button>
                                )
                              }
                            </div>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
          }
          <div className="flex justify-center mt-6">
            <button className="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-blue-500 rounded-md focus:outline-none focus:bg-gray-600">
              Create Batch
            </button>
          </div>
        </form>
      </div>
    );
  }
}
export default ClassCreation;
