import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setExpiryDate,
  setauthToken,
  setisLoggedin,
  setunauthToken,
  setuserId,
} from "../redux/reducers/userSlice";
import logo from "../assets/logo1.png";
import "../Styles/SideBar.css";

function Sidebar({ activeContent }) {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);

  const logoutHandler = async () => {
    dispatch(setuserId(null));
    dispatch(setExpiryDate(null));
    dispatch(setunauthToken(null));
    dispatch(setauthToken(null));
    dispatch(setisLoggedin(false));
  };

  const [showSampleMasterDataSubcategories, setShowSampleMasterDataSubcategories] = useState(false);
  const [activeSampleMasterData, setActiveSampleMasterData] = useState(null);
  const [activeMainCategory, setActiveMainCategory] = useState(activeContent);

  const toggleSampleMasterDataSubcategories = () => {
    const newVisibility = !showSampleMasterDataSubcategories;
    setShowSampleMasterDataSubcategories(newVisibility);
    if (newVisibility) {
      handleSubCategoryClick("Curriculum");
    } else {
      setActiveSampleMasterData(null); // Reset active sub-category when closing
    }
    setActiveMainCategory("samplemasterdata"); // Set active main category to Sample Master Data
  };

  const handleSubCategoryClick = (content) => {
    setActiveSampleMasterData(content);
    setShowSampleMasterDataSubcategories(true); // Always show sub-categories when a sub-category is clicked
    setActiveMainCategory("samplemasterdata"); // Set active main category to Sample Master Data
    navigation(`/${content.toLowerCase()}`);
  };

  const renderSubCategory = (content) => (
    <div
      key={content}
      className="sideBar_item_name"
      onClick={() => handleSubCategoryClick(content)}
      style={
        activeSampleMasterData === content
          ? { backgroundColor: "rgb(73 48 200)" }
          : { backgroundColor: "rgb(100, 100, 200)" } // Highlight sub-categories
      }
    >
      {content}
    </div>
  );

  return (
    <div className="sideBar_container">
      <div className="sideBar_heading">
        <img src={logo} className="sideBar_logo" alt="logo" />
      </div>
      <div className="sideBar_container_body">
        {["Home", "Resources", "Classes"].map((item) => (
          <div
            key={item}
            className="sideBar_item_name"
            onClick={() => {
              navigation(`/${item.toLowerCase()}`);
              setActiveMainCategory(item);
            }}
            style={
              activeMainCategory === item
                ? { backgroundColor: "rgb(73 48 200)" }
                : null
            }
          >
            {item}
          </div>
        ))}
        {users && users.usertype && (
          <>
            {(users.usertype === "S_Admin" || users.usertype === "O_Manager") && (
              <>
                <div
                  className="sideBar_item_name"
                  onClick={() => {
                    navigation("/class-resources");
                    setActiveMainCategory("Class Resources");
                  }}
                  style={
                    activeMainCategory === "Class Resources"
                      ? { backgroundColor: "rgb(73 48 200)" }
                      : null
                  }
                >
                  Class Resources
                </div>
                <div
                  className="sideBar_item_name"
                  onClick={() => {
                    navigation("/assignment");
                    setActiveMainCategory("Class Assignment");
                  }}
                  style={
                    activeMainCategory === "Class Assignment"
                      ? { backgroundColor: "rgb(73 48 200)" }
                      : null
                  }
                >
                  Assignment
                </div>
                <div
                  className="sideBar_item_name"
                  onClick={() => {
                    navigation("/students");
                    setActiveMainCategory("Students");
                  }}
                  style={
                    activeMainCategory === "Students"
                      ? { backgroundColor: "rgb(73 48 200)" }
                      : null
                  }
                >
                  Students
                </div>
                <div
                  className="sideBar_item_name"
                  onClick={() => {
                    navigation("/teachers");
                    setActiveMainCategory("Teachers");
                  }}
                  style={
                    activeMainCategory === "Teachers"
                      ? { backgroundColor: "rgb(73 48 200)" }
                      : null
                  }
                >
                  Teachers
                </div>
              </>
            )}
            {["Exams", "Institute Requests", "Generate Payment", "Master Data"].map((item) => (
              <div
                key={item}
                className="sideBar_item_name"
                onClick={() => {
                  navigation(`/${item.toLowerCase().replace(" ", "")}`);
                  setActiveMainCategory(item.toLowerCase().replace(" ", ""));
                }}
                style={
                  activeMainCategory === item.toLowerCase().replace(" ", "")
                    ? { backgroundColor: "rgb(73 48 200)" }
                    : null
                }
              >
                {item}
              </div>
            ))}
            <div
              className="sideBar_item_name"
              onClick={toggleSampleMasterDataSubcategories}
              style={
                activeMainCategory === "samplemasterdata"
                  ? { backgroundColor: "rgb(73 48 200)", border: "2px solid blue" }
                  : { border: "2px solid transparent" }
              }
            >
              Sample Master Data
            </div>
            {showSampleMasterDataSubcategories && (
              <div className="sideBar_subcategories_container">
                {["Curriculum", "Grade", "Subject", "Unit", "Topic", "Subtopic", "Language"].map((item) =>
                  renderSubCategory(item)
                )}
              </div>
            )}
            {["Copy Check", "Assignment Check", "Subscription"].map((item) => (
              <div
                key={item}
                className="sideBar_item_name"
                onClick={() => {
                  navigation(`/${item.toLowerCase().replace(" ", "")}`);
                  setActiveMainCategory(item.toLowerCase().replace(" ", ""));
                }}
                style={
                  activeMainCategory === item.toLowerCase().replace(" ", "")
                    ? { backgroundColor: "rgb(73 48 200)" }
                    : null
                }
              >
                {item}
              </div>
            ))}
            {users.usertype === "S_Admin" && (
              <div
                className="sideBar_item_name"
                onClick={() => {
                  navigation("/Setting");
                  setActiveMainCategory("Setting");
                }}
                style={
                  activeMainCategory === "Setting"
                    ? { backgroundColor: "rgb(73 48 200)" }
                    : null
                }
              >
                Settings
              </div>
            )}
          </>
        )}
        <div
          className="sideBar_item_name"
          onClick={logoutHandler}
          style={{ color: "red" }}
        >
          Logout
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
