import React, { useEffect, useState } from "react";
import logo from "../../../assets/logo2.png";
import loader from "../../../assets/icons/loader.gif";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
function AssignmentCheckStudentList() {
  const navigate = useNavigate();
  const params = useLocation().state;
  const studentList = params.studentList;
  const [loading, setLoading] = useState(false);
  const [examIdList, setExamIdList] = useState([]);

  const users = useSelector((state) => {
    return state.users;
  });

  if (loading) {
    return (
      <div className="admin_panel_resource_container_loading">
        <img
          src={loader}
          className="admin_panel_resource_container_loading_img"
        />
      </div>
    );
  } else {
    return (
      <div className="admin_panel_resource_container">
        <div className="admin_panel_resource_logo_img_container">
          <img
            onClick={() => {
              navigate("/");
            }}
            className="admin_panel_resource_logo_img"
            src={logo}
            alt="logo"
          />
        </div>

        <div className="flex justify-between m-2 mt-4">
          <div className="admin_panel_resource_container_cont">
            <h1 className="text-2xl">Assignment Checking</h1>
          </div>
        </div>

        {studentList?.length > 0 ? (
          <>
            <div className="flex mt-4 justify-between w-[75vw] mx-2">
              {console.log("studentList.submitAssignmentId", studentList)}
              <table className="w-full bg-[#9bd4e3] font-semibold rounded-lg overflow-hidden">
                <thead className="bg-[#B1D9E4]">
                  <tr>
                    <th className="px-5 py-4 text-left">Student ID</th>
                    <th className="px-5 py-4 text-left">Name</th>
                    <th className="px-5 py-4 text-left">Exam</th>
                    <th className="px-5 py-4 text-left">Date of Submission</th>
                    {studentList?.length > 1 ? (
                      <th className="px-5 py-4 text-left">Select</th>
                    ) : null}
                    <th className="px-5 py-4 text-left">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {studentList
                    ?.filter(
                      (item, index) =>
                        item.isassigntoadmin === true &&
                        !item.isassignto_teacher
                    )
                    .map((item, index) => {
                      return (
                        <tr
                          key={item.submitAssignmentId._id}
                          className="hover:bg-blue-300"
                        >
                          <td className="px-4 py-3">
                            {item?.submitAssignmentId?.student?.Id}
                          </td>
                          <td className="px-4 py-3">
                            {item?.submitAssignmentId?.student?.Name}
                          </td>
                          <td className="px-4 py-3">{params?.examName}</td>
                          <td className="px-4 py-3">
                            {moment(
                              item?.submitAssignmentId?.submissionDateTime
                            ).format("D MMM YYYY")}
                          </td>
                            {studentList?.filter((item) => item.isassigntoadmin === true && !item.isassignto_teacher).length > 1 ? (
                            <td className="px-4 py-3">
                              <input
                                type="checkbox"
                                className="w-6 h-6"
                                onChange={(event) => {
                                  const isChecked = event.target.checked;

                                  setExamIdList((prevList) => {
                                    if (isChecked) {
                                      return [
                                        ...prevList,
                                        item?.submitAssignmentId?._id,
                                      ];
                                    } else {
                                      return prevList.filter(
                                        (listItem) =>
                                          listItem !==
                                          item?.submitAssignmentId?._id
                                      );
                                    }
                                  });
                                }}
                              />
                            </td>
                          ) : null}

                          <td className="px-4 py-3 space-x-2">
                            <button
                              // onClick={viewstudent}
                              onClick={() => {
                                console.log("pp", params);
                                navigate("/assign-check-teacher", {
                                  state: {
                                    examSubmitIds: [
                                      item?.AssignmentSubmit?._id,
                                    ],
                                    examName: params.examName,
                                    subTopic: params.subTopic,
                                    subject: params.subject,
                                    examDate: params.examDate,
                                    topic: params.topic,
                                    assignId: params.assignId,
                                  },
                                });
                              }}
                              className="bg-[#FA991C] hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded items-center justify-center"
                            >
                              Assign to Teacher
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <div className="text-3xl text-[#130F40] text-left p-5">
            No Students found....
          </div>
        )}
        {examIdList?.length > 0 ? (
          <div
            className="bg-[#1C768F] my-7 p-3 w-[50vw] items-center self-center"
            onClick={(e) => {
              e.preventDefault();
              console.log("dd", examIdList);
              navigate("/assign-check-teacher", {
                state: {
                  examSubmitIds: examIdList,
                  examName: params.examName,
                  subTopic: params.subTopic,
                  subject: params.subject,
                  examDate: params.examDate,
                  topic: params.topic,
                  assignId: params.assignId,
                },
              });
            }}
          >
            <div className="text-white text-center self-center">
              Assign to Teacher
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default AssignmentCheckStudentList;
