import React, { useState, useEffect } from 'react';
import './SubTopic.css'; // You need to create this CSS file for styling
import axios from 'axios';
import { toast } from 'react-toastify';
import baseUrl from "../../../../config/config";

function SubTopic() {
  const [subTopicName, setSubTopicName] = useState('');
  const [subTopicDescription, setSubTopicDescription] = useState('');
  const [thumbnail, setThumbnail] = useState(null);
  const [exams, setExams] = useState([]);
  const [selectedExamId, setSelectedExamId] = useState('');
  const [grades, setGrades] = useState([]);
  const [selectedGradeId, setSelectedGradeId] = useState('');
  const [subjects, setSubjects] = useState([]);
  const [selectedSubjectId, setSelectedSubjectId] = useState('');
  const [chapters, setChapters] = useState([]);
  const [selectedChapterId, setSelectedChapterId] = useState('');
  const [topics, setTopics] = useState([]);
  const [selectedTopicId, setSelectedTopicId] = useState('');
  const [selectedSubTopicId, setSelectedSubTopicId] = useState('');
  const [subTopics, setSubTopics] = useState([]);
  const [selectedSubTopic, setSelectedSubTopic] = useState(null);
  const [showSubTopicList, setShowSubTopicList] = useState(false);
  const [imageUrl, setImageUrl] = useState()

  // const baseUrl.Url /v1= "http://localhost:4001/v1";
  // const API_BASE_URL = 'https://content-panel-intelli.onrender.com/v1'
  // const API_BASE_URL = 'https://test-content-dr78.onrender.com/v1'
  // const API_BASE_URL = 'https://internalrelease.onrender.com/v1'
// const baseUrl.Url /v1= 'https://content-panel-backend-urll.onrender.com/v1'


  useEffect(() => {
    fetchExams();
  }, []);

  const fetchExams = async () => {
    try {
      const response = await axios.get(`${baseUrl.Url}/v1/exam/getExams`);
      setExams(response.data.data);
    } catch (error) {
      console.error('Error fetching exams:', error);
    }
  };

  const fetchGrades = async (examId) => {
    try {
      const response = await axios.get(`${baseUrl.Url}/v1/grade/getgrades/${examId}`);
      setGrades(response.data.data);
    } catch (error) {
      console.error('Error fetching grades:', error);
    }
  };

  const fetchSubjects = async (gradeId) => {
    try {
      const response = await axios.get(`${baseUrl.Url}/v1/subject/getSubjects/${gradeId}`);
      setSubjects(response.data.data);
    } catch (error) {
      console.error('Error fetching subjects:', error);
    }
  };

  const fetchChapters = async (subjectId) => {
    try {
      const response = await axios.get(`${baseUrl.Url}/v1/chapter/getChapters/${subjectId}`);
      setChapters(response.data.data);
    } catch (error) {
      console.error('Error fetching chapters:', error);
    }
  };

  const fetchTopics = async (chapterId) => {
    try {
      const response = await axios.get(`${baseUrl.Url}/v1/topic/getTopics/${chapterId}`);
      setTopics(response.data.data);
    } catch (error) {
      console.error('Error fetching topics:', error);
    }
  };

  const fetchSubTopics = async (topicId) => {
    try {
      const response = await axios.get(`${baseUrl.Url}/v1/subTopic/getSubTopics/${topicId}`);
      setSubTopics(response.data.data);
    } catch (error) {
      console.error('Error fetching subtopics:', error);
    }
  };

  const handleSubTopicNameChange = (e) => {
    setSubTopicName(e.target.value);
  };

  const handleSubTopicSelection = (subTopicId) => {
    setSelectedSubTopicId(subTopicId);
  };

  const handleSubTopicDescriptionChange = (e) => {
    setSubTopicDescription(e.target.value);
  };

  const handleThumbnailChange = (e) => {
    const file = e.target.files[0];
    setThumbnail(file);
 
    const data = new FormData();
    data.append("file" , thumbnail)
    data.append("upload_preset", "dagada")
    data.append("cloud_name" , 'dfzd4xbsk')
  
    fetch("https://api.cloudinary.com//v1_1/dfzd4xbsk/image/upload" , {
      method: 'POST',
      body: data
    }).then((res)=>res.json())
    .then((data)=>{
      console.log(data)
      setImageUrl(data.url);
    }).catch((err)=>{
      console.log(err);
      toast.error(err);
    })
  };


  const handleExamSelection = (examId) => {
    setSelectedExamId(examId);
    setSelectedGradeId('');
    setSelectedSubjectId('');
    setSelectedChapterId('');
    setSelectedTopicId('');
    setSelectedSubTopicId('');
    fetchGrades(examId);
  };

  const handleGradeSelection = (gradeId) => {
    setSelectedGradeId(gradeId);
    setSelectedSubjectId('');
    setSelectedChapterId('');
    setSelectedTopicId('');
    setSelectedSubTopicId('');
    fetchSubjects(gradeId);
  };

  const handleSubjectSelection = (subjectId) => {
    setSelectedSubjectId(subjectId);
    setSelectedChapterId('');
    setSelectedTopicId('');
    setSelectedSubTopicId('');
    fetchChapters(subjectId);
  };

  const handleChapterSelection = (chapterId) => {
    setSelectedChapterId(chapterId);
    setSelectedTopicId('');
    setSelectedSubTopicId('');
    fetchTopics(chapterId);
  };

  const handleTopicSelection = (topicId) => {
    setSelectedTopicId(topicId);
    setSelectedSubTopicId('');
    fetchSubTopics(topicId);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!subTopicName) {
        toast.error('Please Enter Exam Name');
        return;
      }

      if (!imageUrl) {
        const userConfirmed = window.confirm(
          'Thumbnail image URL is empty. Do you want to proceed without a thumbnail?'
        );
  
        if (!userConfirmed) {
          return;
        }
      }
      // const imageUrl = cld.image(thumbnail).toURL();
      const token = localStorage.getItem('token');

      const response = await axios.post(
        `${baseUrl.Url}/v1/subTopic/createSubTopic`,
        {
          subTopicName,
          subTopicDescription,
          thumbnail: imageUrl,
          examId: selectedExamId,
          gradeId: selectedGradeId,
          subjectId: selectedSubjectId,
          chapterId: selectedChapterId,
          topicId: selectedTopicId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 201) {
        toast.success('SubTopic created successfully!');
        setSubTopicName('');
        setSubTopicDescription('');
        setThumbnail(null);
        setSelectedExamId('');
        setSelectedGradeId('');
        setSelectedSubjectId('');
        setSelectedChapterId('');
        setSelectedTopicId('');
        setImageUrl(null);
        clearFileInput();
        fetchExams();
      }
    } catch (error) {
      console.error('Error creating subtopic:', error);
      toast.error(error.response.data.error);

    }
  };

  const clearFileInput = () => {
    const fileInput = document.getElementById('thumbnail');
    if (fileInput) {
      fileInput.value = ''; // Clear the value of the file input
    }
  };

  const handleEditSubTopic = (subTopic) => {
    setSelectedSubTopic(subTopic);
    setSubTopicName(subTopic.subTopicName);
    setSubTopicDescription(subTopic.subTopicDescription);
  };

  const handleUpdateSubTopic = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.put(
        `${baseUrl.Url}/v1/subTopic/updateSubTopic/${selectedSubTopic._id}`,
        {
          subTopicName,
          subTopicDescription,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        toast.success('SubTopic updated successfully!');
        setSelectedSubTopic(null);
        setSelectedExamId('');
        setSelectedSubjectId('');
        setSelectedChapterId('');
        setSelectedTopicId('');
        setSubTopics([])
        fetchExams();
      }
    } catch (error) {
      console.error('Error updating subtopic:', error);
      toast.error('An error occurred while updating the subtopic.');
    }
  };

  return (
    <div className="App">
      <div className="sub-navbar">
        <div className="dropdown">
          <select
            className="dropbtn"
            value={selectedExamId}
            onChange={(e) => handleExamSelection(e.target.value)}
          >
            <option value="">Select an Exam</option>
            {exams.map((exam) => (
              <option key={exam._id} value={exam._id}>
                {exam.examName}
              </option>
            ))}
          </select>
        </div>
        <div className="dropdown">
          <select
            className="dropbtn"
            value={selectedGradeId}
            onChange={(e) => handleGradeSelection(e.target.value)}
          >
            <option value="">Select a Grade</option>
            {grades.map((grade) => (
              <option key={grade._id} value={grade._id}>
                {grade.gradeName}
              </option>
            ))}
          </select>
        </div>
        <div className="dropdown">
          <select
            className="dropbtn"
            value={selectedSubjectId}
            onChange={(e) => handleSubjectSelection(e.target.value)}
          >
            <option value="">Select a Subject</option>
            {subjects.map((subject) => (
              <option key={subject._id} value={subject._id}>
                {subject.subjectName}
              </option>
            ))}
          </select>
        </div>
        <div className="dropdown">
          <select
            className="dropbtn"
            value={selectedChapterId}
            onChange={(e) => handleChapterSelection(e.target.value)}
          >
            <option value="">Select a Chapter</option>
            {chapters.map((chapter) => (
              <option key={chapter._id} value={chapter._id}>
                {chapter.chapterName}
              </option>
            ))}
          </select>
        </div>
        <div className="dropdown">
          <select
            className="dropbtn"
            value={selectedTopicId}
            onChange={(e) => handleTopicSelection(e.target.value)}
          >
            <option value="">Select a Topic</option>
            {topics.map((topic) => (
              <option key={topic._id} value={topic._id}>
                {topic.topicName}
              </option>
            ))}
          </select>
        </div>
      </div>
      <form className="form" onSubmit={handleSubmit}>
        <label htmlFor="subTopicName">Sub Topic Name:</label>
        <input
          type="text"
          id="subTopicName"
          value={subTopicName}
          onChange={handleSubTopicNameChange}
        />
        <label htmlFor="subTopicDescription">Sub Topic Description:</label>
        <textarea
          id="subTopicDescription"
          value={subTopicDescription}
          onChange={handleSubTopicDescriptionChange}
        />

        <label htmlFor="thumbnail">Upload Thumbnail:</label>
        <input
          type="file"
          id="thumbnail"
          accept="image/*"
          onChange={handleThumbnailChange}
        />

        <button type="submit">Submit</button>
      </form>

      <div className="sub-navbar">
        <button className='get_subtopics' onClick={() => setShowSubTopicList(!showSubTopicList)}>
          {showSubTopicList ? 'Hide SubTopic Data' : 'Get SubTopic Data'}
        </button>
      </div>
      {showSubTopicList && (
        <div className="edit-form">
          <h2 className="section-title">SubTopic List</h2>
          <ul>
            {subTopics.map((subTopic) => (
              <li className="subtopic-item" key={subTopic._id}>
                <span>{subTopic.subTopicName}</span>
                <button className="edit-button" onClick={() => handleEditSubTopic(subTopic)}>Edit</button>
              </li>
            ))}
          </ul>
        </div>
      )}
      {selectedSubTopic && (
        <div className="edit-form">
          <h2 className="section-title">Edit SubTopic</h2>
          <label htmlFor="editSubTopicName">SubTopic Name:</label>
          <input
            type="text"
            id="editSubTopicName"
            value={subTopicName}
            onChange={handleSubTopicNameChange}
            className="input-field"
          />

          <label htmlFor="editSubTopicDescription">SubTopic Description:</label>
          <textarea
            id="editSubTopicDescription"
            value={subTopicDescription}
            onChange={handleSubTopicDescriptionChange}
            className="textarea-field"
          />

          <button className="update-button" onClick={handleUpdateSubTopic}>Update</button>
          <button className="cancel-button" onClick={() => setSelectedSubTopic(null)}>Cancel</button>
        </div>
      )}
    </div>
  );
}

export default SubTopic;
