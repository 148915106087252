export const data = [
    {
        name: "Mr.Kapoor",
        class: "III",
        Subject: "MATHEMATICS",
        Price: "Rs 2500",
        type: "Video,MP4",
        rating: 10,
        id: 1
    },
    {
        name: "Mr.Kapoor",
        class: "III",
        Subject: "MATHEMATICS",
        Price: "Rs 2500",
        type: "Video,MP4",
        rating: 10,
        id: 1
    },
    {
        name: "Mr.Kapoor",
        class: "III",
        Subject: "MATHEMATICS",
        Price: "Rs 2500",
        type: "Video,MP4",
        rating: 10,
        id: 1
    },
    {
        name: "Mr.Kapoor",
        class: "III",
        Subject: "MATHEMATICS",
        Price: "Rs 2500",
        type: "Video,MP4",
        rating: 10,
        id: 1
    },
    {
        name: "Mr.Kapoor",
        class: "III",
        Subject: "MATHEMATICS",
        Price: "Rs 2500",
        type: "Video,MP4",
        rating: 10,
        id: 1
    },
    {
        name: "Mr.Kapoor",
        class: "III",
        Subject: "MATHEMATICS",
        Price: "Rs 2500",
        type: "Video,MP4",
        rating: 10,
        id: 1
    },
    {
        name: "Mr.Kapoor",
        class: "III",
        Subject: "MATHEMATICS",
        Price: "Rs 2500",
        type: "Video,MP4",
        rating: 10,
        id: 1
    },
    {
        name: "Mr.Kapoor",
        class: "III",
        Subject: "MATHEMATICS",
        Price: "Rs 2500",
        type: "Video,MP4",
        rating: 10,
        id: 1
    },
    {
        name: "Mr.Kapoor",
        class: "III",
        Subject: "MATHEMATICS",
        Price: "Rs 2500",
        type: "Video,MP4",
        rating: 10,
        id: 1
    },
    {
        name: "Mr.Kapoor",
        class: "III",
        Subject: "MATHEMATICS",
        Price: "Rs 2500",
        type: "Video,MP4",
        rating: 10,
        id: 1
    },
    {
        name: "Mr.Kapoor",
        class: "III",
        Subject: "MATHEMATICS",
        Price: "Rs 2500",
        type: "Video,MP4",
        rating: 10,
        id: 1
    },
    {
        name: "Mr.Kapoor",
        class: "III",
        Subject: "MATHEMATICS",
        Price: "Rs 2500",
        type: "Video,MP4",
        rating: 10,
        id: 1
    },
    {
        name: "Mr.Kapoor",
        class: "III",
        Subject: "MATHEMATICS",
        Price: "Rs 2500",
        type: "Video,MP4",
        rating: 10,
        id: 1
    },
    {
        name: "Mr.Kapoor",
        class: "III",
        Subject: "MATHEMATICS",
        Price: "Rs 2500",
        type: "Video,MP4",
        rating: 10,
        id: 1
    },
    {
        name: "Mr.Kapoor",
        class: "III",
        Subject: "MATHEMATICS",
        Price: "Rs 2500",
        type: "Video,MP4",
        rating: 10,
        id: 1
    },
    {
        name: "Mr.Kapoor",
        class: "III",
        Subject: "MATHEMATICS",
        Price: "Rs 2500",
        type: "Video,MP4",
        rating: 10,
        id: 1
    },
    {
        name: "Mr.Kapoor",
        class: "III",
        Subject: "MATHEMATICS",
        Price: "Rs 2500",
        type: "Video,MP4",
        rating: 10,
        id: 1
    },
    {
        name: "Mr.Kapoor",
        class: "III",
        Subject: "MATHEMATICS",
        Price: "Rs 2500",
        type: "Video,MP4",
        rating: 10,
        id: 1
    },
    {
        name: "Mr.Kapoor",
        class: "III",
        Subject: "MATHEMATICS",
        Price: "Rs 2500",
        type: "Video,MP4",
        rating: 10,
        id: 1
    },
    {
        name: "Mr.Kapoor",
        class: "III",
        Subject: "MATHEMATICS",
        Price: "Rs 2500",
        type: "Video,MP4",
        rating: 10,
        id: 1
    },
]