import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import io from "socket.io-client";
import { useParams } from "react-router-dom";

function BatchChats() {
  const { id } = useParams();
  const [socket, setSocket] = useState(null);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const users = useSelector((state) => state.users);
  console.log(users)
  let socketInstance;

  const URL = "https://chat-service.ddns.net";
  const localURL = "http://localhost:8010";
  useEffect(() => {
    // Connect to the Socket.io server
    socketInstance = io(URL, {
      withCredentials: true,
      transports: ["websocket"],
    });
    setSocket(socketInstance);

    // Fetch chat history when component mounts
    const roomId = id;
    const roomName = id;
    const userId = users.userId;
    if (roomName.trim() && userId.trim()) {
      socketInstance.emit("joinRoom", roomName, userId);
    }

    socketInstance.emit("getChatHistory", roomId);

    // Listen for 'chatHistory' events and update the messages state
    socketInstance.on("chatHistory", (chatHistory) => {
      setMessages(chatHistory);
    });

    // Listen for 'message' events and update the messages state
    socketInstance.on("message", (newMessage) => {
      setMessages((prevMessages) => [...prevMessages, newMessage]);
    });

    return () => {
      // Cleanup socket connection when component unmounts
      if (socketInstance && socketInstance.connected) {
        socketInstance.disconnect();
        console.log("Socket disconnected");
      }

      // Clean up event listeners here
      socketInstance.off("connect");
      socketInstance.off("disconnect");
      socketInstance.off("chatHistory");
      socketInstance.off("message");
    };
  }, [id, setMessages, users.userId]);

  const sendMessage = () => {
    if (socket && socket.connected && message.trim() !== "") {
      if(users?.userId ){
      const roomId = id;
      const senderId = users?.userId;
      const senderName = "ADMIN";
      const newMessage = {
        sender_id: senderId,
        sender_name: senderName,
        content: message,
        chatId: roomId,
      };
      socket.emit("sendMessage", roomId, newMessage);
      setMessage("");
    }
    }
  };

  return (
    <div className="flex-1 p:2 sm:p-6 justify-between flex flex-col h-screen p-10">
      <div className="flex sm:items-center justify-between py-3 border-b-2 border-gray-200"></div>
      <div
        id="messages"
        className="flex flex-col space-y-4 p-3 overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch"
      >
        {messages.map((elem, i) => (
          <div className="chat-message" key={i}>
            {users.userId !== elem.senderId && (
              <di className="text-black text-base">{elem?.sender_name}</di>
            )}
            <div
              className={`flex items-end ${
                users.userId === elem.senderId ? "justify-end" : ""
              }`}
            >
              <div
                className={`flex flex-col space-y-2 text-xl max-w-xs mx-2 ${
                  users.userId === elem.senderId
                    ? "order-2 items-start"
                    : "order-1 items-end"
                }`}
              >
                <div>
                  <span
                    className={`px-4 py-2 rounded-lg inline-block ${
                      users.userId === elem.senderId
                        ? "rounded-bl-none bg-gray-300 text-gray-600"
                        : "rounded-br-none bg-blue-600 text-white"
                    }`}
                  >
                    {elem.content}
                  </span>
                </div>
              </div>
              <img
                src={users.userId === elem.senderId?users?.userdata?.personalDetails?.profileImage:"https://images.unsplash.com/photo-1590031905470-a1a1feacbb0b?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=3&amp;w=144&amp;h=144"}
                alt="My profile"
                className={`w-6 h-6 rounded-full ${
                  users.userId === elem.senderId ? "order-2" : "order-1"
                }`}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="border-t-2 border-gray-200 px-4 pt-4 mb-2 sm:mb-0 items-center">
        <div className="relative flex items-center justify-between space-x-5">
          <input
            type="text"
            placeholder="Write your message!"
            className="w-full focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 pl-12 bg-gray-200 rounded-md py-3"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-xl px-4 py-2 transition duration-500 ease-in-out text-white bg-blue-500 hover:bg-blue-400 focus:outline-none"
            onClick={sendMessage}
          >
            <span className="font-bold">Send</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="h-6 w-6 ml-2 transform rotate-90"
            >
              <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}

export default BatchChats;