import React, { useState, useEffect } from "react";
import logo from "../../../assets/logo2.png";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { useSelector } from "react-redux";
import axios from "axios";
import baseUrl from "../../../config/config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function SubscriptionPlans() {
  const users = useSelector((state) => {
    return state.users;
  });
  const navigate = useNavigate();
  const [curriculum, setCurriculum] = useState(null);
  const [classData, setClassData] = useState([]);
  const [subject, setSubject] = useState(null);
  const [numberCopies, setNumberCopies] = useState([]);
  const [dropdownData, setDropdownData] = useState([]);
  const [platformFees, setPlatformFees] = useState("");
  const [inputExam, setInputExam] = useState("");
  const [targetExams, setTargetExams] = useState([]);
  const [fixedPrice, setFixedPrice] = useState("");

  const bunchCopy = Array.from({ length: 100 }, (_, index) => ({
    label: `${index + 1}`,
    value: index + 1,
  }));

  const curriculumData = dropdownData.map((item) => ({
    value: item.curriculum,
    label: item.curriculum,
  }));

  const subjectData = dropdownData.map((item) => ({
    value: item.subject,
    label: item.subject,
  }));

  const standardData = dropdownData.map((item) => ({
    value: item.class,
    label: item.class,
  }));

  const addNewTag = () => {
    if (inputExam.trim() !== "") {
      setTargetExams([...targetExams, inputExam.trim()]);
      setInputExam("");
      console.log("targetExam", targetExams);
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setTargetExams(
      targetExams.filter((targetExams) => targetExams != tagToRemove)
    );
  };

  useEffect(() => {
    axios
      .get(`${baseUrl.auth}/ums/apis/v1/criculram`, {
        headers: {
          Authorization: `Bearer ${
            users.authToken != null ? users.authToken : users.unauthToken
          }`,
          "Content-Type": "application/json",
          Accept: "*/*",
          app_name: "admin",
          app_version_code: "101",
        },
      })
      .then((res) => {
        setDropdownData(res.data);
        console.log("res1", res.data);
      })
      .catch((err) => {
        console.log("error fetching data", err);
      });
  }, []);

  const createPlan = () => {
    if (!curriculum || !subject || !classData) {
      toast.error("Please select all required fields.");
      return;
    }

    let data = JSON.stringify({
      curriculum: curriculum.value,
      subject: subject.value,
      fixedPrice: +fixedPrice,
      platformFees: +platformFees,
      standard: classData.value,
      // targetExams: ["Board Exam", "NTSE"],
      targetExams: targetExams,
      numberOfCopies: numberCopies.value,
      discountFixed: 100,
    });

    console.log("data", JSON.parse(data));

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl.task}/tsv/apis/v1/subscription/Check/model`,
      headers: {
        Authorization: `Bearer ${
          users.authToken != null ? users.authToken : users.unauthToken
        }`,
        "Content-Type": "application/json",
        Accept: "*/*",
        app_name: "admin",
        app_version_code: "101",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.success("Plan created successfully!");
        console.log("create plan", response.data);
        navigate("/subscriptionPlanslist");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Failed to create plan. Please try again.");
      });
  };

  return (
    <div className="admin_panel_resource_container">
      <div className="admin_panel_resource_logo_img_container">
        <img
          onClick={() => {
            navigate("/");
          }}
          className="admin_panel_resource_logo_img"
          src={logo}
          alt="logo"
        />
      </div>

      <div className="m-5 mt-4">
        <div className="admin_panel_resource_container_cont">
          <h1 className="text-2xl ">Subscription Plans</h1>
        </div>

        <div className="bg-[#1C768F26] p-3 mt-5">
          <div className="flex flex-row justify-between mx-20">
            <div className="text-[#FA991C] text-center self-center text-2xl">
              1. Copy Checking Plan
            </div>
            <button className="bg-[#FA991C]">
              <div>Create Plan</div>
            </button>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-1 gap-x-32 mx-32 mt-4">
          <div className="flex flex-col">
            <label className="text-left font-bold mb-1 text-[#032539]">
              Curriculum
            </label>
            <Select
              options={curriculumData}
              className="text-[#1c768f] w-80"
              onChange={(selectedOption) => setCurriculum(selectedOption)}
              value={curriculum}
            />
          </div>

          <div className="flex flex-col">
            <label className="text-left text-[#032539] mb-1">Subject</label>
            <Select
              options={subjectData}
              className="text-[#1c768f] w-80"
              onChange={(selectedOption) => setSubject(selectedOption)}
              value={subject}
            />
          </div>
        </div>

        <div className="ml-32 mr-28">
          <div className="text-left font-bold text-[#032539] mb-1">Class</div>
          <Select
            options={standardData}
            className="text-[#1c768f] w-full"
            onChange={(selectedOption) => setClassData(selectedOption)}
            value={classData}
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-1 gap-x-32 mx-32 mt-4">
          <div className="flex flex-col">
            <label className="text-left font-bold mb-1 text-[#032539]">
              Fixed Price ( per copy )
            </label>
            <div className="bg-[#1C768F] w-80 p-2">
              <input
                type="number"
                placeholder="Enter Price Text"
                value={fixedPrice}
                onChange={(e) => {
                  setFixedPrice(e.target.value);
                }}
                className="text-center text-white bg-transparent border-none w-full focus:outline-none"
                style={{ height: "1px" }}
              />
            </div>
          </div>

          <div className="flex flex-col">
            <label className="text-left text-[#032539] mb-1">
              Platform Fees
            </label>
            <input
              type="number"
              placeholder="Enter Price"
              className="w-80 mr-2 border border-gray-300 rounded px-3 pt-2 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              value={platformFees}
              onChange={(e) => {
                setPlatformFees(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="ml-32 text-[#032539] text-sm font-semibold mt-1">
          For Institute
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-1 gap-x-32 mx-32 mt-2">
          <div className="flex flex-col">
            <label className="text-left text-[#032539] mb-1">
              Select Bunch of Copies
            </label>
            <Select
              options={bunchCopy}
              isMulti
              className="text-[#1c768f] w-full"
              onChange={(selectedOptions) => {
                setNumberCopies(selectedOptions);
              }}
              value={numberCopies}
            />
          </div>

          <div className="flex flex-col">
            <label className="text-left font-bold mb-1 text-[#032539]">
              Discount (fixed)
            </label>
            <div className="bg-[#1C768F] w-80 p-2">
              <div className="text-center text-white">10%</div>
            </div>
          </div>

          <div className="flex flex-col">
            <label className="text-left text-[#032539] my-2 mt-4">
              Target Exams
            </label>
            <input
              type="text"
              placeholder="Enter Target"
              className="w-80 mr-2 border border-gray-300 rounded px-3 pt-2 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              value={inputExam}
              onChange={(e) => {
                setInputExam(e.target.value);
              }}
            />

            <button onClick={addNewTag} className="w-48">
              Add Target Exams
            </button>

          <div className="flex flex-row gap-2 mt-3">
          {targetExams.map((item, index) => (
              <span key={index} className="flex flex-row">
                <div
                  onClick={() => {
                    handleRemoveTag(targetExams);
                  }}
                  className="self-center"
                >
                  <div className="text-white bg-red-500 text-center text-2xl p-1">{item}</div>
                </div>
              </span>
            ))}
          </div>
          </div>
        </div>
      </div>
      <div onClick={createPlan} className="bg-[#1C768F] p-2 ml-36 mr-32 my-5">
        <div className="text-center text-white">Update Plan</div>
      </div>

      <ToastContainer />
    </div>
  );
}

export default SubscriptionPlans;
