const baseUrls = {
  dev: {
    auth: 'https://ieoe8lr41j.execute-api.ap-south-1.amazonaws.com/dev/user-mgmt',
    sts: 'https://ieoe8lr41j.execute-api.ap-south-1.amazonaws.com/dev/sts',
    tms: 'https://ieoe8lr41j.execute-api.ap-south-1.amazonaws.com/dev/teacher',
    task: 'https://ieoe8lr41j.execute-api.ap-south-1.amazonaws.com/dev/task',
    ins: "https://ieoe8lr41j.execute-api.ap-south-1.amazonaws.com/dev/institute_services",
    payment: "https://4i3482jteb.execute-api.ap-south-1.amazonaws.com/dev/payment-service",
    Url: "https://ieoe8lr41j.execute-api.ap-south-1.amazonaws.com/dev/content-pannel",
    Url2: "https://4i3482jteb.execute-api.ap-south-1.amazonaws.com/dev/user-mgmt-service/ums/apis/v1/",
    Razor: "https://ieoe8lr41j.execute-api.ap-south-1.amazonaws.com/dev/rozar_pay",
    // webUrl: "http://localhost:3001/",
    webUrl: "https://intelliedtech.com/"
  },
  uat: {
    service1: 'https://uat.service1.example.com',
    service2: 'https://uat.service2.example.com',
  },
  prod: {
    auth: 'https://ieoe8lr41j.execute-api.ap-south-1.amazonaws.com/dev/user-mgmt',
    sts: 'https://ieoe8lr41j.execute-api.ap-south-1.amazonaws.com/dev/sts',
    tms: 'https://ieoe8lr41j.execute-api.ap-south-1.amazonaws.com/dev/teacher',
    task: 'https://ieoe8lr41j.execute-api.ap-south-1.amazonaws.com/dev/task',
  },
};

const currentEnvironment = 'dev'; // Set this based on your configuration

export default baseUrls[currentEnvironment];
