import React from "react";
import logo from "../../../assets/logo2.png";
import { useNavigate } from "react-router-dom";

function Subscription() {
  const navigate = useNavigate();
  return (
    <div className="admin_panel_resource_container">
      <div className="admin_panel_resource_logo_img_container">
        <img
          onClick={() => {
            navigate("/");
          }}
          className="admin_panel_resource_logo_img"
          src={logo}
          alt="logo"
        />
      </div>

      <div className="m-5 mt-4">
        <div className="admin_panel_resource_container_cont">
          <h1 className="text-2xl ">Subscription Plans</h1>
        </div>

     <div className="bg-[#1C768F26] p-3 mt-5">
     <div className="flex flex-row justify-between mx-20">
      <div className="text-[#FA991C] text-center self-center text-2xl">1. Copy Checking Plan</div>
      <button
      onClick={() => navigate("/subscriptionPlans")}
      className="bg-[#FA991C]">
        <div>
        Create Plan
        </div>
        </button>
      </div>
     </div>


     

      </div>

    </div>
  );
}

export default Subscription;
