import React, { useEffect, useState } from "react";
import Sidebar from "../../components/SideBar";
import logo from "../../assets/logo2.png";
import loader from "../../assets/icons/loader.gif";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Bar, Pie } from "react-chartjs-2";
import axios from "axios";
import { BarChart, LineChart } from "@mui/x-charts";
import baseUrl from "../../config/config"
import { fecthCurriculumOptions, fetchClassOptions, fetchLanguageOptions, fetchsubjectoptions } from "../../redux/reducers/optionSlice";
ChartJS.register(ArcElement, Tooltip, Legend);
function Home() {
  const { classOptions, languageOptions} = useSelector(state => state.options);
  console.log(classOptions,languageOptions)
  const navigate = useNavigate();
  const dispatch=useDispatch();
  const [teachers, setTeachers] = useState([]);
  const [students, setStudents] = useState([]);
  const [datas, setDatas] = useState([]);
  const [loading, setLoading] = useState(false);
  const users = useSelector((state) => {
    return state.users;
  });
  useEffect(() => {
    dispatch(fetchClassOptions());
    dispatch(fetchLanguageOptions());
    dispatch(fecthCurriculumOptions())
    dispatch(fetchsubjectoptions())
    // Dispatch other fetch actions similarly
  }, [dispatch]);
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${baseUrl.tms}/tms/apis/v1/teachers`, {
        headers: {
          Authorization: `Bearer ${users.authToken != null ? users.authToken : users.unauthToken
            }`,
          "Content-Type": "application/json",
          Accept: "*/*",
          app_name: "admin",
          app_version_code: "101",
        },
      })
      .then((data) => {
        setTeachers(data.data.data);
        console.log(data.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error finding the teacher", err);
        setLoading(false);
      });
  }, []);
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${baseUrl.sts}/sts/apis/v1/students`, {
        headers: {
          Authorization: `Bearer ${users.authToken != null ? users.authToken : users.unauthToken
            }`,
          "Content-Type": "application/json",
          Accept: "*/*",
          app_name: "admin",
          app_version_code: "101",
        },
      })
      .then((data) => {
        setStudents(data.data.data);
        console.log(data.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error finding the teacher", err);
        setLoading(false);
      });
  }, []);
  const [pieChartData] = useState({
    labels: ["Students", "Teachers"],
    datasets: [
      {
        data: [25, 20],
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#2ECC71",
          "#9B59B6",
          "#FFA726",
        ],
        hoverBackgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#2ECC71",
          "#9B59B6",
          "#FFA726",
        ],
      },
    ],
  });

  if (loading || datas == null) {
    return (
      <div className="admin_panel_resource_container_loading">
        <img
          src={loader}
          className="admin_panel_resource_container_loading_img"
        />
      </div>
    );
  } else {
    return (
      <div className="admin_panel_resource_container">
        <div className="admin_panel_resource_logo_img_container">
          <img
            onClick={() => {
              navigate("/");
            }}
            className="admin_panel_resource_logo_img"
            src={logo}
            alt="logo"
          />
        </div>
        <div className="admin_panel_resource_container_cont">
          <h1 className="admin_panel_resource_hed">Home</h1>
        </div>
        <div className="flex justify-around">
          <div>
            <Pie data={pieChartData} />
          </div>
          <div>
            <Pie data={pieChartData} />
          </div>
        </div>
        <div className="p-12 flex justify-around">
          <BarChart
            series={[
              { data: [3, 4, 1, 6, 5], stack: "Sudent", label: "Student" },
              { data: [4, 3, 1, 5, 8], stack: "Teacher", label: "Teacher" },
            ]}
            width={600}
            height={350}
          />
          <BarChart
            series={[
              { data: [3, 4, 1, 6, 5], stack: "Sudent", label: "Student" },
              { data: [4, 3, 1, 5, 8], stack: "Teacher", label: "Teacher" },
            ]}
            width={600}
            height={350}
          />
        </div>
        <div  className="p-12 flex justify-around">
          <LineChart
            xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
            series={[
              {
                data: [2, 5.5, 2, 8.5, 1.5, 5],
                area: true,
              },
            ]}
            width={500}
            height={300}
          />
          <LineChart
            xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
            series={[
              {
                data: [2, 5.5, 2, 8.5, 1.5, 5],
                area: true,
              },
            ]}
            width={500}
            height={300}
          />
        </div>
      </div>
    );
  }
}

export default Home;
