import React, { useEffect, useState } from 'react'
import logo from "../../../assets/logo2.png"
import search from "../../../assets/icons/search.png"
import demoVid from "../../../assets/videos/demoVideo.mp4"
import "../../../Styles/Resource.css"
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'video-react/dist/video-react.css';
import { Player } from 'video-react';
import loader from "../../../assets/icons/loader.gif"
import Select from "react-select";
import { useSelector } from 'react-redux'
import baseUrl from "../../../config/config"

const typeOfResData = [
  {
    "value": "video",
    "label": "video"
  },
  {
    "value": "text",
    "label": "text"
  }
]

function UpdateResource() {
  const navigate = useNavigate()

  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)

  let { id } = useParams();

  const [nameR, setNameR] = useState()
  const [clssR, setClssR] = useState()
  const [subjR, setSubjR] = useState()
  const [priceR, setPriceR] = useState()
  const [descpR, setdescpR] = useState()
  const [UrlR, setUrlR] = useState()
  const [publishedByR, setpublishedByR] = useState()
  const [resourcestypeR, setresourcestypeR] = useState()
  const users = useSelector((state) => { return state.users });

  const fetchResource = async () => {
    try {

      setLoading(true)
      const apiUrl = `${baseUrl.task}/tsv/apis/v1/resources/${id}`;
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${users.authToken}`,
          'Content-Type': 'application/json',
          Accept: '*/*',
          app_name: 'admin',
          app_version_code: '101',
        }
      });

      setData(response.data.data)
      console.log(response.data.data)

      if (response.data.data.publishStatus !== "draft") {
        navigate("/resources")
      }
      setPriceR(response.data.data.price);
      setdescpR(response.data.data.description);
      setNameR(response.data.data.name);
      setClssR(response.data.data.class);
      setSubjR(response.data.data.subject);
      setUrlR(response.data.data.Url);
      setpublishedByR(response.data.data.publishedBy);
      setresourcestypeR(response.data.data.resourcestype);

      setLoading(false)
    } catch (e) {
      console.log(e);
      toast.error("Something went wrong !")
      setLoading(false)
    }
  }
  const saveResourceHandler = async () => {
    if (!priceR || !descpR || !nameR || !clssR || !subjR || !UrlR || !publishedByR || !resourcestypeR) {
      toast.error("Provide all fields !")
    } else {
      try {

        await axios.put(`${baseUrl.task}/tsv/apis/v1/resource/${id}`, {
          "class": clssR,
          "subject": subjR,
          "name": nameR,
          "description": descpR,
          "price": priceR
        },
          {
            headers: {
              Authorization: `Bearer ${users.authToken != null ? users.authToken : users.unauthToken}`,
              'Content-Type': 'application/json',
              Accept: '*/*',
              app_name: 'admin',
              app_version_code: '101',
            }
          },
        )
        setPriceR(null);
        setdescpR(null);
        setNameR(null);
        setClssR(null);
        setSubjR(null);
        setUrlR(null);
        setpublishedByR(null);
        setresourcestypeR(null);

        toast.success("Resource updated successfully !")
      } catch (e) {
        toast.error("something went wrong !")
        console.log(e)
      }
    }
  }
  useEffect(() => {
    fetchResource()
  }, [])




  if (loading || data == null) {
    return <div className='admin_panel_resource_container_loading'><img src={loader} className='admin_panel_resource_container_loading_img' /></div>
  } else {

    return (
      <div className='admin_panel_resource_container'>
        <div className='admin_panel_resource_logo_img_container'>
          <img onClick={() => { navigate("/") }} className='admin_panel_resource_logo_img' src={logo} alt="logo" />
        </div>
        <div className='admin_panel_resource_container_cont'>
          <div className='admin_panel_resource_model'>

            <h1 className='admin_panel_resource_heds'>Edit Resource </h1>
            <input value={nameR} onChange={(e) => { setNameR(e.target.value) }} className='admin_panel_resource_model_inp' type="text" placeholder='Enter Resource name' />
            <input value={subjR} onChange={(e) => { setSubjR(e.target.value) }} className='admin_panel_resource_model_inp' type="text" placeholder='Enter Resource Subject' />
            <input value={priceR} onChange={(e) => { setPriceR(e.target.value) }} className='admin_panel_resource_model_inp' type="number" placeholder='Enter Resource Price' />
            <input value={clssR} onChange={(e) => { setClssR(e.target.value) }} className='admin_panel_resource_model_inp' type='number' placeholder='Enter Resource Class' />
            <Select
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  // width: "max-content",
                  minWidth: "300px",
                  borderColor: "white",
                  height: "30px",
                  cursor: "pointer",
                }),
                dropdownIndicator: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "#6B4EFF",
                }),
              }}
              className="admin_panel_resource_filter_container_item_drop"
              placeholder="Enter resource type"
              options={typeOfResData}
              onChange={(selectedOption) =>
                setresourcestypeR(selectedOption.value)
              }
            />
            {/* <input value={resourcestypeR} onChange={(e) => { setresourcestypeR(e.target.value) }} className='admin_panel_resource_model_inp' type="text" placeholder='Enter Resource Type' /> */}
            <input value={UrlR} onChange={(e) => { setUrlR(e.target.value) }} className='admin_panel_resource_model_inp' type="text" placeholder='Enter resource URL' />
            <input value={publishedByR} onChange={(e) => { setpublishedByR(e.target.value) }} className='admin_panel_resource_model_inp' type="text" placeholder='Enter publisher name' />
            <textarea value={descpR} onChange={(e) => { setdescpR(e.target.value) }} className='admin_panel_resource_model_inp_txt' placeholder='Enter Resource description' />
            <button className='admin_panel_resource_filter_btn' onClick={saveResourceHandler}>Save</button>
          </div>
        </div>
        <ToastContainer />
      </div>
    )
  }

}

export default UpdateResource
