import React, { useEffect, useState } from "react";
import logo from "../../../assets/logo2.png";
import loader from "../../../assets/icons/loader.gif";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import baseUrl from "../../../config/config";
import moment from "moment";
function CopyCheck() {
  const navigate = useNavigate();
  const [getAnswerData, setGetAnswerData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [examIdList, setExamIdList] = useState([]);

  const users = useSelector((state) => {
    return state.users;
  });
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${baseUrl.task}/tsv/apis/v1/exam/check/get/toshow/admin`, {
        headers: {
          Authorization: `Bearer ${
            users.authToken != null ? users.authToken : users.unauthToken
          }`,
          "Content-Type": "application/json",
          Accept: "*/*",
          app_name: "admin",
          app_version_code: "101",
        },
      })
      .then((res) => {
        setGetAnswerData(res.data.data);
        console.log("res1", res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error finding the teacher", err);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className="admin_panel_resource_container_loading">
        <img
          src={loader}
          className="admin_panel_resource_container_loading_img"
        />
      </div>
    );
  } else {
    return (
      <div className="admin_panel_resource_container">
        <div className="admin_panel_resource_logo_img_container">
          <img
            onClick={() => {
              navigate("/");
            }}
            className="admin_panel_resource_logo_img"
            src={logo}
            alt="logo"
          />
        </div>

        <div className="flex justify-between m-2 mt-4">
          <div className="admin_panel_resource_container_cont">
            <h1 className="text-2xl">Copy Checking</h1>
          </div>
          <div
            onClick={() => {
              navigate("/copy-check-institute");
            }}
          >
            View Requests from Institute
          </div>
        </div>

        {getAnswerData && getAnswerData.length > 0 ? (
          <>
            <div className="flex mt-4 justify-between w-full mx-2 bg-white">
              <table className="w-full bg-[#9bd4e3] rounded-lg overflow-hidden">
                <thead className="bg-[#B1D9E4] w-full">
                  <tr>
                    <th className="px-5 py-4 text-left">Exam Name</th>
                    <th className="px-5 py-4 text-left">Upload Date</th>
                    <th className="px-5 py-4 text-left">Exam Copy</th>
                    <th className="px-5 py-4 text-left">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {getAnswerData.map((item, index) => {
                    return (
                      item.ExamSubmit.filter((item) => item.isassigntoadmin === true && !item.isassignto_teacher).length > 0 ? (
                      <tr 
                      className="hover:bg-blue-300">
                        <td className="px-4 py-3">{item.examName}</td>
                        <td className="px-4 py-3">
                          {moment(item.createdAt).format('D MMM YYYY')}
                        </td>
                        <td className="px-4 py-3">{item.ExamSubmit.filter((item) => item.isassigntoadmin === true && !item.isassignto_teacher).length}</td>

                        <td className="px-4 py-3 space-x-2">
                          <button
                            onClick={() => {
                              navigate("/copy-check-studentlist", {
                                state: {
                                  studentList: item?.ExamSubmit,
                                  examName: item.examName,
                                  subTopic: item.subTopic,
                                  subject: item.subject,
                                  examDate: item.createdAt,
                                  topic: item.Topic,
                                  examId: item._id,
                                  chapter: item.chapter,
                                },
                              });
                            }}
                            className="bg-[#FA991C] hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded items-center justify-center"
                          >
                            Student List
                          </button>
                        </td>
                      </tr>
                          ): null
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <div className="text-3xl text-[#130F40] text-left p-5">
            No Students found....
          </div>
        )}
   
      </div>
    );
  }
}

export default CopyCheck;
