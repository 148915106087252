import React, { useEffect, useState } from 'react'
import logo from "../../../assets/logo2.png"
import search from "../../../assets/icons/search.png"
import demoVid from "../../../assets/videos/demoVideo.mp4"
import "../../../Styles/Resource.css"
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'video-react/dist/video-react.css';
import { Player } from 'video-react';
import loader from "../../../assets/icons/loader.gif"
import { useSelector } from 'react-redux'
import baseUrl from "../../../config/config"
function ResourceDetail() {
  const navigate = useNavigate()

  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const users = useSelector((state) => { return state.users });


  let { id } = useParams();

  const fetchResource = async () => {
    try {

      setLoading(true)
      const apiUrl = `${baseUrl.task}/tsv/apis/v1/resources/${id}`;
      const response = await axios.get(apiUrl,
        {
          headers: {
            Authorization: `Bearer ${users.authToken != null ? users.authToken : users.unauthToken}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
            app_name: 'admin',
            app_version_code: '101',
          }
        },
      );
      setData(response.data.data)
      console.log(response.data.data)
      setLoading(false)
    } catch (e) {
      console.log(e);
      toast.error("Something went wrong !")
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchResource();
  }, []);

  const rejectHandler = async () => {
    try {

      setLoading(true)
      await axios.put(`${baseUrl.task}/tsv/apis/v1/resource/${id}`, {
        publishStatus: "false"
      },
        {
          headers: {
            Authorization: `Bearer ${users.authToken != null ? users.authToken : users.unauthToken}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
            app_name: 'admin',
            app_version_code: '101',
          }
        },
      )

      await fetchResource()
      setLoading(false)
    } catch (e) {
      toast.error("something went wrong !")
      setLoading(false)
    }
  }
  const approveHandler = async () => {
    try {

      setLoading(true)
      await axios.put(`${baseUrl.task}/tsv/apis/v1/resource/${id}`, {
        publishStatus: "true"
      },
        {
          headers: {
            Authorization: `Bearer ${users.authToken != null ? users.authToken : users.unauthToken}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
            app_name: 'admin',
            app_version_code: '101',
          }
        },
      )

      await fetchResource()
      setLoading(false)
    } catch (e) {
      toast.error("something went wrong !")
      setLoading(false)
    }
  }
  const revertApprove = async () => {
    try {

      setLoading(true)
      await axios.put(`${baseUrl.task}tsv/apis/v1/resource/${id}`, {
        publishStatus: "requested"
      },
        {
          headers: {
            Authorization: `Bearer ${users.authToken != null ? users.authToken : users.unauthToken}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
            app_name: 'admin',
            app_version_code: '101',
          }
        },
      )

      await fetchResource()
      setLoading(false)
      navigate("/resources")
    } catch (e) {
      toast.error("something went wrong !")
      setLoading(false)
    }
  }

  if (loading || data == null) {
    return <div className='admin_panel_resource_container_loading'><img src={loader} className='admin_panel_resource_container_loading_img' /></div>
  } else {

    return (
      <div className='admin_panel_resource_container'>
        <div className='admin_panel_resource_logo_img_container'>
          <img onClick={() => { navigate("/") }} className='admin_panel_resource_logo_img' src={logo} alt="logo" />
        </div>
        <div className='admin_panel_resource_container_cont'>

          <h1 className='admin_panel_resource_hed'>Resource Detail</h1>
        </div>
        <table>
          <thead>
            <tr>

              <th>Name</th>
              <th> Class</th>
              <th>Subject</th>
              <th>Price</th>
              <th>Type</th>
              {/* <th>Rating</th> */}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>

            <tr>
              <td>{data.name}</td>
              <td>{data.class}</td>
              <td>{data.subject}</td>
              <td>{data.price}</td>
              <td>{data.resourcestype}</td>
              {/* <td>10</td> */}
              <td>
                <div className='admin_panel_table_btns'>
                  {console.log(data.publishStatus === "true")}

                  {data.publishStatus === "true" ? <button className='admin_panel_table_btns_btn2' onClick={revertApprove}>Revert </button> : null}
                  {data.publishStatus === "true" ? <h1 > ( Approved Already )</h1> : null}
                  {data.publishStatus === "false" ? "Resource Rejected" : null}
                  {data.publishStatus === "requested" ? <button className='admin_panel_table_btns_btn3' onClick={rejectHandler}>Reject</button> : null}
                  {data.publishStatus === "requested" ? <button className='admin_panel_table_btns_btn2' onClick={approveHandler}>Approve</button> : null}
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div className='admin_panel_resource_detail_section'>
          <h1 className='admin_panel_resource_detail_section_hed'>{data.name} </h1>
          <div className='admin_panel_resource_detail_section_desc'>
            {data.description}
          </div>

          {data.resourcestype === 'video' ?
            <Player className='admin_panel_resource_detail_section_vid' >
              <source src={data.Url} />
            </Player>
            : <button className='admin_panel_resource_detail_section_pdf'>Download PDF</button>}
        </div>
        <ToastContainer />
      </div>
    )
  }

}

export default ResourceDetail
