import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  BrowserRouter,
} from "react-router-dom";
import NotFound from "./Pages/auth/notFound.jsx";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./Pages/auth/Login.jsx";
import Home from "./Pages/main/Home.jsx";
import Private from "./components/reduxComponents/PrivateRoute.js";
import Resources from "./Pages/main/resource/Resources.jsx";
import Classes from "./Pages/main/Classes.jsx";
import Students from "./Pages/main/Students.jsx";
import Teachers from "./Pages/main/Teachers.jsx";
import Reviews from "./Pages/main/Reviews.jsx";
import Exams from "./Pages/main/Exams.jsx";
import ResourceDetail from "./Pages/main/resource/ResourceDetail.jsx";
import UpdateResource from "./Pages/main/resource/UpdateResource.jsx";
import OTPScreen from "./Pages/auth/OTP.jsx";
import ExpireUser from "./components/reduxComponents/ExpireUser.jsx";
import Providers from "./redux/Providers.js";
import NewExamCustum from "./Pages/main/Exam/NewExamCustom.js";
import AddQuestions from "./Pages/main/Exam/AddQuestions.jsx";
import ExamComponent from "./Pages/main/Exam/Exam.jsx";
import NewExamPanel from "./Pages/main/Exam/NewExamPanel.js";
import ClassDetail from "./Pages/main/ClassDetail.jsx";
import ClassCreation from "./Pages/main/ClassCreation.jsx";
import AssigmentDetails from "./Pages/main/AssigmentDetails.jsx";
import AddStudent from "./Pages/main/AddStudent.jsx";
import AddTutor from "./Pages/main/AddTutor.jsx";
import ChapterDetails from "./Pages/main/ChapterDetails.jsx";
// import Subscription from "./Pages/subscriptions/Subscription.jsx";
import SessionDetails from "./Pages/main/SessionDetails.jsx";
import ExamSection from "./Pages/main/ExamSection.jsx";
import Exam from "./Pages/main/Exams.jsx";
import MeetingPage from "./Pages/main/MeetingPage.js";
import ContactUs from "./components/ContectUs.jsx";
import About from "./components/About.jsx";
// import PrivacyPolicy from "./components/PrivacyPolicy.jsx";
import TnC from "./components/TnC.jsx";
import CnR from "./components/CnR.jsx";
import Footer from "./components/Footer.jsx";
import BatchChats from "./Pages/main/BatchChats.jsx";
import GeneratePayment from "./Pages/main/GeneratePayment.jsx";
import InstituteRequest from "./InstituteRequest.js";
import InstuteRequestDet from "./InstuteRequestDet.js";
import InstituteRequestResource from "./InstituteRequestResource.js";
import CopyCheck from "./Pages/main/CopyChecking/CopyCheck.jsx";
import CopyCheckInstitute from "./Pages/main/CopyChecking/CopyCheckInstitute.jsx";
import MarkCopyCheck from "./Pages/main/CopyChecking/MarkCopyCheck.jsx";
import MarkCopyCheckInstitute from "./Pages/main/CopyChecking/MarkCopyCheckInstitute.jsx";
import Subscription from "./Pages/main/Subscription/Subscription.jsx";
import SubscriptionPlans from "./Pages/main/Subscription/SubscriptionPlans.jsx";
import SwitchStudentRequests from "./Pages/main/SwitchStudentRequests/SwitchStudentRequests.jsx";
import SwitchInstitute from "./Pages/main/SwitchStudentRequests/SwitchInstitute.jsx";
import VerifyStudentRequests from "./Pages/main/SwitchStudentRequests/VerifyStudentRequest.jsx";
import AssignmentCheck from "./Pages/main/AssignmentChecking/AssignmentCheck.jsx";
import AssignmentCheckTeacher from "./Pages/main/AssignmentChecking/AssignmentCheckTeacher.jsx";
import AssignmentCheckInstitute from "./Pages/main/AssignmentChecking/AssignmentCheckInstitute.jsx";
import AssignmentCheckInstituteTeacher from "./Pages/main/AssignmentChecking/AssignmentCheckInstituteTeacher.jsx";
import CopyCheckStudentList from "./Pages/main/CopyChecking/CopyCheckStudentList.jsx";
import AssignmentCheckStudentList from "./Pages/main/AssignmentChecking/AssignmentCheckStudentList.jsx";
import CopyCheckInstituteStudentList from "./Pages/main/CopyChecking/CopyCheckInstituteStudentList.jsx";
import AssignmentCheckInstituteStudentList from "./Pages/main/AssignmentChecking/AssignmentCheckInstituteStudentList.jsx";
import SubscriptionPlansList from "./Pages/main/Subscription/SuscriptionPlansList.jsx";
import DeleteAccount from "./Pages/main/DeleteAccount.jsx";
import PrivacyPolicy from "./Pages/main/PrivacyPolicy.jsx";
import MasterData from "./Pages/main/MasterData.jsx";
import Curriculum from "./Pages/main/SampleMasterData/Curriculum/Curriculum.jsx";
import Setting from "./Pages/Setting.jsx";
import { useSelector } from "react-redux";
import Grade from "./Pages/main/SampleMasterData/Grade/Grade.jsx";
import Subject from "./Pages/main/SampleMasterData/Subject/Subject.jsx";
import Unit from "./Pages/main/SampleMasterData/Unit/Unit.jsx";
import Topic from "./Pages/main/SampleMasterData/Topic/Topic.jsx";
import SubTopic from "./Pages/main/SampleMasterData/SubTopic/SubTopic.jsx";
import Language from "./Pages/main/SampleMasterData/Language/Language.jsx";
import Payment from "./Pages/Payment/Payment.jsx";
import AddSession from "./Pages/main/AddSession.jsx";
import ClassResources from "./Pages/main/classresource/Resources.jsx";
import Assignment from "./Pages/main/classassignment/Assignment.jsx";
import Assigment from "./Pages/main/Assigment.jsx";

const ProjectRoutes = () => {
  return (
    <React.Suspense fallback={<>Loading...</>}>
      <BrowserRouter>
        <Providers>
          <ExpireUser>
            <Routes>
              <Route
                path="/"
                element={
                  <Private activeContent="Home">
                    <Home />
                  </Private>
                }
              />
              <Route
                path="/home"
                element={
                  <Private activeContent="Home">
                    <Home />
                  </Private>
                }
              />
              <Route path="/login" element={<Login />} />
              <Route path="/about-us" element={<About />} />
              <Route path="/terms-and-conditions" element={<TnC />} />
              <Route path="/cancellation-and-refunds" element={<CnR />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/delete-account" element={<DeleteAccount />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/verify-otp" element={<OTPScreen />} />
              <Route
                path="/resources"
                element={
                  <Private activeContent="Resources">
                    <Resources />
                  </Private>
                }
              />
              <Route path="/Setting" element={<Private activeContent="Setting">
                <Setting />
              </Private>} />
              <Route
                path="/resource/:id"
                element={
                  <Private activeContent="Resources">
                    <ResourceDetail />
                  </Private>
                }
              />
              <Route
                path="/edit-resource/:id"
                element={
                  <Private activeContent="Resources">
                    <UpdateResource />
                  </Private>
                }
              />
              <Route
                path="/classes"
                element={
                  <Private activeContent="Classes">
                    <Classes />
                  </Private>
                }
              />
              <Route
                path="/classes/create-class"
                element={
                  <Private activeContent="Classes">
                    <ClassCreation />
                  </Private>
                }
              />
              <Route
                path="/classes/create-class/addstudent/:id"
                element={
                  <Private activeContent="Classes">
                    <AddStudent />
                  </Private>
                }
              />
              <Route
                path="/masterdata"
                element={
                  <Private activeContent="masterdata">
                    <MasterData />
                  </Private>
                }
              />
              <Route
                path="/payment/:id"
                element={
                  <Private activeContent="payment">
                    <Payment />
                  </Private>
                }
              />
              <Route
                path="/curriculum"
                element={
                  <Private activeContent="curriculumdata">
                    <Curriculum />
                  </Private>
                }
              />
              <Route
                path="/grade"
                element={
                  <Private activeContent="gradedata">
                    <Grade />
                  </Private>
                }
              />
              <Route
                path="/subject"
                element={
                  <Private activeContent="subjectdata">
                    <Subject />
                  </Private>
                }
              />
              <Route
                path="/unit"
                element={
                  <Private activeContent="unitdata">
                    <Unit />
                  </Private>
                }
              />
              <Route
                path="/topic"
                element={
                  <Private activeContent="topicdata">
                    <Topic />
                  </Private>
                }
              />
              <Route
                path="/subTopic"
                element={
                  <Private activeContent="subtopicdata">
                    <SubTopic />
                  </Private>
                }
              />
              <Route
                path="/language"
                element={
                  <Private activeContent="languagedata">
                    <Language />
                  </Private>
                }
              />
              <Route
                path="/classes/create-class/addtutor"
                element={
                  <Private activeContent="Classes">
                    <AddTutor />
                  </Private>
                }
              />
              <Route
                path="/classes/:id"
                element={
                  <Private activeContent="Classes">
                    <ClassDetail />
                  </Private>
                }
              />
              <Route
                path="/addsession/:id"
                element={
                  <Private activeContent="Classes">
                    <AddSession />
                  </Private>
                }
              />
              <Route
                path="/assigment/batch/:id"
                element={
                  <Private activeContent="Classes">
                    <Assigment />
                  </Private>
                }
              />
              <Route
                path="/exam/batch/:id"
                element={
                  <Private activeContent="Classes">
                    <ExamSection />
                  </Private>
                }
              />
              <Route
                path="/chapter/:id"
                element={
                  <Private activeContent="Classes">
                    <ChapterDetails />
                  </Private>
                }
              />
              <Route
                path="/session/:id"
                element={
                  <Private activeContent="Classes">
                    <SessionDetails />
                  </Private>
                }
              />
              <Route
                path="/assigment/:id"
                element={
                  <Private activeContent="Classes">
                    <AssigmentDetails />
                  </Private>
                }
              />
              <Route
                path="/exam/:id"
                element={
                  <Private activeContent="Classes">
                    <Exam />
                  </Private>
                }
              />
              <Route
                path="/batch/chat/:id"
                element={
                  <Private activeContent="Classes">
                    <BatchChats />
                  </Private>
                }
              />
              <Route
                path="/students"
                element={
                  <Private activeContent="Students">
                    <Students />
                  </Private>
                }
              />
              <Route
                path="/class-resources"
                element={
                  <Private activeContent="Class Resources">
                    <ClassResources />
                  </Private>
                }
              />
              <Route
                path="/assignment"
                element={
                  <Private activeContent="Class Assignment">
                    <Assignment />
                  </Private>
                }
              />
              <Route
                path="/teachers"
                element={
                  <Private activeContent="Teachers">
                    <Teachers />
                  </Private>
                }
              />
              <Route
                path="/institute/request"
                element={
                  <Private activeContent="institute-request">
                    <InstituteRequest />
                  </Private>
                }
              />
              <Route
                path="/institute/request/detail"
                element={
                  <Private activeContent="institute-request">
                    <InstuteRequestDet />
                  </Private>
                }
              />
              <Route
                path="/institute/request/resouce"
                element={
                  <Private activeContent="institute-request">
                    <InstituteRequestResource />
                  </Private>
                }
              />
              <Route
                path="/reviews"
                element={
                  <Private activeContent="Reviews">
                    {" "}
                    <Reviews />
                  </Private>
                }
              />
              {/* <Route path="/exams"element={<Private activeContent="Exams"><Exams /></Private>} /> */}
              <Route
                path="/exams"
                element={
                  <Private activeContent="Exams">
                    <ExamComponent />
                  </Private>
                }
              />
              <Route
                path="/create-exam-panel"
                element={
                  <Private activeContent="Exams">
                    <NewExamPanel />
                  </Private>
                }
              />
              <Route
                path="/custom-exam"
                element={
                  <Private activeContent="Exams">
                    <NewExamCustum />
                  </Private>
                }
              />
              <Route
                path="/custom-exam/add-question"
                element={
                  <Private activeContent="Exams">
                    <AddQuestions />
                  </Private>
                }
              />
              {/* <Route
                path="/subscriptions"
                element={
                  <Private activeContent="Subscriptions">
                    <Subscription />
                  </Private>
                }
              /> */}
              <Route
                exact
                path="/meetsessions/:roomName"
                element={
                  <Private activeContent="Classes">
                    <MeetingPage />
                  </Private>
                }
              />
              <Route
                exact
                path="/generatePayment"
                element={
                  <Private activeContent="generatepayment">
                    <GeneratePayment />
                  </Private>
                }
              />

              <Route
                path="/copy-check"
                element={
                  <Private activeContent="CopyCheck">
                    <CopyCheck />
                  </Private>
                }
              />
              <Route
                path="/copy-check-institute"
                element={
                  <Private activeContent="CopyCheckInstitute">
                    <CopyCheckInstitute />
                  </Private>
                }
              />
              <Route
                path="/copy-check-studentlist"
                element={
                  <Private activeContent="CopyCheckStudentList">
                    <CopyCheckStudentList />
                  </Private>
                }
              />
              <Route
                path="/mark-copy-check"
                element={
                  <Private activeContent="MarkCopyCheck">
                    <MarkCopyCheck />
                  </Private>
                }
              />
              <Route
                path="/copy-check-institute-studentlist"
                element={
                  <Private activeContent="CopyCheckInstituteStudentList">
                    <CopyCheckInstituteStudentList />
                  </Private>
                }
              />
              <Route
                path="/mark-copy-check-institute"
                element={
                  <Private activeContent="MarkCopyCheckInstitute">
                    <MarkCopyCheckInstitute />
                  </Private>
                }
              />
              <Route
                path="/assign-check"
                element={
                  <Private activeContent="AssignmentCheck">
                    <AssignmentCheck />
                  </Private>
                }
              />
              <Route
                path="/assign-check-studentlist"
                element={
                  <Private activeContent="AssignmentCheckStudentList">
                    <AssignmentCheckStudentList />
                  </Private>
                }
              />
              <Route
                path="/assign-check-teacher"
                element={
                  <Private activeContent="AssignmentCheckTeacher">
                    <AssignmentCheckTeacher />
                  </Private>
                }
              />
              <Route
                path="/assign-check-institute"
                element={
                  <Private activeContent="AssignmentCheckInstitute">
                    <AssignmentCheckInstitute />
                  </Private>
                }
              />
              <Route
                path="/assign-check-institute-studentlist"
                element={
                  <Private activeContent="AssignmentCheckInstituteStudentList">
                    <AssignmentCheckInstituteStudentList />
                  </Private>
                }
              />
              <Route
                path="/assign-check-institute-teacher"
                element={
                  <Private activeContent="AssignmentCheckInstituteTeacher">
                    <AssignmentCheckInstituteTeacher />
                  </Private>
                }
              />
              <Route
                path="/subscription"
                element={
                  <Private activeContent="Subscription">
                    <Subscription />
                  </Private>
                }
              />
              <Route
                path="/subscriptionPlans"
                element={
                  <Private activeContent="SubscriptionPlans">
                    <SubscriptionPlans />
                  </Private>
                }
              />
              <Route
                path="/subscriptionPlanslist"
                element={
                  <Private activeContent="SubscriptionPlansList">
                    <SubscriptionPlansList />
                  </Private>
                }
              />
              <Route
                path="/switchStudentRequests"
                element={
                  <Private activeContent="SwitchStudentRequests">
                    <SwitchStudentRequests />
                  </Private>
                }
              />
              <Route
                path="/switchInstitute"
                element={
                  <Private activeContent="SwitchInstitute">
                    <SwitchInstitute />
                  </Private>
                }
              />
              <Route
                path="/verifyStudentRequests"
                element={
                  <Private activeContent="VerifyStudentRequests">
                    <VerifyStudentRequests />
                  </Private>
                }
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </ExpireUser>
        </Providers>
        <Footer />
        <ToastContainer />
      </BrowserRouter>
    </React.Suspense>
  );
};
export default ProjectRoutes;
