import { useDispatch, useSelector } from 'react-redux'
import { setExpiryDate, setauthToken, setdata, setisLoggedin, setunauthToken, setuserId } from '../../redux/reducers/userSlice';
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios';

function ExpireUser({ children }) {


  const { ExpiryDate } = useSelector(state => state.users);
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const expirationDate = new Date(ExpiryDate);
  const currentTime = new Date();
  const { users } = useSelector((state) => { return state })
  const getTokenFromAPI = async () => {
    try {
      const response = await axios.get('https://dev-auth-services.onrender.com/auth/apis/v1/unauth_token/',
        {
          headers: {
            Authorization: `Bearer ${users.authToken != null ? users.authToken : users.unauthToken}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
            app_name: 'admin',
            app_version_code: '101',
          }
          },
      );

      const apiToken = response.data.data.accessToken;
      dispatch(setunauthToken(apiToken));
    } catch (error) {
      console.error('Error fetching token:', error);
    }
  };

  useEffect(() => {
    if (users.setisLoggedin === false && users.unauthToken === null) {

      const getTokenFromStorage = async () => {
        await getTokenFromAPI();
      };
      getTokenFromStorage();
    }

  }, [])
  useEffect(() => {


    if (ExpiryDate != null && currentTime >= expirationDate) {

      dispatch(setisLoggedin(false))
      dispatch(setuserId(null))
     dispatch(setauthToken(null))
      dispatch(setExpiryDate(null))

      navigate("/login")
    }
  }, [ExpiryDate, navigate])

  return children;


}

export default ExpireUser
