import React, { useState } from 'react';
import axios from 'axios';
import { faEnvelope, faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../Styles/contact.css';
import { toast } from 'react-toastify';
import loader from "../assets/loader/loader.gif"

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });
  const [loading,setLoading]=useState(false)

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when starting the API call

    try {
      const response = await axios.post('https://ieoe8lr41j.execute-api.ap-south-1.amazonaws.com/dev/user-mgmt/ums/apis/v1/users/contact-us', formData);
      console.log(response.data); // Handle the response as needed
      // Optionally, you can reset the form data after successful submission
    setLoading(false); // Set loading to true when starting the API call

      setFormData({
        name: '',
        email: '',
        phone: '',
        message: '',
      });
      toast.success('Your request is submitted successfully!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000, // Auto close the toast after 3 seconds
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      // Handle errors as needed
    setLoading(false); // Set loading to true when starting the API call

    }
  };

  return (
    <>

      <div className="contact-us">
        <div className="container-contect">
          <div style={{ width: '100%', margin: '10px', minHeight: '50vh' }}>
            <h1 className='h1-c'>Contact Us</h1>
            <iframe
              style={{ borderRadius: '4px' }}
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3509.1808858939858!2d77.06371247613154!3d28.413798293978918!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d23a31e63d83b%3A0x1235445b3de3e4bf!2sNirvana%20Courtyard%20Market!5e0!3m2!1sen!2sus!4v1700807100297!5m2!1sen!2sus"
              width="100%"
              height="400"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div style={{ width: '100%', margin: '10px' }}>
            <h1 className='h1-c'>Send us a message</h1>
            <form className='form-c' onSubmit={handleSubmit}>
              <label className='label-c' htmlFor="name">Name</label>
              <input
                className='input-c'
                type="text"
                id="name"
                name="name"
                placeholder="Enter your name"
                value={formData.name}
                onChange={handleChange}
                required
              />

              <label className='label-c' htmlFor="email">Email</label>
              <input
                className='input-c'
                type="email"
                id="email"
                name="email"
                placeholder="Enter your email address"
                value={formData.email}
                onChange={handleChange}
                required
              />

              <label className='label-c' htmlFor="phone">Phone</label>
              <input
                className='input-c'
                type="tel"
                id="phone"
                name="phone"
                placeholder="Enter your phone number"
                value={formData.phone}
                onChange={handleChange}
                required
              />

              <label className='label-c' htmlFor="message">Message</label>
              <textarea
                className='textarea-c'
                id="message"
                name="message"
                placeholder="Enter your message"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
 {loading===true ?
                <img className='login_credentials_container_form_part_2_btn_loader' src={loader} />
      :
              <button className='button-c' type="submit">Submit</button>
 }
            </form>
          </div>
        </div>
        <div className="contact-information">
          <p>You can also reach us by:</p>
          <ul className='ul-c'>
            <li className='li-c'>
              <FontAwesomeIcon icon={faEnvelope} style={{ color: '#007bff' }} />{" "}
              <a className='a-c' href="mailto:support@intelliedtech.com">support@intelliedtech.com</a>
            </li>
            <li className='li-c'>
              <FontAwesomeIcon icon={faPhone} style={{ color: '#007bff' }} />{" "}
              <a className='a-c' href="#">+919899235249</a>
            </li>
            <li className='li-c'>
              <FontAwesomeIcon icon={faMapMarkerAlt} style={{ color: '#007bff' }} />{" "}
              <a className='a-c' href="#">Nirvana Country, Sector 50, Gurugram, Haryana 122018, India</a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
