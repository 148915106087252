import React, { useEffect, useState } from "react";
import logo from "../../assets/logo2.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useParams } from 'react-router-dom';
import loader from "../../assets/icons/loader.gif"
import axios from "axios";
import baseUrl from "../../config/config"
import { useSelector } from "react-redux";
import moment from "moment/moment";

const StudentCard = ({ item }) => {

  console.log(item)

  const users = useSelector((state) => { return state.users });
  const [student, setStudent] = useState();

  const getStudent = async () => {
    try {
      const response = await axios.get(`${baseUrl.sts}/sts/apis/v1/user/${item}`, {
        headers: {
          'Authorization': `Bearer ${users.authToken}`,
        }
      });
      if (response.data.success) {
        setStudent(response.data.data);
      }
    } catch (error) {
      console.error('Error Class :', error);
    }
  }

  useEffect(() => {
    getStudent();
  }, [])

  return (
    <div className="bg-white shadow-md p-3 rounded-sm flex justify-between items-center space-x-5">
      <div className="flex items-center gap-3">
        <img src={student?.personalDetails?.profileImage} className="h-12 w-12 rounded-full" />
        <div>
          <p>{`${student?.personalDetails?.first_name} ${student?.personalDetails?.last_name}`}</p>
          <p>Phone: {student?.personalDetails?.phone_number}</p>
        </div>
      </div>
      <div>{item?.paymentPaid ? "✓" : "✖"}</div>
    </div>
  )
}

function ClassDetail() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState([]);
  const users = useSelector((state) => { return state.users });
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    axios.get(`${baseUrl.tms}/tms/apis/v1/classes/${id}`, {
      headers: {
        Authorization: `Bearer ${users.authToken}`,
        'Content-Type': 'application/json',
        Accept: '*/*',
        app_name: 'admin',
        app_version_code: '101',
      }
    }).then((res) => {
      setData(res.data.data);
      setLoading(false);
      console.log(res.data.data);
    }).catch((err) => {
      console.log('error getting batch details', err);
      setLoading(false);
    })
  }, [])

  if (loading || data == null) {
    return (
      <div className='admin_panel_resource_container_loading'><img src={loader} className='admin_panel_resource_container_loading_img' /></div>
    )
  }
  else {
    return (
      <div className="admin_panel_resource_container">
        <div className="admin_panel_resource_logo_img_container">
          <img
            onClick={() => {
              navigate("/");
            }}
            className="admin_panel_resource_logo_img"
            src={logo}
            alt="logo"
          />
        </div>
        <div className="admin_panel_resource_container_cont">
          <h1 className="admin_panel_resource_hed">Class Details</h1>
        </div>
        <div className="flex justify-between border items-center rounded-md border-blue-500 p-6">
          <div className="p-2">
            <div className="text-xs text-gray-500">Class Name- {data?.className}</div>
            <div className="text-2xl text-blue-500">{data?.className}</div>
            <div className="text-sm text-gray-500">{data?.Subject}</div>
            <div className="text-xl text-black">Language- {data?.language}</div>
          </div>
          <div className="p-2">
            <div className="text-3xl text-blue-500">Curriculum- {data?.curriculum}</div>
            <div className="text-xl text-gray-500">Class Duration - <span className="text-3xl text-blue-500">{data?.duration}</span></div>
          </div>
          <div className="p-2">
            <div className="text-xs text-gray-500">Batch Type</div>
            <div className="text-3xl text-blue-500">{data?.classType === "batch" ? "Batch Tutoring" : data?.classType === "home" ? "Home Tutoring" : "Private Tutoring"}</div>
          </div>
        </div>
        <div className="mt-5 items-center rounded-md bg-slate-300 border-[#CBCBCB] border-[1px] p-6">
          <div className="flex justify-between p-3 items-center">
            <div className="text-3xl font-bold ">Class Overview</div>
            {/* <div className="border-[#CBCBCB] border-[2px] rounded-md px-5 p-2 text-black">
              Reschedule Timming
            </div> */}
            <div onClick={() => navigate(`/batch/chat/${id}`)} className="text-xl font-bold bg-blue-500 rounded-lg p-3 cursor-pointer text-white">Chats</div>
          </div>
          <div className=" p-10 mt-5 space-y-5 bg-white rounded-lg">
            {/* <div className="flex justify-between">
            <div className="text-lg text-black">Target Exam</div>
            <div>CAT,XAT,MAT</div>
          </div> */}
            <div className="flex justify-between">
              <div className="text-lg text-black">Start Date</div>
              <div>{moment(data?.startClass).format('Do MMM YY')}</div>
            </div>
            <div className="flex justify-between">
              <div className="text-lg text-black">End Date</div>
              <div>{moment(data?.endClass).format('Do MMM YY')}</div>
            </div>
            {/* <div className="flex justify-between">
            <div className="text-lg text-black">Current Session</div>
            <div>CAT,XAT,MAT</div>
          </div> */}
            <div className="text-center">
              <button
                onClick={() => { navigate(`/exam/batch/${id}`) }}
                className="bg-[#6764FF] text-white self-center p-3"
              >
                Exam Section
              </button>
              <button
                onClick={() => { navigate(`/assigment/batch/${id}`) }}
                className="bg-[#6764FF] text-white self-center p-3 ml-2"
              >
                Assigment Section
              </button>
            </div>
            <h3 className="font-bold text-2xl">Student In Batch</h3>
            <div>
              {
                data?.studentId?.map((item, key) => (
                  <StudentCard key={key} item={item} />
                ))
              }
            </div>
            <div className="text-center">
              <button
                onClick={() => { navigate(`/classes/create-class/addstudent/${id}`) }}
                className="bg-[#6764FF] text-white self-center p-3"
              >
                Add New Student
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ClassDetail;
