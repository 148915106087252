import React, { useEffect, useState } from "react";
import logo from "../../assets/logo2.png";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { FaFilePdf } from "react-icons/fa";
import axios from "axios";
import loader from "../../assets/icons/loader.gif"
import baseUrl from "../../config/config"
import { useSelector } from "react-redux";
function Exam() {
  const users = useSelector((state) => { return state.users });
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);
  const [flag,setFlag]=useState(false);
  useEffect(() => {
    setLoading(true);
    axios.get(`${baseUrl.task}/tsv/apis/v1/exam/batch/${id}`, {
      headers: {
        Authorization: `Bearer ${users.authToken}`,
        'Content-Type': 'application/json',
        Accept: '*/*',
        app_name: 'admin',
        app_version_code: '101',
      }
    }).then((res) => {
      setData(res.data.data);
      console.log(res.data.data);
      setLoading(false);
    }).catch((err) => {
      setLoading(false);
      console.log("unable to get assigment details", err);
    })
  }, [flag])
  if (loading || data == null) {
    return (
      <div className='admin_panel_resource_container_loading'><img src={loader} className='admin_panel_resource_container_loading_img' /></div>
    )
  }
  else {
    return (
      <div className="admin_panel_resource_container">
        <div className="admin_panel_resource_logo_img_container">
          <img
            onClick={() => {
              navigate("/");
            }}
            className="admin_panel_resource_logo_img"
            src={logo}
            alt="logo"
          />
        </div>
        <div className="admin_panel_resource_container_cont">
          <h1 className="admin_panel_resource_hed">Assigment</h1>
        </div>
        <div className="space-y-5">
          <div className="flex justify-between border items-center rounded-md border-blue-500 p-6">
            <div className="p-2">
              <div className="text-gray-500 text-3xl">Assigment Name- 1</div>
              {/* <div className="text-2xl text-blue-500">Mr.Kapoor</div>
            <div className="text-sm text-gray-500">Mathematics</div>
            <div className="text-xl text-black">Due Date- 08.08.22</div>
            <div className="text-blue text-xl">Publish Date- 08.08.23</div> */}
            </div>
            <div className="p-2">
              {/* <div className="text-xs text-gray-500">Subject- Maths</div>
            <div className="text-xl text-gray-500">Topic- Trigonometry</div>
            <div className="text-2xl text-blue-500">SubTopic- Equation</div> */}
            </div>
            <div className="p-2">
              {
                (data?.publishStatus === "draft") ? (
                  <div onClick={() => {
                    setLoading(true);
                    axios.patch(`https://dev-task-services.onrender.com/tsv/apis/v1/assign/${id}`, {
                      "publishStatus": "true"
                    }).then((res) => {
                      if (res.status === 200) {
                        setLoading(false);
                        setFlag(true)
                      }
                    }).catch((err) => {
                      setLoading(false);
                      console.log("error while publishing data", err);
                    })
                  }} className="text-xl text-white cursor-pointer bg-blue-500 text-center mt-2 rounded-xl p-2">
                    Publish
                  </div>
                ) : (
                  <div className="text-xl text-white cursor-pointer bg-red-500 text-center mt-2 rounded-xl p-2">
                    Published
                  </div>
                )
              }

            </div>
          </div>
          <div className="mt-5 items-center rounded-md bg-slate-300 border-[#CBCBCB] border-[1px] p-6">
            <div className="flex justify-between p-3 items-center">
              <div className="text-3xl font-bold ">Assigment Overview</div>
            </div>
            <div className="flex justify-center items-center p-10 space-x-8 bg-white rounded-lg">
              <div className="text-lg font-semibold">
                Download Assigment in Pdf Format
              </div>
              <div className="bg-[#6B4EFF] flex items-center space-x-3 p-3 cursor-pointer text-white">
                <div>
                  <FaFilePdf color="white" size={25} />
                </div>
                <div>
                  Download
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Exam;

