import React, { useEffect, useState } from 'react';

const JitsiIntegration = ({ roomName, username, userid }) => {
  const [startTime, setStartTime] = useState(null);
  const [totalDuration, setTotalDuration] = useState(0);


 
  useEffect(() => {
    const domain = 'meet.intelliedtech.com';
    const parentNode = document.querySelector('#jitsi-container');

    const options = {
      roomName: roomName || 'default-room',
      width: '100%',
      height: '100vh',
      parentNode: parentNode,
      userInfo: {
        displayName: username,
        id: userid,
        subject: { role: 'moderator' },
      },
    };

    const api = new window.JitsiMeetExternalAPI(domain, options);

    // Only set the start time if it's not set already
    if (!startTime) {
      setStartTime(new Date());
    }

    // Clean up existing listeners before adding new ones
    api.removeAllListeners();

    // Add listeners
    api.addListener('participantJoined', (participant) => {
      console.log('Participants joined:', participant);
    });

    api.addListener('participantLeft', (participant) => {
      console.log('Participants left:', participant);
    });

    api.addListener('readyToClose', () => {
      console.log('Participants meet ended');
      if (startTime) {
        const endTime = new Date();
        const duration = (endTime - startTime) / 1000; // in seconds
        console.log(`User ${username} (ID: ${userid}) left the meeting after ${duration} seconds.`);

        // Update the total duration
        setTotalDuration((prevDuration) => prevDuration + duration);
      }
      window.history.back();
    });

    // Clean up when the component unmounts
    return () => {
      api.dispose();
    };
  }, [roomName, username, userid, startTime]);

  // Log the total duration whenever it changes
  useEffect(() => {
    console.log(`Total meeting duration: ${totalDuration} seconds`);
  }, [totalDuration]);

  return <div id="jitsi-container" />;
};

export default JitsiIntegration;
