import React, { useEffect, useState } from 'react'
import logo from "../src/assets/logo2.png"
import search from "../src/assets/icons/search.png"
import "../src/Styles/Resource.css"
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import loader from "../src/assets/icons/loader.gif"
import Modal from 'react-responsive-modal'
import baseUrl from "../src/config/config"
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
function InstuteRequestDet() {
  const location = useLocation();
  const users = useSelector((state) => { return state.users });
  const datas = location.state || {};
  console.log(datas)
  const [data, setData] = useState({
    name: ""
  });
  const [institutefee, setInstitutefee] = useState("");
  const [fee, setFee] = useState("")
  const [loading, setLoading] = useState(false);
  const [activebatch, setActivebatch] = useState(null);
  const [activebatchdata, setActivebatchdata] = useState(null);
  const navigate = useNavigate();
  const handleAcceptBtn = () => {
    const transformedData = {
      req_status: {
        sid: datas.student_id,
        profileimg: datas.student_profileImg || 'https://intelliedutech.s3.ap-south-1.amazonaws.com/1706789932640',
        studentname: data.studentname || 'Amit',
        status: 'requested',
        flag: true
      }
    };
    setLoading(true)
    axios.put(`${baseUrl.ins}/institute/apis/v1/update/status/${datas?.Institute_id}`, transformedData, {
      headers: {
        Authorization: `Bearer ${users.authToken}`,
        'Content-Type': 'application/json',
        Accept: '*/*',
        app_name: 'admin',
        app_version_code: '101',
      }
    }).then((res) => {
      if (res.status == 200) {
        navigate('/')
      }
    }).catch((err) => {
      setLoading(false)
      console.log(err)
    })
  }
  if (loading || data == null) {
    return (
      <div className='admin_panel_resource_container_loading'><img src={loader} className='admin_panel_resource_container_loading_img' /></div>
    )
  } else {
    return (
      <div className='admin_panel_resource_container'>
        <div className='admin_panel_resource_logo_img_container'>
          <img onClick={() => { navigate("/") }} className='admin_panel_resource_logo_img' src={logo} alt="logo" />

        </div>
        <div className="w-full px-2 bg-white">
          <div className="flex  justify-between items-center">
            <h1 className='admin_panel_resource_hed'>Student Requests</h1>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th>Student Id</th>
              <th>Class</th>
              <th>Name</th>
              <th>Institute</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr className='p-3'>
              <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                <div className="text-sm leading-5 text-gray-900">
                  {datas?.student_id}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                <div className="flex items-center">
                  <div className="text-sm leading-5 font-medium text-gray-900">
                    {datas?.standard}
                  </div>
                </div>
              </td>

              <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                <div className="text-sm leading-5 text-gray-900">
                  {datas?.studentname}
                </div>
              </td>

              <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                <span className="px-2 inline-flex leading-5 font-semibold rounded-full">
                  {datas?.institute_name}
                </span>
              </td>

              <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                <button className='admin_panel_table_btns_btn1'>View Details</button>
              </td>
            </tr>
          </tbody>
        </table>
        {/* <div onClick={() => {
          setActivebatch(!activebatch);
          setActivebatchdata(true);
        }} className="w-full px-2 bg-white border-[1px] border-[#1C768F] rounded-2xl cursor-pointer">
          <div className="flex mx-1 my-5 rounded-xl bg-[#795ffe] px-5 p-5 justify-between items-center">
            <h1 className='admin_panel_resource_heds text-[#1C768F]' >Batch Name</h1>
            <h1 className='admin_panel_resource_heds text-black'>Class XI</h1>
            <h1 className='admin_panel_resource_heds text-[#1C768F]'>JEE <span>Chem Maths</span></h1>
          </div>
          <div className="flex justify-between items-center px-5">
            <div className='flex-row'>
              <h4 className='admin_panel_resource_heds' style={{ color: "#1C768F" }}>Timing</h4>
              <h4 className='admin_panel_resource_heds'>05:10</h4>
            </div>
            <div className='flex-row'>
              <h4 className='admin_panel_resource_heds' style={{ color: "#1C768F" }}>Start Date</h4>
              <h4 className='admin_panel_resource_heds'>23/12/2024</h4>
            </div>
            <div className='flex-row'>
              <h4 className='admin_panel_resource_heds' style={{ color: "#1C768F" }}>Weekly Schedule</h4>
              <h4 className='admin_panel_resource_heds'>
                M T W TH F S SU
              </h4>
            </div>
            <div className='flex-row'>
              <h4 className='admin_panel_resource_heds' style={{ color: "#1C768F" }}>Batch Size</h4>
              <h4 className='admin_panel_resource_heds'>75 students</h4>
            </div>
          </div>
        </div> */}
        {/* {
          activebatch && activebatchdata && ( */}
        <div className='px-10'>
          <div className="flex justify-between items-center p-5">
            <h1 className='admin_panel_resource_hed'>Description</h1>
          </div>
          {/* <div className='px-5 flex justify-between items-center p-3 space-x-10'>
            <input placeholder='Institute Fees' type='number' className='w-1/2 p-5' pattern="[0-9]*" onChange={(e) => setInstitutefee(e.target.value)} />
            <input placeholder='Enter Your Fees' type='number' className='w-1/2 bg-[#1C768F] p-5 placeholder:text-white text-white' pattern="[0-9]*" onChange={(e) => setFee(e.target.value)} />
          </div> */}
          <div className='flex justify-center mt-10' onClick={handleAcceptBtn}>
            <button className='w-1/2 bg-[#1C768F] text-white'>Accept Request</button>
          </div>
        </div>
        {/* )
        } */}
      </div>
    )
  }
}

export default InstuteRequestDet