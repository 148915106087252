import axios from 'axios';
import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker';
import Modal from 'react-responsive-modal';
import { useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import baseUrl from "../../config/config"
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';

const AddSession = () => {

    const { id } = useParams();
    const users = useSelector((state) => { return state.users });
    const [detailModal, setDetailModal] = useState(false);
    const [session, setSession] = useState([]);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        "session_name": "",
        "class_id": id,
        "session_date": "",
        "session_start_time": "",
        "session_end_time": "",
        "session_status": "created"
    });

    const convertToISO = (timeString) => {
        const now = moment().tz('Asia/Kolkata');
        const dateString = now.format('YYYY-MM-DD');
        const dateTimeString = `${dateString} ${timeString}`;
        // Create a moment object and set the timezone to IST
        const istTime = moment.tz(dateTimeString, 'YYYY-MM-DD HH:mm', 'Asia/Kolkata');
        // Convert to ISO string
        const isoString = istTime.toISOString();
        return isoString;
    };

    const ModalCloseHandler = async () => {
        setDetailModal(false)
    }

    const getSesion = () => {
        axios.get(`${baseUrl.tms}/tms/apis/v1/bbb/class/all/sessions/${id}`, {
            headers: {
                Authorization: `Bearer ${users.authToken}`,
                'Content-Type': 'application/json',
                Accept: '*/*',
                app_name: 'admin',
                app_version_code: '101',
            }
        }).then((res) => {
            if (res.status === 200) {
                setSession(res.data.data);
                setLoading(false);
            }
        }).catch((err) => {
            console.log('error while creating the batch', err);
            setLoading(false);
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (data.session_name.length === 0 || data.session_date.length === 0 || data.session_start_time.length === 0 || data.session_end_time.length === 0) {
            toast.error('Please fill in all required fields.');
            return;
        }
        setLoading(true);
        axios.post(`${baseUrl.tms}/tms/apis/v1/bbb/class/sessions`, data, {
            headers: {
                Authorization: `Bearer ${users.authToken}`,
                'Content-Type': 'application/json',
                Accept: '*/*',
                app_name: 'admin',
                app_version_code: '101',
            }
        }).then((res) => {
            if (res.status === 200) {
                setLoading(false);
                setDetailModal(false);
                getSesion();
                toast.success('Session Successfully Created');
            }
        }).catch((err) => {
            console.log('error while creating the batch', err);
            setLoading(false);
        })
    }

    useEffect(() => {
        getSesion();
    }, [])

    return (
        <>

            <Modal className='admin_panel_resource_model' open={detailModal} onClose={ModalCloseHandler} center>
                <h1 className='admin_panel_resource_heds text-center'>Session Details</h1>
                <div className='w-[600px] h-[400px] flex justify-center'>
                    <form onSubmit={handleSubmit} className='w-full flex flex-col justify-between'>
                        <div className='flex flex-col'>
                            <label className="text-[#4A3794]" htmlFor="username">
                                Session Name
                            </label>
                            <input
                                id="sessionname"
                                type="text"
                                required={true}
                                className="block w-full px-4 py-2 mt-2 text-black bg-white border border-[#4A3794] rounded-md  focus:outline-none focus:ring placeholder:text-slate-500"
                                placeholder="Session Name"
                                onChange={(e) => setData({ ...data, session_name: e.target.value })}
                            />
                            <label className="text-[#4A3794]" htmlFor="username">
                                Session Date Time
                            </label>
                            <DatePicker
                                selected={data?.session_date}
                                onChange={(date) => setData({ ...data, session_date: date })}
                                className="border p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
                                timeIntervals={15}
                                placeholderText="Batch Start Date"
                                dateFormat="MMMM d, yyyy"
                                timeCaption="Time"
                            />
                            <label className="text-[#4A3794]" htmlFor="username">
                                Session Start and End Time
                            </label>
                            <div className='flex gap-3'>
                                <input
                                    id="sessionstart"
                                    type="time"
                                    required={true}
                                    className="block w-full px-4 py-2 mt-2 text-black bg-white border border-[#4A3794] rounded-md  focus:outline-none focus:ring placeholder:text-slate-500"
                                    placeholder="Session Start Time"
                                    onChange={(e) => setData({ ...data, session_start_time: convertToISO(e.target.value) })}
                                />
                                <input
                                    id="sessionend"
                                    type="time"
                                    required={true}
                                    className="block w-full px-4 py-2 mt-2 text-black bg-white border border-[#4A3794] rounded-md  focus:outline-none focus:ring placeholder:text-slate-500"
                                    placeholder="Session End Time"
                                    onChange={(e) => setData({ ...data, session_end_time: convertToISO(e.target.value) })}
                                />
                            </div>
                        </div>
                        <button
                            type='submit'
                            className="text-xl text-white cursor-pointer bg-blue-500 text-center mt-2 rounded-xl p-2"
                        >
                            Add New Session
                        </button>
                    </form>
                </div>
            </Modal>

            <div className='text-center p-3 text-lg'>Add Session</div>
            <div className='p-3'>
                <button
                    onClick={() => setDetailModal(true)}
                    className="text-xl text-white cursor-pointer bg-blue-500 text-center mt-2 rounded-xl p-2"
                >
                    Add New Session
                </button>
                <div className='flex flex-col gap-3 mt-3'>
                    {session.map((item, index) => {
                        return (
                            <div key={index} className='p-2 shadow-md'>
                                <h2 className="text-xl text-blue-500">{item.session_name}</h2>
                                <div className='grid grid-cols-3 gap-1 mt-1 text-left text-gray-600'>
                                    <p className='font-semibold text-sm'>Date : <span className='font-normal text-gray-900'>{moment(item.session_date).format('DD-MMM-YYYY')}</span></p>
                                    <p className='font-semibold text-sm'>Student Start : <span className='font-normal text-gray-900'>{item.student_joined_at ? moment(item.student_joined_at).format('DD-MMM-YYYY hh:mm A') : "Not Started"}</span></p>
                                    <p className='font-semibold text-sm'>Teacher Start : <span className='font-normal text-gray-900'>{item.teacher_joined_at ? moment(item.teacher_joined_at).format('DD-MMM-YYYY hh:mm A') : "Not Started"}</span></p>
                                    <p className='font-semibold text-sm'>Time : <span className='font-normal text-gray-900'>{`${moment(item.session_start_time).format('hh:mm A')}- ${moment(item.session_end_time).format('hh:mm A')}`}</span></p>
                                    <p className='font-semibold text-sm'>Student End&nbsp;&nbsp; : <span className='font-normal text-gray-900'>{item.student_leave_at ? moment(item.student_leave_at).format('DD-MMM-YYYY hh:mm A') : "Not Ended"}</span></p>
                                    <p className='font-semibold text-sm'>Teacher End&nbsp;&nbsp; : <span className='font-normal text-gray-900'>{item.student_leave_at ? moment(item.teacher_leave_at).format('DD-MMM-YYYY hh:mm A') : "Not Ended"}</span></p>

                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>

        </>
    )
}

export default AddSession