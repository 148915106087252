import React, { useState, useEffect } from "react";
import "./Subject.css";
import axios from "axios";
import { toast } from "react-toastify";
import baseUrl from "../../../../config/config";

function Subject() {
  const [subjectName, setSubjectName] = useState("");
  const [subjectDescription, setSubjectDescription] = useState("");
  // const [thumbnail, setThumbnail] = useState(null);
  // const [exams, setExams] = useState([]);
  const [selectedExamId, setSelectedExamId] = useState("");

  // const [examName, setExamName] = useState('');
  // const [examDescription, setExamDescription] = useState('');
  const [thumbnail, setThumbnail] = useState(null);
  const [exams, setExams] = useState([]);
  const [selectedExam, setSelectedExam] = useState(null);
  const [showExamList, setShowExamList] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [showSubjectList, setShowSubjectList] = useState(false);
  const [grades, setGrades] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState("");
  const [selectedGradeId, setSelectedGradeId] = useState("");
  const [selectedSubjectId, setSelectedSubjectId] = useState("");

  const [imageUrl, setImageUrl] = useState();

  // const baseUrl.Url /v1= "http://localhost:4001/v1";
  // const baseUrl.Url /v1= 'https://content-panel-intelli.onrender.com/v1'
  // const baseUrl.Url /v1= 'https://test-content-dr78.onrender.com/v1'
  // const API_BASE_URL = 'https://internalrelease.onrender.com/v1'
  // const baseUrl.Url /v1= 'https://content-panel-backend-urll.onrender.com/v1'

  useEffect(() => {
    fetchExams();
  }, []);

  const fetchExams = async () => {
    try {
      const response = await axios.get(`${baseUrl.Url}/v1/exam/getExams`);
      setExams(response.data.data);
    } catch (error) {
      console.error("Error fetching exams:", error);
    }
  };

  const fetchGrades = async (examId) => {
    try {
      const response = await axios.get(
        `${baseUrl.Url}/v1/grade/getgrades/${examId}`
      );
      setGrades(response.data.data);
    } catch (error) {
      console.error("Error fetching grades:", error);
    }
  };

  const fetchSubjects = async (gradeId) => {
    try {
      const response = await axios.get(
        `${baseUrl.Url}/v1/subject/getSubjects/${gradeId}`
      );
      setSubjects(response.data.data);
    } catch (error) {
      console.error("Error fetching subjects:", error);
    }
  };

  const handleExamSelection = async (examId) => {
    console.log(examId);
    setSelectedExamId(examId);
    fetchGrades(examId);
  };

  const handleGradeSelection = async (gradeId) => {
    setSelectedGradeId(gradeId);
    fetchSubjects(gradeId);
  };

  const handleSubjectNameChange = (e) => {
    setSubjectName(e.target.value);
  };

  const handleSubjectDescriptionChange = (e) => {
    setSubjectDescription(e.target.value);
  };

  const handleThumbnailChange = (e) => {
    const file = e.target.files[0];
    setThumbnail(file);

    const data = new FormData();
    data.append("file", thumbnail);
    data.append("upload_preset", "dagada");
    data.append("cloud_name", "dfzd4xbsk");

    fetch("https://api.cloudinary.com//v1_1/dfzd4xbsk/image/upload", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setImageUrl(data.url);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!subjectName) {
        toast.error("Please Enter Exam Name");
        return;
      }

      if (!imageUrl) {
        const userConfirmed = window.confirm(
          "Thumbnail image URL is empty. Do you want to proceed without a thumbnail?"
        );

        if (!userConfirmed) {
          return;
        }
      }
      // const imageUrl = cld.image(thumbnail).toURL();
      const token = localStorage.getItem("token");

      const response = await axios.post(
        `${baseUrl.Url}/v1/subject/createSubject`,
        {
          subjectName,
          subjectDescription,
          thumbnail: imageUrl,
          examId: selectedExamId,
          gradeId: selectedGradeId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log('subjectResponse', response);
      if (response.status === 201) {
        toast.success("Subject created successfully!");
        setSubjectName("");
        setSubjectDescription("");
        setThumbnail(null);
        setSelectedExamId("");
        setSelectedGradeId("");
        setImageUrl(null);
        clearFileInput(); // Clear the file input field
        fetchExams();
      }
    } catch (error) {
      console.error("Error creating subject:", error);
      toast.error(error.response.data.error);
    }
  };
  const clearFileInput = () => {
    const fileInput = document.getElementById("thumbnail");
    if (fileInput) {
      fileInput.value = ""; // Clear the value of the file input
    }
  };

  const handleEditSubject = (subject) => {
    setSelectedSubject(subject);
    setSubjectName(subject.subjectName);
    setSubjectDescription(subject.subjectDescription);
  };

  const handleUpdateSubject = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${baseUrl.Url}/v1/subject/updateSubject/${selectedSubject._id}`,
        {
          subjectName,
          subjectDescription,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        toast.success("Subject updated successfully!");
        setSelectedSubject(null);
        setSelectedExamId("");
        setSubjects([]);
        fetchExams();
      }
    } catch (error) {
      console.error("Error updating subject:", error);
      toast.error("An error occurred while updating the subject.");
    }
  };

  return (
    <div className="App">
      <div className="sub-navbar">
      <div className="dropdown">
        <select
          className="dropbtn"
          value={selectedExamId}
          onChange={(e) => handleExamSelection(e.target.value)}
        >
          <option value="">Select an Exam</option>
          {Array.isArray(exams) ? (
            exams.map((exam) => (
              <option key={exam._id} value={exam._id}>
                {exam.examName}
              </option>
            ))
          ) : (
            <option key={exams._id} value={exams._id}>
              {exams.examName}
            </option>
          )}
        </select>
        </div>

      <div className="dropdown">
        <select
          className="dropbtn"
          value={selectedGradeId}
          onChange={(e) => handleGradeSelection(e.target.value)}
        >
          <option value="">Select a Grade</option>
          {Array.isArray(grades) ? (
            grades.map((grade) => (
              <option key={grade._id} value={grade._id}>
                {grade.gradeName}
              </option>
            ))
          ) : (
            <option key={grades._id} value={grades._id}>
              {grades.gradeName}
            </option>
          )}
        </select>
      </div>

      </div>

      <form className="form" onSubmit={handleSubmit}>
        <label htmlFor="subjectName">Subject Name:</label>
        <input
          type="text"
          id="subjectName"
          value={subjectName}
          onChange={handleSubjectNameChange}
        />

        <label htmlFor="subjectDescription">Subject Description:</label>
        <textarea
          id="subjectDescription"
          value={subjectDescription}
          onChange={handleSubjectDescriptionChange}
        />

        <label htmlFor="thumbnail">Upload Thumbnail:</label>
        <input
          type="file"
          id="thumbnail"
          accept="image/*"
          onChange={handleThumbnailChange}
        />

        <button type="submit">Submit</button>
      </form>

      <div className="sub-navbar">
        <button
          className="get_subjects"
          onClick={() => setShowSubjectList(!showSubjectList)}
        >
          {showSubjectList ? "Hide Subject Data" : "Get Subject Data"}
        </button>
      </div>
      {showSubjectList && (
        <div className="edit-form">
          <h2 className="section-title">Subject List</h2>
          <ul>
            {subjects.map((subject) => (
              <li className="subject-item" key={subject._id}>
                <span>{subject.subjectName}</span>
                <button
                  className="edit-button"
                  onClick={() => handleEditSubject(subject)}
                >
                  Edit
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
      {selectedSubject && (
        <div className="edit-form">
          <h2 className="section-title">Edit Subject</h2>
          <label htmlFor="editSubjectName">Subject Name:</label>
          <input
            type="text"
            id="editSubjectName"
            value={subjectName}
            onChange={handleSubjectNameChange}
            className="input-field"
          />

          <label htmlFor="editSubjectDescription">Subject Description:</label>
          <textarea
            id="editSubjectDescription"
            value={subjectDescription}
            onChange={handleSubjectDescriptionChange}
            className="textarea-field"
          />

          <button className="update-button" onClick={handleUpdateSubject}>
            Update
          </button>
          <button
            className="cancel-button"
            onClick={() => setSelectedSubject(null)}
          >
            Cancel
          </button>
        </div>
      )}
    </div>
  );
}

export default Subject;
