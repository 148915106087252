export const classOptions = [
  {
    value: "10th",
    label: "10",
  },
  {
    value: "12th",
    label: "12",
  },
  {
    value: "Btech",
    label: "Btech",
  },
  {
    value: "Bsc",
    label: "Bsc",
  },
  {
    value: "Msc",
    label: "Msc",
  },
  {
    value: "TET",
    label: "TET",
  },
  {
    value: "UGC",
    label: "UGC",
  },
];
export const languageOptions = [
  {
    value: "English",
    label: "English",
  },
  {
    value: "Hindi",
    label: "Hindi",
  },
  {
    value: "Marathi",
    label: "Marathi",
  },
];
export const curriculumoption = [
  {
    value: "IIT",
    label: "Jee Advance",
  },
  {
    value: "NEET",
    label: "Neet",
  },
  {
    value: "JeeMains",
    label: "JEE MAINS",
  },
  {
    value: "IBDB",
    label: "IBDB",
  },
  {
    value: "IB",
    label: "IB",
  },
  {
    value: "SSC",
    label: "SSC",
  },
  {
    value: "UGC",
    label: "UGC",
  },
];
export const subjectOptions = [
  {
    value: "Maths",
    label: "Maths",
  },
  {
    value: "Physics",
    label: "Physics",
  },
  {
    value: "Chemistry",
    label: "Chemistry",
  },
  {
    value: "English",
    label: "English",
  },
];
export const classData = [
  {
    value: "private",
    label: "Private Class",
  },
  {
    value: "batch",
    label: "Batch Class",
  },
  {
    value: "home",
    label: "Home Tutoring",
  },
];
export const modeData = [
  {
    value: "online",
    label: "online",
  },
  {
    value: "offline",
    label: "offline",
  },
];
export const gradeData = [
  {
    value: "KG",
    label: "Kindergarten",
  },
  {
    value: "Pre",
    label: "Preschool",
  },
  {
    value: "1",
    label: "1st Grade",
  },
  {
    value: "2",
    label: "2nd Grade",
  },
  {
    value: "3",
    label: "3rd Grade",
  },
  {
    value: "4",
    label: "4th Grade",
  },
  {
    value: "5",
    label: "5th Grade",
  },
  {
    value: "6",
    label: "6th Grade",
  },
  {
    value: "7",
    label: "7th Grade",
  },
  {
    value: "8",
    label: "8th Grade",
  },
  {
    value: "9",
    label: "9th Grade",
  },
  {
    value: "10",
    label: "10th Grade",
  },
  {
    value: "11",
    label: "11th Grade",
  },
  {
    value: "12",
    label: "12th Grade",
  },
];
