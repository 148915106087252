import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Sidebar from "../SideBar";
const Private = ({ children, activeContent }) => {
  const { isloggedin, userId } = useSelector((state) => state.users);
  const navigate = useNavigate();

  useEffect(() => {
    if (isloggedin === false) {
      navigate("/login");
    }
  }, [isloggedin, navigate]);
  return (
    <div className="admin_panel_main_container">
      <Sidebar activeContent={activeContent} />
      <div className="admin_panel_main_container_main">{children}</div>
    </div>
  );
};

export default Private;
